import React from 'react';
import { useTranslation } from "react-i18next";

export default function Breadcrumb({ mqttStatus }) {
  const {t/*, i18n*/} = useTranslation();
  
  // console.log("mqttStatus: ", mqttStatus);

  return (
    <>
      {        
        mqttStatus && (mqttStatus.mqttConnected !== "connected") && (
          <div className="breadcrumb">
          {t('Connecting')}      
        </div>)
      }
    </>
  );
}
