import React from 'react';
import { useTranslation } from "react-i18next";
import { format as formatDate } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import { timestampToDate, colourNameToHex, hexToRgb } from '../utilities';

//Used by date-fns/esm format 
const locales = {it, de, fr, enGB, enUS};

export default function JournalEntry(props) {
  const {t, i18n} = useTranslation(); 

  const {
    dateTime, 
    authorName, 
    authorSurname, 
    authorOrg, 
    authorColor,
    // authorIcon,
    title, 
    caseDesc,
    entry 
  } = props;

  const bgColRGB = hexToRgb(colourNameToHex(authorColor ? authorColor : '#AAA'));

  let author = `${authorName ? authorName : ''}${(authorName && authorSurname) ? ' ' : ''}${authorSurname ? authorSurname : ''}${(authorName || authorSurname) ? ', ' : ''}${authorOrg ? authorOrg : ''}`;
  if((authorName == null || authorName === '-') && (authorSurname == null || authorSurname === '-') && (authorOrg == null || authorOrg === '-')) {
      author = `${t('alerts.system')}`;
  } 

  return (
    <div className="journal-entry" style={{background: `rgb(${bgColRGB.r}, ${bgColRGB.g}, ${bgColRGB.b}, 0.1)`}}> 
      <div className="journal-entry-header">
        <span className="journal-entry-author">
          {`${t('journal.author')}: `}<b>{author}</b>
        </span>
        <span className="journal-entry-datetime">{`${t('journal.datetime')}: ${dateTime ? formatDate(timestampToDate(dateTime), "Ppp", {locale: locales[i18n.language]}) : '-'}` }</span>
      </div>
      <div className="journal-entry-body">
        {(caseDesc !== null) && (
          <details>
            <summary>
              <span className="journal-entry-title">{title}</span>
            </summary>
            <span className="journal-entry-desc respect-whitespace">{caseDesc}</span>
          </details>
        )}
        {(caseDesc === null) && (
          <><span className="journal-entry-title">{title}</span><br/></>
        )}
        <span className="journal-entry-text respect-whitespace">{entry}</span>
      </div>
    </div>
  )
}
