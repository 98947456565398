import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TileBadge from './TileBadge';
import TileStatistics from './TileStatistics';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as OnChangeSpinner }  from '../svg/OnChangeSpinner.svg';
import { ReactComponent as WarningIcon }  from '../svg/warning.svg';
import {convertToDMS, null_or_undefined} from '../utilities';
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import ResourceLink from "./ResourceLink";

export default function ActiveTile(props) {
  const {t/*, i18n*/} = useTranslation();

  const {
    tileId, 
    onClickHandler, 
    useFontAwesome,
    iconName, 
    iconColor,
    iconBgColor,
    tileState, 
    descType, 
    title, 
    details,
    moreDetails,
    uppercaseDetails,
    detailsTime,
    currentTime,
    badgeNumber,
    statisticsType,
    showSpinner,
    showWarning,
    overlayIcon,
    overlayIconColor,
    statisticsData,
    onBadgeClickHandler,
    isUnread,
    dataUnit,
    timeThresholds
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  
  let actualIconColor = null;
  let actualIconBgColor = null;
  let actualBadgeNumber = null;
  let actualStatisticsType = null;

  const nodeRef = React.useRef(null)

  if(typeof iconColor !== 'undefined' && iconColor !== null && iconColor !== "") {
    actualIconColor = iconColor;
  }

  if(typeof iconBgColor !== 'undefined' && iconBgColor !== null && iconBgColor !== "") {
    actualIconBgColor = iconBgColor;
  }

  if(typeof badgeNumber !== 'undefined' && badgeNumber !== null && Number.isInteger(badgeNumber)) {
    actualBadgeNumber = badgeNumber;
  }

  if(typeof statisticsType !== 'undefined' && statisticsType !== null && Number.isInteger(statisticsType)) {
    actualStatisticsType = statisticsType;
  }

  function createActualIconStyle() {
    let actualStyle = null;
    if(actualIconColor) {
      actualStyle = {...actualStyle, color: actualIconColor};
    }
    if(actualIconBgColor) {
      actualStyle = {...actualStyle, backgroundColor: actualIconBgColor};
    }
    return actualStyle;
  }

  function replaceTimePlaceholder(message, messageTime, now) {
    if(typeof(message) !== "string")
      return message;

    const time = now - messageTime >= 0 ? now - messageTime : 0;
    const days = Math.floor(time / (1000 * 60 * 60 * 24)); 
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); 
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)); 
    const seconds = Math.floor((time % (1000 * 60)) / 1000); 
    let elapsedTimeString = t('activeTile.for') + " ";
    if(days) {
      elapsedTimeString = elapsedTimeString + days + " " + t('activeTile.dayAbbrev');
    }
    if(days || hours) {
      if(days) {
        elapsedTimeString = elapsedTimeString + " "
      }
      elapsedTimeString = elapsedTimeString + hours.toString().padStart(2, '0') + " " + t('activeTile.hourAbbrev');
    }
    if(hours || minutes) {
      if(days || hours) {
        elapsedTimeString = elapsedTimeString + " "
      }
      elapsedTimeString = elapsedTimeString + minutes.toString().padStart(2, '0') + " " + t('activeTile.minAbbrev');
    }
    if(!days) {
      if(hours || minutes) {
        elapsedTimeString = elapsedTimeString + " "
      }
      elapsedTimeString = elapsedTimeString + seconds.toString().padStart(2, '0') + " " + t('activeTile.secAbbrev');
    }
    return message.replace('%time%', elapsedTimeString);
  }

  function replacePositionPlaceholder(message) {
    if(typeof(message) !== "string")
      return message;

    message = message.replace('%position:?,?%', t('position') + " ?");

    const regex = new RegExp('%position:([+-]*\\d+\\.\\d+),([+-]*\\d+\\.\\d+);([A-Za-zÀ-ÖØ-öø-ÿ\\d \'-]+|\\?)%', 'g');
    const matches = message.matchAll(regex);
    for (const match of matches) {
      // NOTES: if there is a valid postion placeholer, the ResourceLink to the position will REPLACE THE WHOLE MESSAGE!
      // message = message.replace(match[0], `<a href="https://www.google.com/maps/search/?api=1&query=${match[1]}%2C${match[2]}" target="_blank" rel="noopener">${t('position')}</a>`)
      return <ResourceLink
          className="tile-position-link"
          icon={faMapMarkedAlt}
          linkState={{
            url: `https://www.google.com/maps/search/?api=1&query=${match[1]}%2C${match[2]}`,
            linkName: " " + (match[3] === '?' ? convertToDMS(parseFloat(match[1]), parseFloat(match[2])).fullString : match[3]),
            description: t('position')
          }}
      />;
    }

    return message;
  }

  function calcTileStyle(messageTime, now) {
    if (null_or_undefined(timeThresholds))
      return null;
    let style = null;
    for(let a = 0; a < timeThresholds.length; a++) {
      if((now - messageTime) > timeThresholds[a].threshold) {
        style = {backgroundColor: timeThresholds[a].backgroundColor};
      }
    }
    return style;
  }

  function handleIsCollapsedChange(tileId, isCollapsed) {
    onBadgeClickHandler(tileId, isCollapsed);

    if(statisticsType === 0) 
      return;

    setIsCollapsed(!isCollapsed);
  }

  function decodeStatisticsType(valueType, percentages) {
    switch (valueType) {
      case 'int':
        if(percentages != null) {
          return 1;
        }
        return 2;

      case 'float':
        return 2;

      case 'json':
        return 0;

      default:
        return 0;
    }
  }

  return (
    <div className="evento" id={`tile_${tileId}`}>
      <div className="struttura">
        <div 
          className={`icona ${tileState} ${onClickHandler ? 'icon-clickable' : ''}`}
          data-installation={tileId}
          onClick={onClickHandler}
          style={createActualIconStyle()}
        >
          {(typeof useFontAwesome === 'undefined' || useFontAwesome === false) && (<i className={`icon ${iconName}`}></i>)}
          {useFontAwesome === true && (<FontAwesomeIcon icon={iconName} />)}
          {showSpinner && (<OnChangeSpinner className="tile-on-change-icon" style={{background: 'none'}}/>)}
          {showWarning && (<WarningIcon className="tile-warning-icon" style={{background: 'none'}}/>)}
          {overlayIcon && (<FontAwesomeIcon className="tile-warning-icon" icon={overlayIcon} style={{background: 'none', opacity: 0.75, color: overlayIconColor}}/>)}
        </div>
        <div className={`desc ${descType}`} style={calcTileStyle(detailsTime, currentTime)}>
          <span className="titolo">{title}</span>
          <div className={`dettagli ${uppercaseDetails === true ? "uppercase" : ""}`}>
            {replacePositionPlaceholder(replaceTimePlaceholder(details, detailsTime, currentTime))}
            {typeof moreDetails !== 'undefined' && (
              <>
                <br/>
                {replacePositionPlaceholder(replaceTimePlaceholder(moreDetails, detailsTime, currentTime))}
              </>
            )}
          </div>
        </div>
      </div>
      
      {actualBadgeNumber !== null && (
        <TileBadge 
          badgeNumber={actualBadgeNumber} 
          handleCollapseChange={(statisticsType === 0) ? null : (tileId, isCollapsed) => { handleIsCollapsedChange(tileId, isCollapsed) }} 
          isUnread={isUnread}
          tileId={tileId}
        />
      )}

      <CSSTransition classNames="tile-statistics-trans" in={!isCollapsed} nodeRef={nodeRef} timeout={250} unmountOnExit>
        <div ref={nodeRef}>
          <TileStatistics statType={(actualStatisticsType === null && statisticsData != null) ? decodeStatisticsType(statisticsData.valueType, statisticsData.percentages) : actualStatisticsType} tileId={tileId} unit={dataUnit} wholeData={statisticsData}/>
        </div>
      </CSSTransition>
    </div>   
  );
}
