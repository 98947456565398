import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  faUser,
  faArrowCircleDown,
  faArrowCircleUp,
  faPhone,
  faMobileAlt,
  faPhoneVolume,
  faKey, faTrashAlt, faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import ActiveTile from './ActiveTile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { CSSTransition } from 'react-transition-group';
import ResourceLink from './ResourceLink';
import {
  convertFontAwesomeIconName,
  not_null_or_undefined,
  null_or_undefined,
} from '../utilities';
import cloneDeep from "lodash/cloneDeep";
import {confirmAlert} from "react-confirm-alert";
import {InstallationsContext} from "./App";
import Select from "react-select";
import AsyncSelect from "react-select/async";

export default function ContactPersons({persons, setPersons, standalone, defaultOpen, allowModify}) {
  const {t} = useTranslation();
  const { selectedInst, deleteInstContact, updateInstContact, createInstContact, requestContactRoles } =
      React.useContext(InstallationsContext);
  const [contactRoles, setContactRoles] = useState(null);
  const [show, setShow] = useState(defaultOpen);
  const [personModal, setPersonModal] = useState(null);
  const [modalType, setModalType] = useState('show');

  const nodeRef = React.useRef(null)

  const EmptyPerson = {
    userId: 0,
    firstName: '',
    lastName: '',
    phoneNumber1Number: "",
    phoneNumber1Desc: null,
    phoneNumber1Type: null,
    phoneNumber2Number: "",
    phoneNumber2Desc: null,
    phoneNumber2Type: null,
    phoneNumber3Number: "",
    phoneNumber3Desc: null,
    phoneNumber3Type: null,
    phoneNumber4Number: "",
    phoneNumber4Desc: null,
    phoneNumber4Type: null,
    phoneNumber5Number: "",
    phoneNumber5Desc: null,
    phoneNumber5Type: null,
    relation: '',
    summary: null,
    note: null,
    links: [],
    props: { hasKeys: false},
    roles: [{roleId: 0}],
    priority: 0
  };

  const PHONE_NUMBER_TYPE_OPTIONS = [
    { value: 'land', label: t('guests.landline') },
    { value: 'cell', label: t('guests.cellular') },
    { value: 'viva', label: t('guests.vivaVoice') },
  ];

  const YES_NO_TYPE_OPTIONS = [
    { value: false, label: t('no') },
    { value: true, label: t('yes') },
  ]

  const handleAlert = (message) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: `${t('contactPerson.errorTitle')}`,
        message: message,
        buttons: [
          {
            label: t('OK'),
            onClick: resolve
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      });
    });
  };

  const handleConfirmation = (message) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: `${t('contactPerson.confirmTitle')}`,
        message: message,
        buttons: [
          {
            label: t('No'),
            onClick: reject
          },
          {
            label: t('Yes'),
            onClick: resolve
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      });
    });
  };

  function convertPhoneNumberTypeValToType(type) {
    if (type === null) return PHONE_NUMBER_TYPE_OPTIONS[0];
    const typeFound = PHONE_NUMBER_TYPE_OPTIONS.find(el => el.value === type);
    if (typeFound) return typeFound;

    return null;
  }

  function extractHasKeyFromProp(props) {
    if(props?.hasKeys === true)
      return YES_NO_TYPE_OPTIONS[1];

    return YES_NO_TYPE_OPTIONS[0];
  }

  async function requestPersonAddition(instSerial, newPerson) {
    const createdContact = await createInstContact(instSerial, convertPersonInfoToPersonAPI(newPerson));
    if(createdContact.lastErrorMessage != null) {
      alert(createdContact.lastErrorMessage)
      return;
    }
    const newPersons = cloneDeep(persons);
    newPersons.set(createdContact.userId, createdContact);
    setPersons(newPersons);
  }

  function cleanupPhoneNumber(number) {
    if(number == null || number.length === 0)
      return number;
    let cleanNumber = "";
    if(number.substring(0,1) === "+")
      cleanNumber = "00" + number.substring(1).replace(/[\s\-()]/g, '');
    else
      cleanNumber = number.replace(/[\s\-()]/g, '');
    return cleanNumber;
  }

  function toggleShow() {
    if(!standalone)
      return;
    setShow( (show) => { return !show; });
    setModalType('show');
  }

  function personOnClickHandler(person) {
    setPersonModal(cloneDeep(person));
  }

  function closePersonModal() {
    setPersonModal(null);
    setModalType('show');
  }

  async function handleAddNewPerson(e) {
    e.stopPropagation();
    await handlePersonEditDataRequests();
    setModalType('create');
    setPersonModal({ ...EmptyPerson });
  }

  async function getContactRoles() {
    if (contactRoles === null) {
      const contactRolesRead = await requestContactRoles();
      if (not_null_or_undefined(contactRolesRead)) {
        let mapped = contactRolesRead.map((contactRole) => {
          return { value: contactRole, label: `${contactRole.name}` };
        });
        setContactRoles(mapped);
        return mapped;
      }
      return [];
    } else {
      return contactRoles;
    }
  }

  function convertPersonInfoToPersonAPI(personInfo)
  {
    let personForApi = {
      "userId": personInfo.userId,
      "firstName": personInfo.firstName,
      "lastName": personInfo.lastName,
      "organization": personInfo.organization,
      "summary": personInfo.summary,
      "note": personInfo.note,
      "relation": personInfo.relation,
      "props": personInfo.props,
      "links": cloneDeep(personInfo.links),
      "roles": cloneDeep(personInfo.roles),
      "priority": personInfo.priority
    }

    if(personInfo.phoneNumber1Number != null && personInfo.phoneNumber1Number.length !== 0) {
      personForApi.phone1Info = {
        "number": personInfo.phoneNumber1Number,
        "type": personInfo.phoneNumber1Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": personInfo.phoneNumber1Desc ?? "",
      }
    } else {
      personForApi.phone1Info = {
        "number": ""
      }
    }

    if(personInfo.phoneNumber2Number != null && personInfo.phoneNumber2Number.length !== 0) {
      personForApi.phone2Info = {
        "number": personInfo.phoneNumber2Number,
        "type": personInfo.phoneNumber2Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": personInfo.phoneNumber2Desc ?? "",
      }
    } else {
      personForApi.phone2Info = {
        "number": ""
      }
    }

    if(personInfo.phoneNumber3Number != null && personInfo.phoneNumber3Number.length !== 0) {
      personForApi.phone3Info = {
        "number": personInfo.phoneNumber3Number,
        "type": personInfo.phoneNumber3Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": personInfo.phoneNumber3Desc ?? "",
      }
    } else {
      personForApi.phone3Info = {
        "number": ""
      }
    }

    if(personInfo.phoneNumber4Number != null && personInfo.phoneNumber4Number.length !== 0) {
      personForApi.phone4Info = {
        "number": personInfo.phoneNumber4Number,
        "type": personInfo.phoneNumber4Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": personInfo.phoneNumber4Desc ?? "",
      }
    } else {
      personForApi.phone4Info = {
        "number": ""
      }
    }

    if(personInfo.phoneNumber5Number != null && personInfo.phoneNumber5Number.length !== 0) {
      personForApi.phone5Info = {
        "number": personInfo.phoneNumber5Number,
        "type": personInfo.phoneNumber5Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": personInfo.phoneNumber5Desc ?? "",
      }
    } else {
      personForApi.phone5Info = {
        "number": ""
      }
    }

    return personForApi;
  }

  function convertPersonApiInfoToPersonInfo(personApi)
  {
    let personInfo = {
      "userId": personApi.userId,
      "firstName": personApi.firstName,
      "lastName": personApi.lastName,
      "organization": personApi.organization,
      "summary": personApi.summary,
      "note": personApi.note,
      "relation": personApi.relation,
      "props": personApi.props,
      "links": cloneDeep(personApi.links),
      "roles": cloneDeep(personApi.roles),
      "priority": personApi.priority
    }

    if(personApi.phone1Info != null) {
      personInfo.phoneNumber1Number = personApi.phone1Info.number;
      personInfo.phoneNumber1Type = personApi.phone1Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      personInfo.phoneNumber1Desc = personApi.phone1Info.desc ?? "";
    }

    if(personApi.phone2Info != null) {
      personInfo.phoneNumber2Number = personApi.phone2Info.number;
      personInfo.phoneNumber2Type = personApi.phone2Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      personInfo.phoneNumber2Desc = personApi.phone2Info.desc ?? "";
    }

    if(personApi.phone3Info != null) {
      personInfo.phoneNumber3Number = personApi.phone3Info.number;
      personInfo.phoneNumber3Type = personApi.phone3Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      personInfo.phoneNumber3Desc = personApi.phone3Info.desc ?? "";
    }

    if(personApi.phone4Info != null) {
      personInfo.phoneNumber4Number = personApi.phone4Info.number;
      personInfo.phoneNumber4Type = personApi.phone4Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      personInfo.phoneNumber4Desc = personApi.phone4Info.desc ?? "";
    }

    if(personApi.phone5Info != null) {
      personInfo.phoneNumber5Number = personApi.phone5Info.number;
      personInfo.phoneNumber5Type = personApi.phone5Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      personInfo.phoneNumber5Desc = personApi.phone5Info.desc ?? "";
    }
    return personInfo;
  }

  async function handlePersonEditDataRequests() {
    // if (contactRoles === null) {
    //   setContactRoles('loading'); //Avoid multiple unnecessary calls
    //   const contactRolesRead = await requestContactRoles();
    //   if (not_null_or_undefined(contactRolesRead)) {
    //     let mapped = contactRolesRead.map((contactRole) => {
    //       return { value: contactRole, label: `${contactRole.name}` };
    //     });
    //     setContactRoles(mapped);
    //   }
    // }
  }

  async function deletePerson(contactId) {
    confirmAlert({
      title: `${t('contactPerson.confirmDeletion')}`,
      buttons: [
        {
          label: `${t('Yes')}`,
          onClick: async () => {
            try {
              await deleteInstContact(selectedInst, contactId);
              const newContacts = cloneDeep(persons);
              newContacts.delete(contactId);
              setPersons(newContacts);
            } catch (ex) {
              alert('Error: ' + ex.message());
            }
          },
        },
        {
          label: `${t('No')}`,
        },
      ],
    });
  }

  async function saveChangesToPerson(contactInfo) {
    const updated = await updateInstContact(selectedInst, contactInfo.userId, convertPersonInfoToPersonAPI(contactInfo));
    if(updated.lastErrorMessage != null) {
      alert(updated.lastErrorMessage)
      return;
    }
    const newPersons = cloneDeep(persons);
    newPersons.forEach((contact, idx, persons) => {
      if (contact.userId === contactInfo.userId) {
        persons.set(idx, updated);
      }
    });
    setPersons(newPersons);
  }

  function renderContacts() {
    if(persons === null)
      return;

    return (
      <div className="info-contact-persons">
        { 
          typeof persons !== 'undefined' && (
            [...persons.values()].sort((a, b) => { return a.priority - b.priority }).map( (contactPerson, idx) => {
              let icon = faUser;
              let iconColor = '#75ac65';
              let tileState = "active-state";

              if(contactPerson.iconUrl) {
                icon = convertFontAwesomeIconName(contactPerson.iconUrl) ?? faUser;
              }

              if(contactPerson.color) {
                iconColor = contactPerson.color;
              }
            
              return (
                <ActiveTile 
                  key={contactPerson.userId}
                  descType={""}
                  details={
                    <>
                      {contactPerson.phone1Info?.number && (
                          <span className='phone-link'>
                        <a className='phone-link' href={`${contactPerson.phone1Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(contactPerson.phone1Info.number)}`}>
                          {contactPerson.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                          {contactPerson.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                          {contactPerson.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                          {`${contactPerson.phone1Info.number}`}
                        </a> {contactPerson.phone1Info.desc && (`(${contactPerson.phone1Info.desc})`)}
                      </span>
                      )}
                      <wbr/>
                      {contactPerson.phone2Info?.number && (
                          <span className='phone-link'>
                        <a className='phone-link' href={`${contactPerson.phone2Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(contactPerson.phone2Info.number)}`}>
                          {contactPerson.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                          {contactPerson.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                          {contactPerson.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                          {`${contactPerson.phone2Info.number}`}
                        </a> {contactPerson.phone2Info.desc && (`(${contactPerson.phone2Info.desc})`)}
                      </span>
                      )}
                      <wbr/>
                      {contactPerson.phone3Info?.number && (
                          <span className='phone-link'>
                          <a className='phone-link' href={`${contactPerson.phone3Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(contactPerson.phone3Info.number)}`}>
                            {contactPerson.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {contactPerson.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {contactPerson.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${contactPerson.phone3Info.number}`}
                          </a> {contactPerson.phone3Info.desc && (`(${contactPerson.phone3Info.desc})`)}
                        </span>
                      )}
                      <wbr/>
                      {contactPerson.phone4Info?.number && (
                          <span className='phone-link'>
                          <a className='phone-link' href={`${contactPerson.phone4Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(contactPerson.phone4Info.number)}`}>
                            {contactPerson.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {contactPerson.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {contactPerson.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${contactPerson.phone4Info.number}`}
                          </a> {contactPerson.phone4Info.desc && (`(${contactPerson.phone4Info.desc})`)}
                        </span>
                      )}
                      <wbr/>
                      {contactPerson.phone5Info?.number && (
                          <span className='phone-link'>
                          <a className='phone-link' href={`${contactPerson.phone5Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(contactPerson.phone5Info.number)}`}>
                            {contactPerson.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {contactPerson.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {contactPerson.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${contactPerson.phone5Info.number}`}
                          </a> {contactPerson.phone5Info.desc && (`(${contactPerson.phone5Info.desc})`)}
                        </span>
                      )}
                      {contactPerson.props?.hasKeys != null && (
                        <span className='contact-props'>
                          <FontAwesomeIcon className={"phone-type-icon"} icon={faKey}/>&nbsp;{contactPerson.props.hasKeys ? `${t('yes')}` : `${t('no')}`}
                        </span>
                      )}
                      {
                        contactPerson.eMail && (
                          <>
                            , e-mail:{'\u00A0'}
                            <a className="email-link" href={`mailto:${contactPerson.eMail}`}>
                              {`${contactPerson.eMail}`}
                            </a>
                          </>)
                      }
                    </>
                  }
                  iconBgColor={iconColor}
                  iconName={icon}
                  moreDetails={contactPerson.summary}
                  onClickHandler={ () => personOnClickHandler(contactPerson) }
                  tileId={`contact_${contactPerson.userId}`}
                  tileState={tileState}
                  title={`${contactPerson.firstName} ${contactPerson.lastName}${
                    (contactPerson.organization != null ? (", " + contactPerson.organization) : '')
                  }${(contactPerson.relation != null ? " (" + contactPerson.relation + ")" : '')}`}
                  useFontAwesome={true}
                  // uppercaseDetails={true}
                />
              )}
            )
          )
        }
      </div>
    );
  }

  function renderModal() {
    return (
      <Modal
        classNames={{
          // overlay: 'customOverlay',
          modal: 'fullWidthModal',
        }}
        onClose={closePersonModal}
        open={personModal !== null}
      >
        {personModal !== null && modalType === 'show' && (
            <>
              <h1>{t('contactPerson.contactPersonDetailTitle')}</h1>
              <hr/>
              <p><b>{`${t('contactPerson.name')}:`}</b> {`${personModal.firstName}`}</p>
              <p><b>{`${t('contactPerson.surname')}:`}</b> {`${personModal.lastName}`}</p>
              {personModal.organization &&
                  (<p><b>{`${t('contactPerson.organization')}:`}</b> {`${personModal.organization ?? '-'}`}</p>)}
              {personModal.relation &&
                  (<p><b>{`${t('contactPerson.relation')}:`}</b> {`${personModal.relation ?? '-'}`}</p>)}
              {/*<p>*/}
              {/*  <b>{`${t('contactPerson.roleShorthand')}:`}</b> {*/}
              {/*      personModal.roles.map( (rule) => {*/}
              {/*        return (`${rule.roleShorthand}`);*/}
              {/*      })*/}
              {/*  }*/}
              {/*</p>*/}
              {typeof personModal.phone1Info !== 'undefined' && (
                  <p>
                    {personModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                    {personModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                    {personModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                    <a className='phone-link'
                       href={`${personModal.phone1Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(personModal.phone1Info.number)}`}>{`${personModal.phone1Info.number}`}</a> {`${personModal.phone1Info.desc}`}
                  </p>
              )}
              {typeof personModal.phone2Info !== 'undefined' && (
                  <p>
                    {personModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                    {personModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                    {personModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                    <a className='phone-link'
                       href={`${personModal.phone2Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(personModal.phone2Info.number)}`}>{`${personModal.phone2Info.number}`}</a> {`${personModal.phone2Info.desc}`}
                  </p>
              )}
              {typeof personModal.phone3Info !== 'undefined' && (
                  <p>
                    {personModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                    {personModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                    {personModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                    <a className='phone-link'
                       href={`${personModal.phone3Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(personModal.phone3Info.number)}`}>{`${personModal.phone3Info.number}`}</a> {`${personModal.phone3Info.desc}`}
                  </p>
              )}
              {typeof personModal.phone4Info !== 'undefined' && (
                  <p>
                    {personModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                    {personModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                    {personModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                    <a className='phone-link'
                       href={`${personModal.phone4Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(personModal.phone4Info.number)}`}>{`${personModal.phone4Info.number}`}</a> {`${personModal.phone4Info.desc}`}
                  </p>
              )}
              {typeof personModal.phone5Info !== 'undefined' && (
                  <p>
                    {personModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                    {personModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                    {personModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                        <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                    <a className='phone-link'
                       href={`${personModal.phone5Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(personModal.phone5Info.number)}`}>{`${personModal.phone5Info.number}`}</a> {`${personModal.phone5Info.desc}`}
                  </p>
              )}
              {typeof personModal.eMail !== 'undefined' && (
                  <p><b>{`${t('contactPerson.email')}:`}</b> <a className="email-link"
                                                                href={`mailto:${personModal.eMail}`}>{`${personModal.eMail}`}</a>
                  </p>
              )}
              {personModal.props?.hasKeys != null && (
                  <p>
                    <FontAwesomeIcon className={"phone-type-icon"}
                                     icon={faKey}/> {personModal.props.hasKeys ? `${t('yes')}` : `${t('no')}`}
                  </p>
              )}
              {typeof personModal.summary !== 'undefined' && (
                  <p>
                    <b>{`${t('contactPerson.summary')}:`}</b> {`${personModal.summary}`}
                  </p>
              )}
              {typeof personModal.note !== 'undefined' && (
                  <p>
                    <b>{`${t('contactPerson.note')}:`}</b> {`${personModal.note ?? '-'}`}
                  </p>
              )}
              <p><b>{`${t('contactPerson.priority')}:`}</b> {`${personModal.priority}`}</p>
              <p><b>{`${t('contactPerson.category')}:`}</b> {`${personModal.roles[0].name}`}</p>
              <div className={'guest-links-section'}>
                {personModal.links && (
                    personModal.links.map((link) => {
                      return (
                          <ResourceLink
                              key={link.url}
                              className="installation-desc-link"
                              linkState={link}
                          />
                      );
                    })
                )}
              </div>
              {allowModify && (
                  <div className='guest-details-modify-buttons'>
                    <button
                        className='cbutton'
                        onClick={() => {
                          setModalType('edit');
                          setPersonModal((curPersonModal) => {
                            return convertPersonApiInfoToPersonInfo(curPersonModal);
                          });
                          handlePersonEditDataRequests();
                        }}
                        type='button'
                    >
                      {t('edit')}
                    </button>
                  </div>
              )}
            </>
        )}
        {personModal !== null && modalType === 'edit' && (
            <>
            <h1>{t('contactPerson.contactEditDetailTitle')}</h1>
            <hr/>
              <form className='guest-details-modify-form'>
                <div className='guest-form-row'>
                  <label htmlFor='contactName'>
                    <b>{`${t('contactPerson.name')}:`}</b>
                    <input
                        defaultValue={`${personModal.firstName ?? ''}`}
                        name='contactName'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.firstName = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactSurname'>
                    <b>{`${t('contactPerson.surname')}:`}</b>
                    <input
                        defaultValue={`${personModal.lastName ?? ''}`}
                        name='contactSurname'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.lastName = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactOrganization'>
                    <b>{`${t('contactPerson.organization')}:`}</b>
                    <input
                        defaultValue={`${personModal.organization ?? ''}`}
                        name='contactOrganization'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.organization = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactRelation'>
                    <b>{`${t('contactPerson.relation')}:`}</b>
                    <input
                        defaultValue={`${personModal.relation ?? ''}`}
                        name='contactOrganization'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.relation = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber1Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-1-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber1Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber1'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber1Number ?? ''}`}
                        name='contactPhoneNumber1'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber1Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber1Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber1Desc ?? ''}`}
                        name='contactPhoneNumber1Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber1Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber2Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-2-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber2Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber2'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber2Number ?? ''}`}
                        name='contactPhoneNumber2'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber2Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber2Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber2Desc ?? ''}`}
                        name='contactPhoneNumber2Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber2Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber3Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-3-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber3Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber3'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber3Number ?? ''}`}
                        name='contactPhoneNumber3'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber3Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber3Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber3Desc ?? ''}`}
                        name='contactPhoneNumber3Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber3Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber4Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-4-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber4Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber4'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber4Number ?? ''}`}
                        name='contactPhoneNumber4'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber4Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber4Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber4Desc ?? ''}`}
                        name='contactPhoneNumber4Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber4Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber5Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-5-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber5Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber5'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber5Number ?? ''}`}
                        name='contactPhoneNumber5'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber5Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber5Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber5Desc ?? ''}`}
                        name='contactPhoneNumber5Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber5Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactSummary'>
                    <b>{`${t('contactPerson.summary')}:`}</b>
                    <input
                        defaultValue={`${personModal.summary ?? ''}`}
                        name='contactSummary'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.summary = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactNote'>
                    <b>{`${t('contactPerson.note')}:`}</b>
                    <textarea
                        defaultValue={`${personModal.note ?? ''}`}
                        name='contactNote'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.note = newValue;
                            return {...personModalCur};
                          });
                        }}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactNote'>
                    <b>{`${t('contactPerson.hasKeys')}:`}</b>
                    <Select
                        className='dropdown-select yes-no-type'
                        classNamePrefix='select'
                        defaultValue={extractHasKeyFromProp(personModal.props)}
                        getOptionLabel={option => `${t(option.label)}`}
                        isSearchable={true}
                        menuPlacement='top'
                        name='phone-number-3-type'
                        onChange={(option) => {
                          setPersonModal((personModalCur) => {
                            if (personModalCur.props === null)
                              personModalCur.props = {}
                            personModalCur.props.hasKeys = option.value;
                            return {...personModalCur};
                          });
                        }}
                        options={YES_NO_TYPE_OPTIONS}
                        placeholder={t('remindersAdminPage.filterSelectIcon')}
                        styles={{
                          singleValue: (styles, {data}) => {
                            return {...styles, color: '#797979'};
                          },
                        }}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactPriority'>
                    <b>{`${t('contactPerson.priority')}:`}</b>
                    <input
                        defaultValue={`${personModal.priority ?? 0}`}
                        name='contactPriority'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.priority = newValue;
                            return {...personModalCur};
                          });
                        }}
                        step={1}
                        type={'number'}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactRole'>
                    <b>{`${t('contactPerson.category')}: `}</b>
                    <AsyncSelect
                        cacheOptions={false}
                        className='dropdown-select-fi'
                        classNamePrefix='select'
                        defaultOptions={true}
                        defaultValue={
                          {
                            value: personModal?.roles[0].roleId,
                            label: personModal?.roles[0].name
                          }
                        }
                        getOptionLabel={option => `${t(option.label)}`}
                        isSearchable={false}
                        loadOptions={getContactRoles}
                        menuPlacement='top'
                        name='contactRole'
                        onChange={(option) => {
                          setPersonModal((personModalCur) => {
                            personModalCur.roles = []
                            personModalCur.roles.push({"roleId": option.value.id});
                            return {...personModalCur};
                          });
                        }}
                        placeholder={t('remindersAdminPage.filterSelectIcon')}
                        styles={{
                          singleValue: (styles, {data}) => {
                            return {...styles, color: '#797979'};
                          },
                        }}
                    />
                  </label>
                </div>
                <div className='guest-links-section'>
                  <h3>{`${t('contactPerson.links')}:`}</h3>
                  <div className='guest-links-list'>
                    {personModal.links?.map((link, idx) => {
                      return (
                          <div key={idx+'_'+link.url} className='guest-link-raw'>
                            <ResourceLink
                                key={idx+'_'+link.url}
                                className='guest-form-row'
                                descriptionEditLabel={t('guests.linkDescription')}
                                edit={true}
                                editDivClassName='guest-form-link-edit'
                                linkState={link}
                                nameEditLabel={t('guests.linkName')}
                                setLinkState={(newLink) => {
                                  setPersonModal((curPersonModal) => {
                                    curPersonModal.links[idx] = newLink;
                                    return {...curPersonModal};
                                  });
                                }}
                                urlEditLabel={t('guests.linkUrl')}
                            />
                            <div key={link.url + 'delete'} className='guest-delete-button' data-idx={idx}>
                              <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  onClick={(e) => {
                                    setPersonModal((curPersonModal) => {
                                      const newLinks = curPersonModal.links.filter((link, index) => {
                                        return index !== idx;
                                      });
                                      curPersonModal.links = newLinks;
                                      return {...curPersonModal};
                                    });
                                  }}
                              />
                            </div>
                          </div>
                      );
                    })}
                  </div>
                  <button
                      className='cbutton'
                      onClick={() => {
                        if (null_or_undefined(personModal.links)) {
                          personModal.links = [
                            {
                              linkName: '',
                              description: '',
                              url: 'newLink1',
                            },
                          ];
                        } else {
                          personModal.links.push({
                            linkName: '',
                            description: '',
                            url: 'newLink' + personModal.links.length,
                          });
                        }
                        setPersonModal((curPersonModal) => {
                          return {...curPersonModal};
                        });
                      }}
                      type='button'
                  >
                    {t('guests.addLink')}
                  </button>
                </div>
                <div className='guest-details-modify-buttons'>
                  <button
                      className='cbutton deleteBt'
                      onClick={async () => {
                        await deletePerson(personModal.userId);
                        closePersonModal();
                      }}
                      type='button'
                  >
                    {t('delete')}
                  </button>
                  <div>
                    <button
                        className='cbutton cancelBt'
                        onClick={() => {
                          closePersonModal();
                        }}
                        type='button'
                    >
                      {t('cancel')}
                    </button>
                    <button
                        className='cbutton saveBt'
                        onClick={async () => {
                          try {
                            if (personModal.firstName.length === 0 || personModal.lastName.length === 0) {
                              await handleAlert(t('contactPerson.invalidName'));
                              return;
                            }

                            if (personModal.roles[0]?.roleId === null || personModal.roles[0].roleId === 0) {
                              await handleAlert(t('contactPerson.errorMustSelectCategory'));
                              return;
                            }

                            if (personModal.relation.length === 0) {
                              await handleConfirmation(t('contactPerson.warningMissingRelation'));
                            }
                            if ((personModal.phoneNumber1Number == null || personModal.phoneNumber1Number?.length === 0) &&
                                (personModal.phoneNumber2Number == null || personModal.phoneNumber2Number?.length === 0) &&
                                (personModal.phoneNumber3Number == null || personModal.phoneNumber3Number?.length === 0) &&
                                (personModal.phoneNumber4Number == null || personModal.phoneNumber4Number?.length === 0) &&
                                (personModal.phoneNumber5Number == null || personModal.phoneNumber5Number?.length === 0)) {
                              await handleConfirmation(t('contactPerson.warningMissingPhoneNumber1'));
                            }

                            await saveChangesToPerson(personModal);
                            closePersonModal();
                          } catch (error) {
                            console.log(error)
                            // return to allow for error correction
                          }
                        }}
                        type='button'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </form>
            </>
        )}
        {personModal !== null && modalType === 'create' && (
            <>
              <h1>{t('contactPerson.contactEditDetailTitle')}</h1>
              <hr/>
              <form className='guest-details-modify-form'>
                <div className='guest-form-row'>
                  <label htmlFor='contactName'>
                    <b>{`${t('contactPerson.name')}:`}</b>
                    <input
                        defaultValue={`${personModal.firstName ?? ''}`}
                        name='contactName'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.firstName = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactSurname'>
                    <b>{`${t('contactPerson.surname')}:`}</b>
                    <input
                        defaultValue={`${personModal.lastName ?? ''}`}
                        name='contactSurname'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.lastName = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactOrganization'>
                    <b>{`${t('contactPerson.organization')}:`}</b>
                    <input
                        defaultValue={`${personModal.organization ?? ''}`}
                        name='contactOrganization'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.organization = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactRelation'>
                    <b>{`${t('contactPerson.relation')}:`}</b>
                    <input
                        defaultValue={`${personModal.relation ?? ''}`}
                        name='contactOrganization'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.relation = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber1Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-1-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber1Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber1'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber1Number ?? ''}`}
                        name='contactPhoneNumber1'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber1Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber1Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber1Desc ?? ''}`}
                        name='contactPhoneNumber1Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber1Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber2Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-2-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber2Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber2'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber2Number ?? ''}`}
                        name='contactPhoneNumber2'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber2Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber2Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber2Desc ?? ''}`}
                        name='contactPhoneNumber2Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber2Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber3Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-3-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber3Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber3'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber3Number ?? ''}`}
                        name='contactPhoneNumber3'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber3Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber3Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber3Desc ?? ''}`}
                        name='contactPhoneNumber3Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber3Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber4Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-4-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber4Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber4'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber4Number ?? ''}`}
                        name='contactPhoneNumber4'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber4Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber4Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber4Desc ?? ''}`}
                        name='contactPhoneNumber4Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber4Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <Select
                      className='dropdown-select phone-type'
                      classNamePrefix='select'
                      defaultValue={convertPhoneNumberTypeValToType(personModal.phoneNumber5Type)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='phone-number-5-type'
                      onChange={(option) => {
                        setPersonModal((personModalCur) => {
                          personModalCur.phoneNumber5Type = option.value;
                          return {...personModalCur};
                        });
                      }}
                      options={PHONE_NUMBER_TYPE_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <label htmlFor='contactPhoneNumber5'>
                    <b>{`${t('contactPerson.phoneNumber')}:`}</b>
                    <input
                        className={"phone-number"}
                        defaultValue={`${personModal.phoneNumber5Number ?? ''}`}
                        name='contactPhoneNumber5'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber5Number = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                  <label htmlFor='contactPhoneNumber5Desc'>
                    <b>{`${t('contactPerson.phoneNumberInfo')}:`}</b>
                    <input
                        className={"phone-desc"}
                        defaultValue={`${personModal.phoneNumber5Desc ?? ''}`}
                        name='contactPhoneNumber5Desc'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.phoneNumber5Desc = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactSummary'>
                    <b>{`${t('contactPerson.summary')}:`}</b>
                    <input
                        defaultValue={`${personModal.summary ?? ''}`}
                        name='contactSummary'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.summary = newValue;
                            return {...personModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactNote'>
                    <b>{`${t('contactPerson.note')}:`}</b>
                    <textarea
                        defaultValue={`${personModal.note ?? ''}`}
                        name='contactNote'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.note = newValue;
                            return {...personModalCur};
                          });
                        }}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactNote'>
                    <b>{`${t('contactPerson.hasKeys')}:`}</b>
                    <Select
                        className='dropdown-select yes-no-type'
                        classNamePrefix='select'
                        defaultValue={extractHasKeyFromProp(personModal.props)}
                        getOptionLabel={option => `${t(option.label)}`}
                        isSearchable={true}
                        menuPlacement='top'
                        name='phone-number-3-type'
                        onChange={(option) => {
                          setPersonModal((personModalCur) => {
                            if (personModalCur.props === null)
                              personModalCur.props = {}
                            personModalCur.props.hasKeys = option.value;
                            return {...personModalCur};
                          });
                        }}
                        options={YES_NO_TYPE_OPTIONS}
                        placeholder={t('remindersAdminPage.filterSelectIcon')}
                        styles={{
                          singleValue: (styles, {data}) => {
                            return {...styles, color: '#797979'};
                          },
                        }}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactPriority'>
                    <b>{`${t('contactPerson.priority')}:`}</b>
                    <input
                        defaultValue={`${personModal.priority ?? 0}`}
                        name='contactPriority'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPersonModal((personModalCur) => {
                            personModalCur.priority = newValue;
                            return {...personModalCur};
                          });
                        }}
                        step={1}
                        type={'number'}
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='contactRole'>
                    <b>{`${t('contactPerson.category')}: `}</b>
                    <AsyncSelect
                        cacheOptions={false}
                        className='dropdown-select-fi'
                        classNamePrefix='select'
                        defaultOptions={true}
                        defaultValue={
                          {
                            value: personModal?.roles[0].roleId,
                            label: personModal?.roles[0].name
                          }
                        }
                        getOptionLabel={option => `${t(option.label)}`}
                        isSearchable={false}
                        loadOptions={getContactRoles}
                        menuPlacement='top'
                        name='contactRole'
                        onChange={(option) => {
                          setPersonModal((personModalCur) => {
                            personModalCur.roles = []
                            personModalCur.roles.push({"roleId": option.value.id});
                            return {...personModalCur};
                          });
                        }}
                        placeholder={t('remindersAdminPage.filterSelectIcon')}
                        styles={{
                          singleValue: (styles, {data}) => {
                            return {...styles, color: '#797979'};
                          },
                        }}
                    />
                  </label>
                </div>
                <div className='guest-links-section'>
                  <h3>{`${t('contactPerson.links')}:`}</h3>
                  <div className='guest-links-list'>
                    {personModal.links?.map((link, idx) => {
                      return (
                          <div className='guest-link-raw'>
                            <ResourceLink
                                key={idx}
                                className='guest-form-row'
                                descriptionEditLabel={t('guests.linkDescription')}
                                edit={true}
                                editDivClassName='guest-form-link-edit'
                                linkState={link}
                                nameEditLabel={t('guests.linkName')}
                                setLinkState={(newLink) => {
                                  setPersonModal((curPersonModal) => {
                                    curPersonModal.links[idx] = newLink;
                                    return {...curPersonModal};
                                  });
                                }}
                                urlEditLabel={t('guests.linkUrl')}
                            />
                            <div key={link.url + 'delete'} className='guest-delete-button' data-idx={idx}>
                              <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  onClick={(e) => {
                                    setPersonModal((curPersonModal) => {
                                      const newLinks = curPersonModal.links.filter((link, index) => {
                                        return index !== idx;
                                      });
                                      curPersonModal.links = newLinks;
                                      return {...curPersonModal};
                                    });
                                  }}
                              />
                            </div>
                          </div>
                      );
                    })}
                  </div>
                  <button
                      className='cbutton'
                      onClick={() => {
                        if (null_or_undefined(personModal.links)) {
                          personModal.links = [
                            {
                              linkName: '',
                              description: '',
                              url: 'newLink1',
                            },
                          ];
                        } else {
                          personModal.links.push({
                            linkName: '',
                            description: '',
                            url: 'newLink' + personModal.links.length,
                          });
                        }
                        setPersonModal((curPersonModal) => {
                          return {...curPersonModal};
                        });
                      }}
                      type='button'
                  >
                    {t('guests.addLink')}
                  </button>
                </div>
                <div className='guest-details-modify-buttons'>
                  <div></div>
                  <div>
                    <button
                        className='cbutton cancelBt'
                        onClick={() => {
                          closePersonModal();
                        }}
                        type='button'
                    >
                      {t('cancel')}
                    </button>
                    <button
                        className='cbutton saveBt'
                        onClick={async () => {
                          try {
                            if (personModal.firstName.length === 0 || personModal.lastName.length === 0) {
                              await handleAlert(t('contactPerson.invalidName'));
                              return;
                            }

                            if (personModal.roles[0]?.roleId === null || personModal.roles[0].roleId === 0) {
                              await handleAlert(t('contactPerson.errorMustSelectCategory'));
                              return;
                            }

                            if (personModal.relation.length === 0) {
                              await handleConfirmation(t('contactPerson.warningMissingRelation'));
                            }
                            if ((personModal.phoneNumber1Number == null || personModal.phoneNumber1Number?.length === 0) &&
                                (personModal.phoneNumber2Number == null || personModal.phoneNumber2Number?.length === 0) &&
                                (personModal.phoneNumber3Number == null || personModal.phoneNumber3Number?.length === 0) &&
                                (personModal.phoneNumber4Number == null || personModal.phoneNumber4Number?.length === 0) &&
                                (personModal.phoneNumber5Number == null || personModal.phoneNumber5Number?.length === 0)) {
                              await handleConfirmation(t('contactPerson.warningMissingPhoneNumber1'));
                            }

                            await requestPersonAddition(selectedInst, personModal);
                            closePersonModal();
                          } catch(error) {
                            // return to allow for error correction
                          }
                        }}
                        type='button'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </form>
            </>
        )}
      </Modal>
    );
  }

  return (
      <div className={standalone ? "contact-persons-div" : "contact-persons-div-embedded"}>
        <h1 className={standalone ? "contact-persons-header" : "contact-persons-header-embedded"} onClick={toggleShow}>
          {standalone && (
              <>
                <FontAwesomeIcon icon={(show ? faArrowCircleUp : faArrowCircleDown)}/>
                <span
                    style={{marginLeft: "5px"}}>{`${t('contactPersons.title')}: ${persons !== null ? persons.size : '...'}`}</span>
              </>
          )}
          {!standalone && (
              <span>{`${t('contactPersons.title')}`}</span>
          )}
          {allowModify && (
              <span className='add-contact-person-icon'>
            <FontAwesomeIcon icon={faPlusCircle} onClick={handleAddNewPerson}/>
          </span>
          )}
        </h1>
        <hr/>
        {renderModal()}
        {standalone && (
            <CSSTransition classNames="contacts-trans" in={show} nodeRef={nodeRef} timeout={250} unmountOnExit>
              <div ref={nodeRef}>
                {renderContacts()}
              </div>
            </CSSTransition>
        )}
        {!standalone && (
            renderContacts()
        )}
      </div>
  );
}
