import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import { not_null_or_undefined } from '../utilities';
import cloneDeep from 'lodash/cloneDeep';

export default function DisplayOptionModal({displayOptions, setDisplayOptions, updateDisplayOptions}) {
  const {t/*, i18n*/} = useTranslation();

  const [orderingCriterion, setOrderingCriterion] = useState(null);

  const newCriterion = {priority: 0, criterionName: 'name', ordering: 'asc'};

  function saveOptions() {    
    updateDisplayOptions(displayOptions);
    closeModal();
  }

  function closeModal() {
    setDisplayOptions(null);
  }

  function appendCriterion() {
    setDisplayOptions( (cur) => {cur.orderingCriteria.push(orderingCriterion); return cloneDeep(cur);} ); 
    setOrderingCriterion(null);
  }

  function removeCriterion(index) {
    setDisplayOptions( (cur) => {
      cur.orderingCriteria[index] = null; 
      cur.orderingCriteria = cur.orderingCriteria.filter(el => el !== null);
      return cloneDeep(cur);
    } ); 
  }

  function decodeShowSafetySensors(type) {
    switch(type) {
      case 'true':
        return {value: 'true', label: `${t('Yes')}`};
      case 'separated':
        return {value: 'separated', label: `${t('statusPage.separatedSafetyDisplay')}`};
      case 'false':
      default:
        return {value: 'false', label: `${t('No')}`};
    }
  }

  function getCategoryOptions(priority) {
    switch(priority) {
      case 0: //group, if it can be a group
        return [
          {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
          {value: 'category', label: `${t('statusPage.orderCriterion_category')}`},       //can be a group
          {value: 'room', label: `${t('statusPage.orderCriterion_room')}`},               //can be a group
          {value: 'events', label: `${t('statusPage.orderCriterion_events')}`},
          {value: 'alerts', label: `${t('statusPage.orderCriterion_alerts')}`},           //can be a special group
          {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
          {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
        ];
      
      case 1: //order inside the group, even inside a special group or a "uncategorized" group
        switch(displayOptions.orderingCriteria[0].criterionName) {
          default:
            return [];

          case 'category':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
              {value: 'room', label: `${t('statusPage.orderCriterion_room')}`},
              {value: 'events', label: `${t('statusPage.orderCriterion_events')}`},
              {value: 'alerts', label: `${t('statusPage.orderCriterion_alerts')}`},
              {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
              {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
            ];

          case 'room':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
              {value: 'category', label: `${t('statusPage.orderCriterion_category')}`},
              {value: 'events', label: `${t('statusPage.orderCriterion_events')}`},
              {value: 'alerts', label: `${t('statusPage.orderCriterion_alerts')}`},
              {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
              {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
            ];

          case 'events':
          case 'alerts':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
              {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
              {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
            ];
        }
      
      case 2:
        switch(displayOptions.orderingCriteria[1].criterionName) {
          default:
            return [];

          case 'room':
          case 'category':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
              {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
              {value: 'events', label: `${t('statusPage.orderCriterion_events')}`},
              {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
            ];

          case 'events':
          case 'alerts':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
              {value: 'default', label: `${t('statusPage.orderCriterion_default')}`},
              {value: 'last-change', label: `${t('statusPage.orderCriterion_last-change')}`}
            ];
        }

      case 3:
        switch(displayOptions.orderingCriteria[1].criterionName) {
          default:
            return [];

          case 'Events':
            return [
              {value: 'name', label: `${t('statusPage.orderCriterion_name')}`},
            ];
        }

      default:
        return [];
    }
  }

  return (
    <Modal
      onClose={closeModal}
      open={not_null_or_undefined(displayOptions)}
    >
      {not_null_or_undefined(displayOptions) && (
        <>
          <div className="modal-dialog-container col3">
            <h3>
              {`${t('statusPage.displayOptionsModalTitle')}`}
              <hr/>
            </h3>

            <label
                className="checkbox-label modal-show-status-categories-checkbox modal-dialog-full-width"
                htmlFor="modal-show-status-categories-checkbox"
            >
              <input
                  defaultChecked={displayOptions.showStatusCategories}
                  id="modal-show-status-categories-checkbox"
                  name="modal-show-status-categories-checkbox"
                  onChange={() => {
                    displayOptions.showStatusCategories = !displayOptions.showStatusCategories
                  }}
                  type="checkbox"
              />
              {t('statusPage.showStatusCategories')}
              <span className="checkmark"/>
            </label>

            <label
                className="checkbox-label modal-default-show-favorites-checkbox modal-dialog-full-width"
                htmlFor="modal-default-show-favorites-checkbox"
            >
              <input
                  defaultChecked={displayOptions.defaultShowFavorites}
                  id="modal-default-show-favorites-checkbox"
                  name="modal-default-show-favorites-checkbox"
                  onChange={() => {
                    displayOptions.defaultShowFavorites = !displayOptions.defaultShowFavorites
                  }}
                  type="checkbox"
              />
              {t('statusPage.defaultShowFavorites')}
              <span className="checkmark"/>
            </label>

            <label
                className="checkbox-label modal-default-show-favorites-checkbox modal-dialog-full-width"
                htmlFor="modal-default-show-dev-assign-checkbox"
            >
              <input
                  defaultChecked={displayOptions.showDeviceAssignation}
                  id="modal-default-show-dev-assign-checkbox"
                  name="modal-default-show-dev-assign-checkbox"
                  onChange={() => {
                    displayOptions.showDeviceAssignation = !displayOptions.showDeviceAssignation
                  }}
                  type="checkbox"
              />
              {t('statusPage.showDeviceAssignation')}
              <span className="checkmark"/>
            </label>

            <div className="modal-dialog-full-width">
              <label className="select-label">{`${t('statusPage.showSafetySensors')}`}</label>
              <div className="select-control">
                <Select
                    defaultValue={decodeShowSafetySensors(displayOptions.showSafetySensors)}
                    isSearchable={false}
                    menuPlacement="top"
                    name="action-type"
                    onChange={(option) => {
                      displayOptions.showSafetySensors = option.value
                    }}
                    options={[
                      {value: 'true', label: `${t('Yes')}`},
                      {value: 'separated', label: `${t('statusPage.separatedSafetyDisplay')}`},
                      {value: 'false', label: `${t('No')}`}
                    ]}
                />
              </div>
            </div>

            <div className="modal-dialog-table-container modal-dialog-full-width">
              <table className="modal-dialog-table">
                <thead>
                <tr>
                  <th>
                    {`${t('statusPage.orderingCriterionPriority')}`}
                  </th>
                  <th>
                    {`${t('statusPage.orderingCriterionName')}`}
                  </th>
                  <th>
                    {`${t('statusPage.orderingCriterionOrdering')}`}
                  </th>
                  <th>
                    {(getCategoryOptions(displayOptions.orderingCriteria.length).length > 0) && (
                        <FontAwesomeIcon
                            className="clickable"
                            icon={faPlusCircle}
                            onClick={() => {
                              setOrderingCriterion({...newCriterion, priority: displayOptions.orderingCriteria.length})
                            }}
                            title={`${t('add')}`}
                        />
                    )}
                  </th>
                </tr>
                </thead>
                <tbody>
                {displayOptions.orderingCriteria.map((criterion, index) => {
                  return (
                      <tr key={index}>
                        <td>
                          {criterion.priority}
                        </td>
                        <td>{t(`statusPage.orderCriterion_${criterion.criterionName}`)}</td>
                        <td>{t(`statusPage.orderCriterion_${criterion.ordering}`)}</td>
                        <td>
                          {(displayOptions.orderingCriteria.length === (index + 1)) && (
                              <FontAwesomeIcon
                                  className="clickable"
                                  icon={faMinusCircle}
                                  onClick={() => {
                                    removeCriterion(index)
                                  }}
                                  title={`${t('remove')}`}
                              />
                          )}
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>

            {(orderingCriterion !== null) && (
                <>
                  <div>
                    <label className="input-label">{`${t('statusPage.orderingCriterionPriority')}`}</label>
                    <input
                        className="modal-dialog-input"
                        defaultValue={orderingCriterion.priority}
                        readOnly={true}
                        type="text"
                    />
                  </div>

                  <div>
                    <label className="select-label">{`${t('statusPage.orderingCriterionName')}`}</label>
                    <div className="select-control">
                      <Select
                          defaultValue={{
                            value: orderingCriterion.criterionName,
                            label: t(`statusPage.orderCriterion_${orderingCriterion.criterionName}`)
                          }}
                          isSearchable={false}
                          maxMenuHeight={150}
                          menuPlacement="top"
                          name="action-type"
                          onChange={(option) => {
                            orderingCriterion.criterionName = option.value
                          }}
                          options={getCategoryOptions(displayOptions.orderingCriteria.length)}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="select-label">{`${t('statusPage.orderingCriterionOrdering')}`}</label>
                    <div className="select-control">
                      <Select
                          defaultValue={
                            {
                              value: orderingCriterion.ordering,
                              label: t(`statusPage.orderCriterion_${orderingCriterion.ordering}`)
                            }
                          }
                          isSearchable={false}
                          menuPlacement="top"
                          name="action-type"
                          onChange={(option) => {
                            orderingCriterion.ordering = option.value
                          }}
                          options={[
                            {value: 'asc', label: `${t('statusPage.orderCriterion_asc')}`},
                            {value: 'desc', label: `${t('statusPage.orderCriterion_desc')}`},
                          ]}
                      />
                    </div>
                  </div>
                  <button className="cbutton modal-buttons full-line-modal-buttons" onClick={() => {
                    appendCriterion()
                  }} type="button">{`${t('ADD')}`}</button>
                </>
            )}

            <button className="cbutton modal-buttons full-line-modal-buttons" onClick={() => {
              saveOptions()
            }} type="button">{`${t('SAVE')}`}</button>
          </div>
        </>
      )}
    </Modal>
  )
}
