import React, { useState, useContext } from 'react';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import { useTranslation } from 'react-i18next';
import { InstallationsContext, hasCapability } from './App';
import ActiveTile from './ActiveTile';
import { extractFileName, timestampToDate } from '../utilities';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import MainArea from './MainArea';
import ResourceLink from './ResourceLink';
import FooterSection from './FooterSection';
import StaticBanner from './StaticBanner';
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api";

export default function TechPage({ currRoute, phyDevicesInfo }) {
  const {t, i18n} = useTranslation();
  const { 
    selectedInst, 
    installations,
    loadInstPhyDevicesInfos,
    requestPhyDeviceDetails
  } = useContext(InstallationsContext);
  const [phyDeviceModal, setPhyDeviceModal] = useState(null);
  const [requestingPhyDevices, setRequestingPhyDevices] = useState(false);
  
  let mainContentRef = React.createRef();

  function phyDeviceOnClickHandler(phyDevice) {
    setPhyDeviceModal(phyDevice);
    updatePhyDeviceModal(selectedInst, phyDevice.id);
  }

  function closePhyDeviceModal() {
    setPhyDeviceModal(null);
  }

  async function updatePhyDeviceModal(inst, id) {
    const phyDeviceDetails = await requestPhyDeviceDetails(inst, id);
    setPhyDeviceModal(phyDeviceDetails);
  }

  function isPhyDevicesInfoValid() {
    const valid = selectedInst !== null && (phyDevicesInfo instanceof Map) && (phyDevicesInfo.size > 0);
    return valid;
  }

  function decodeState(isConnected, isBatteryLow) {
    if(!isConnected) {
      return "error-state";
    }

    if(isBatteryLow) {
      return "warning-state";
    }

    return "active-state";
  }

  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  if( (selectedInst !== null) && (!(phyDevicesInfo instanceof Map)) && (!requestingPhyDevices)) {
    setRequestingPhyDevices(true);
    loadInstPhyDevicesInfos(selectedInst);
  }
  
  return (
    <>
      <HeaderSection 
        currRoute={currRoute} 
        mainContentRef={mainContentRef} 
      />
      <section className="main-content" ref={mainContentRef}>
        {selectedInstallationData && (
          <>
            {(selectedInstallationData.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) && (
              <StaticBanner 
                backgroundColor="#d9534f"
                color="white" 
                icon='exclamation-triangle' 
                message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(selectedInstallationData.lastStatusUpdate).toLocaleString(i18n.language)}`}
              />
            )}
            {(hasCapability(selectedInstallationData, 'alerts-show')) && (
              <Alerts 
                alerts={selectedInstallationData.alerts} 
                canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')} 
                installationStatus={selectedInstallationData.status}
              />
            )}
            <Modal
              onClose={closePhyDeviceModal}
              open={phyDeviceModal !== null}
            >
              {/*{console.log(phyDeviceModal)}*/}
              {
                phyDeviceModal !== null && (
                      <>
                        <h1>{t('techPage.phyDeviceDetailTitle')}</h1>
                        <hr/>
                        <p><b>{`${t('techPage.room')}:`}</b> {`${phyDeviceModal.roomName}`}</p>
                        <p><b>{`${t('techPage.name')}:`}</b> {`${phyDeviceModal.name}`}</p>
                        <p><b>{`${t('techPage.position')}:`}</b> {`${phyDeviceModal.position}`}</p>
                        <p><b>{`${t('techPage.status')}:`}</b> {`${phyDeviceModal.stateDesc}`}</p>
                        <p>
                          <b>{`${t('techPage.productName')}:`}</b> {`${phyDeviceModal.productName ? phyDeviceModal.productName : "..."}`}
                        </p>
                        <p>
                          <b>{`${t('techPage.manufacturer')}:`}</b> {`${phyDeviceModal.manufacturer ? phyDeviceModal.manufacturer : "..."}`}
                        </p>
                        <p>
                          <b>{`${t('techPage.productNumber')}:`}</b> {`${phyDeviceModal.productNumber ? phyDeviceModal.productNumber : "..."}`}
                        </p>
                        <p>
                          <b>{`${t('techPage.orderCode')}:`}</b> {`${phyDeviceModal.orderCode ? phyDeviceModal.orderCode : "..."}`}
                        </p>
                        <p>
                          <b>{`${t('techPage.deviceSerialNumber')}:`}</b> {`${phyDeviceModal.serialNumber != null ? phyDeviceModal.serialNumber : "..."}`}
                        </p>
                          {phyDeviceModal?.notes != null && phyDeviceModal?.notes !== "" && (
                              <p>
                                <b>{`${t('techPage.deviceNotes')}:`}</b> {`${phyDeviceModal.notes ? phyDeviceModal.notes : "..."}`}
                              </p>)
                          }
                        {phyDeviceModal.links && (
                            phyDeviceModal.links.map((link) => {
                              return (
                                  <ResourceLink
                                      key={link.url}
                                      className="installation-desc-link"
                                      linkState={link}
                                  />
                              );
                            })
                        )}
                      </>
                  )
              }
            </Modal>
            <MainArea title={`${t("techPage.title")}`}>
              {isPhyDevicesInfoValid() && (
                  <>
                    {[...phyDevicesInfo.values()].map( (deviceData) => {
                    return <ActiveTile 
                      key={deviceData.id}
                      descType={""}
                      details={deviceData.stateDesc}
                      iconName={extractFileName(deviceData.iconURL)}
                      onClickHandler={() => {phyDeviceOnClickHandler(deviceData)}}
                      tileId={deviceData.id}
                      tileState={decodeState(deviceData.isConnected, deviceData.isBatteryLow)}
                      title={deviceData.name}
                      />
                    })
                  }
                </>
              )} 
              {!isPhyDevicesInfoValid() && (
                <p>{`${t("techPage.loadingMessage")}`}</p>
              )} 
            </MainArea>
          </>
        )}
        <FooterSection/>
      </section>
    </>
  )
}
