import React from 'react'
import { not_null_or_undefined, timestampToDate } from '../utilities';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import 'moment/min/locales';
import moment from 'moment'

ChartJS.register(LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip);

export default function ScatterGraph({graphClassName, dataToRender, unit, locale, lineColor, decimal}) {
//  const startTimestamp = dataToRender[0].timestamp;

  const inputData = dataToRender.map((point) => {
//    return {x: point.timestamp - startTimestamp, y: point.value};
    return {x: timestampToDate(point.timestamp), y: point.value};
  });

  moment.locale(locale);

  const data = {
    datasets: [{
      data: inputData,
      borderColor: lineColor,
      fill: false,
      showLine: true
    }]
  };

  const options = {
    fill: false,
    animation: false,
    color: 'red',
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context) {
            // console.log(context);
            return `${context.label}: ${context.formattedValue} ${unit}`;
          }
        }
      },
        legend: {
        display: false
      }
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        position: 'bottom',
        time: {
          // unit: 'day'
          tooltipFormat: 'L LT',
          displayFormats: locale !== 'en' ? {
            hour: 'H',
            minute: 'H:mm',
            second: 'H:mm:ss',
            day: 'MMM D'
          } : {
            hour: 'h a',
            minute: 'h:mm a',
            second: 'h:mm:ss a',
            day: 'MMM D'
          }
        }
      },
      y: {
        display: true,
        ticks: {
          beginAtZero: true,
          callback: not_null_or_undefined(unit) ? function(value, index, values) {
            return `${value} ${unit}`;
          } : value => value,
          precision: decimal
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    normalized: true
  };

  return (
    <div className={graphClassName}>
      <Scatter
        // data={dataFn}
        data={data}
        height={350}
        options={options}
      />
    </div>
  );
}
