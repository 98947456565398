import { timestampToLocalStringO, secondsToElapsedTimeStr } from './utilities';
import { DEFAULT_COLORS } from './Contants';
export class ChartDataPoint
{
  constructor(timestamp, label, value, color) {
    this.timestamp = timestamp;
    if(label === null) {
      this.label = '';
    } else {
      this.label = label;
    }

    this.value = value;
    this.color = color;
  }

  getTimestamp() {
    return this.timestamp;
  }

  getLabel() {
    return this.label;
  }

  getValue(conversion) {
    switch(conversion) {
      case 1: // flat conversion
        return 1;
  
      case 2: // add 1
        return this.value + 1;
  
      default:
        // no conversion
        return this.value;
    }
  }

  getBackgroundColor() {
    if(this.color != null)
      return this.color;

    switch(this.value) {
      default:
      case 0:
        return DEFAULT_COLORS.NORMAL;
      case 1:
        return DEFAULT_COLORS.ACTIVE;
      case 2:
        return DEFAULT_COLORS.WARNING;
      case 3:
        return DEFAULT_COLORS.ERROR;
      }
  }

  getBorderColor() {
    if(this.color != null)
      return this.color;
    
    switch(this.value) {
      default:
      case 0:
        return DEFAULT_COLORS.NORMAL;
      case 1:
        return DEFAULT_COLORS.ACTIVE;
      case 2:
        return DEFAULT_COLORS.WARNING;
      case 3:
        return DEFAULT_COLORS.ERROR;
    }
  }
}

export function generateEventBarArray(events, startTime, endTime, nSteps, valueMap, locale, hideMinutes) {
  let timeFormatOptions = {};
  if(endTime - startTime <= 86400) {
    //timeFormatOptions = {...timeFormatOptions, hour:'numeric', minute:'numeric'};
    timeFormatOptions = {...timeFormatOptions, hour:'numeric'};
    if(!hideMinutes) {
      timeFormatOptions = {...timeFormatOptions, minute:'numeric'};
    }
  }
  if(endTime - startTime < 86400 * 7) {
    timeFormatOptions = {...timeFormatOptions, hour:'numeric'};
    if(!hideMinutes) {
      timeFormatOptions = {...timeFormatOptions, minute:'numeric'};
    }
  }
  if(endTime - startTime > 86400) {
    timeFormatOptions = {...timeFormatOptions, month:'numeric', day:'numeric'};
  }
  if(endTime - startTime > 86400 * 365) {
    timeFormatOptions = {...timeFormatOptions, year:'2-digit'};
  } 

  if(endTime > events[events.length - 1].timestamp)
    endTime = events[events.length - 1].timestamp;

  const timeStep = Math.round((endTime - startTime) / nSteps);
  // console.log('endTime', endTime);
  endTime = startTime + timeStep * nSteps;
  // console.log('timeStep: ', timeStep, 'startTime: ', startTime, 'endTime', endTime);
  let data = [];
  let eStart = 0;
  // let eStart = 1;
  // console.log('----> START RENDERING <----');
  // console.log(events);
  for(let n_inter = 0, start = startTime, end = startTime + timeStep; end <= endTime; start += timeStep, end += timeStep, n_inter++) {
    // console.log(`start: ${start}, end: ${end}, timeStep: ${timeStep}, n_inter: ${n_inter}`)

    let lastValue = parseInt(events[eStart].value);
    let lastTimestamp = events[eStart].timestamp;
    // console.log(`lastValue: ${lastValue}, lastTimestamp: ${lastTimestamp}`);
//    let val = 0;
    let intervalValMap = new Map;
    for(let i = eStart + 1; i < events.length; ++i) {
      if((lastTimestamp <= start) && (events[i].timestamp >= end)) {
        data.push(new ChartDataPoint(
          start,
          timestampToLocalStringO(start, locale, timeFormatOptions), 
          lastValue,
          valueMap.has(lastValue) ? valueMap.get(lastValue).color : null
          )
        );
        break;
      }

      if(events[i].timestamp >= end) {
        // val += lastValue * (end - lastTimestamp) / timeStep;
        // const roundedVal = Math.round(val);

        if(intervalValMap.has(lastTimestamp)) {
          intervalValMap.set(lastValue, intervalValMap.get(lastValue) + (end - lastTimestamp) / timeStep);
        } else {
          intervalValMap.set(lastValue, (end - lastTimestamp) / timeStep);
        }

        let prevalentVal = {"value": -1, "fraction" : 1.0};
        if(intervalValMap.size >= 1) {
          const vv = intervalValMap.entries().next().value
          prevalentVal = {"value": vv[0], "fraction" : vv[1]};

          for(const [value, fraction] of intervalValMap) {
            if(fraction > prevalentVal.fraction) {
              prevalentVal = {value, fraction}
            }
          }
        }

        data.push(new ChartDataPoint(
          start,
          timestampToLocalStringO(start, locale, timeFormatOptions),
          prevalentVal.value,
          valueMap.has(prevalentVal.value) ? valueMap.get(prevalentVal.value).color : null
          )
        );
        // console.log(`n_iter: ${n_inter}, value: ${val}, rounded: ${Math.round(val)}`)
        break;
      }
//      val += lastValue * (events[i].timestamp - ((lastTimestamp < start) ? start : lastTimestamp)) / timeStep;

      if(intervalValMap.has(lastTimestamp)) {
        intervalValMap.set(lastValue, intervalValMap.get(lastValue) + (events[i].timestamp - ((lastTimestamp < start) ? start : lastTimestamp)) / timeStep);
      } else {
        intervalValMap.set(lastValue, (events[i].timestamp - ((lastTimestamp < start) ? start : lastTimestamp)) / timeStep);
      }

      lastValue = parseInt(events[i].value);
      lastTimestamp = events[i].timestamp;
      eStart = i;
    }
  }
  // console.log(data);
  return data;
}

function splitData(data) 
{
  let labels = [];
  let values = [];
  let backgroundColors = [];
  let borderColors = [];
  let timestamps = [];

  data.forEach((value, index, array) => {
    timestamps.push(value.getTimestamp());
    labels.push(value.getLabel());
    values.push(value.getValue(1));
    backgroundColors.push(value.getBackgroundColor());
    borderColors.push(value.getBorderColor());
  });
  return [timestamps, labels, values, backgroundColors, borderColors];
}

export function createEventChartInfo(inputData, valueMap, translator, locale, numberOfBars = 720,
                                     tooltipType = "full", displayXScale = true, xScaleTitle = '')
{
  const [timestamps, labels, rawData, backgroundColors, borderColors] = splitData(inputData);
  const data = {
    labels: labels,
    datasets: [{
      // label: 'in bed',
      data: rawData,
      backgroundColor: backgroundColors,
      hoverBackgroundColor: backgroundColors,
      borderColor: borderColors,
      borderWidth: 0,
      barPercentage: 1.0,
      categoryPercentage: 1.0,
      timestamps: timestamps
    }]
  };
  const options = {
    animation: false,
    plugins: {
      tooltip: {
        enabled: tooltipType !== 'no',
        displayColors: true,
        callbacks: {
          title: function(context) {      
            // console.log(context)
            if(tooltipType === "valueOnly" || tooltipType === "intervalOnly" || tooltipType === "valueIntervalDurationOnly")
              return;
            let step = context[0].dataset.timestamps[1] - context[0].dataset.timestamps[0];
            let start = context[0].dataset.timestamps[0];
            let end = context[0].dataset.timestamps[0] + context[0].dataset.data.length * step;
            let interval = end - start;

            let timeFormatOptions = {};
            if(interval < 86400 * numberOfBars) {
              timeFormatOptions = {...timeFormatOptions, minute:'numeric'};
            }
            if(interval < 86400 * numberOfBars) {
              timeFormatOptions = {...timeFormatOptions, hour:'numeric'};
            }
            timeFormatOptions = {...timeFormatOptions, month:'numeric', day:'numeric'};
            if(interval > 86400 * 365) {
              timeFormatOptions = {...timeFormatOptions, year:'2-digit'};
            } 

            return timestampToLocalStringO(context[0].dataset.timestamps[context[0].dataIndex], locale, timeFormatOptions);
          },
          label: function(context) {
            // console.log(context);
            if(valueMap.has(context.element.options.backgroundColor)) {
              let label = valueMap.get(context.element.options.backgroundColor).desc;
              // console.log(context.element.options.backgroundColor, valueMap, label)
              if(tooltipType === "valueOnly" || tooltipType === "valueAndTime") {
                return label;
              }

              let step = context.dataset.timestamps[1] - context.dataset.timestamps[0];
              let start = context.dataset.timestamps[0];
              let end = context.dataset.timestamps[0] + context.dataset.data.length * step;
              if(context.dataIndex > 0) {
                for(let i = context.dataIndex - 1; i >= 0; --i) {
                  if(context.dataset.backgroundColor[i] !== context.element.options.backgroundColor) {
                    start = context.dataset.timestamps[i + 1];
                    break;
                  }
                }
              }
              if(context.dataIndex < context.dataset.data.length - 1) {
                for(let i = context.dataIndex; i < context.dataset.data.length; ++i) {
                  if(context.dataset.backgroundColor[i] !== context.element.options.backgroundColor) {
                    end = context.dataset.timestamps[i];
                    break;
                  }
                }
              }

              if(tooltipType === "intervalOnly" || tooltipType === "intervalAndTime") {
                label += " " + translator('timeDurationFor') + " " + secondsToElapsedTimeStr(end - start, translator);
                return label;
              }

              if(tooltipType === "valueIntervalDurationOnly" || tooltipType === "full" ) {
                let interval = end - start;
                let timeFormatOptions = {};
                if(interval < 86400 * numberOfBars) {
                  timeFormatOptions = {...timeFormatOptions, minute:'numeric'};
                }
                if(interval < 86400 * numberOfBars) {
                  timeFormatOptions = {...timeFormatOptions, hour:'numeric'};
                }
                timeFormatOptions = {...timeFormatOptions, month:'numeric', day:'numeric'};
                if(interval > 86400 * 365) {
                  timeFormatOptions = {...timeFormatOptions, year:'2-digit'};
                } 
                  
                label += ` ${translator('timeDurationFrom')} ${timestampToLocalStringO(start, locale, timeFormatOptions)} ${translator('timeDurationTo')} ${timestampToLocalStringO(end, locale, timeFormatOptions)} (${secondsToElapsedTimeStr(end - start, translator)})`;
                return label;
              }              
            } else {
              return '';
            }
            return context.formattedValue;
          }
        }
      },
        legend: {
        display: false
      }
    },
    scales: {
      y: {
        display: false,
        ticks: {
            beginAtZero: true
        }
      },
      x: {
        display: displayXScale,
        ticks:{
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function(val, index) {
            if(index === 0)
              return '';
            // return index % 12 === 0 ? this.getLabelForValue(val) : '';
            return " " + this.getLabelForValue(val) + " ";
          },
          maxRotation: 0,
          minRotation: 0,
          padding: 0
        },
        title: {display: xScaleTitle !== '', text: xScaleTitle}
        // gridLines:{
        //     display: false,
        //     color: "#fff",
        //     lineWidth:2
        //}
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    normalized: true
  };
  return {data, options};
}

export function createPieChartInfo(infos)
{
  const {
    normalPerc, 
    activePerc, 
    warningPerc, 
    errorPerc, 
    normalLabel, 
    activeLabel, 
    warningLabel, 
    errorLabel, 
    normalColor, 
    activeColor, 
    warningColor, 
    errorColor
  } = infos;

  let labels = [];
  let dataPercentage = [];
  let bkgColors = [];

  if(normalPerc) {
    labels.push(normalLabel ?? '');
    dataPercentage.push(normalPerc);
    bkgColors.push(normalColor ?? DEFAULT_COLORS.NORMAL);
  }

  if(activePerc) {
    labels.push(activeLabel ?? '');
    dataPercentage.push(activePerc);
    bkgColors.push(activeColor ?? DEFAULT_COLORS.ACTIVE);
  }

  if(warningPerc) {
    labels.push(warningLabel ?? '');
    dataPercentage.push(warningPerc);
    bkgColors.push(warningColor ?? DEFAULT_COLORS.WARNING);
  }

  if(errorPerc) {
    labels.push(errorLabel ?? '');
    dataPercentage.push(errorPerc);
    bkgColors.push(errorColor ?? DEFAULT_COLORS.ERROR);
  }

  const data = {
    labels: labels,
    datasets: [{
      // label: 'in bed',
      data: dataPercentage,
      backgroundColor: bkgColors
    }]
  };

  const options = {
    animation: false,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: true            
  };
  
  return {data, options};
}
