import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-responsive-modal';
import Select, { createFilter } from 'react-select';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import FooterSection from './FooterSection';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import { hasCapability, InstallationsContext } from './App';
import { useTranslation } from 'react-i18next';
import { timestampToDate, dateToTimestamp, /*secondsToElapsedTimeStr, timestampToLocalStringO,*/ colourNameToHex, secondsToElapsedTimeStr, timestampToLocalStringO } from '../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faPlusCircle, faStepBackward, faStepForward, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import dateSub from 'date-fns/sub';
import { DEFAULT_COLORS } from '../Contants';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../css/graphical.css';
import BarGraph from './BarGraph';
import ScatterGraph from './ScatterGraph';
import StaticBanner from './StaticBanner';
import { Puff } from "react-loader-spinner";
import HelpButton from "./HelpButton";
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api";

const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

export const GRAPH_COLORS_POOL = [
  '#75ac65',
  '#f39c12',
  '#d9534f',
  '#993366',
  '#3d6dc3',
  '#0033cc',
  '#666699',
  '#999999'
];

//Used by react-datepicker 
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

export default function TrendPage({ currRoute }) {
  let mainContentRef = React.createRef();

  const {t, i18n} = useTranslation();
  const { 
    installations,
    selectedInst,    
    devicesState,
    requestDeviceData,
  } = React.useContext(InstallationsContext);

  const NUMBER_OF_BARS = 720;

  const [devToGraph, setDevToGraph] = useState([]);
  const [startSeconds, setStartSeconds] = useState(3600 * 9);
  const [timeInterval, setTimeInterval] = useState(3600 * 24);
  const [cannotChangeParams, setCannotChangeParams] = useState(false);
  const [newDevice, setNewDevice] = useState(null);
  const mustUpdate = useRef('no');

  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  
  function renderLoadingSpinner() {
    return (
      <div 
        className="embedded-loading-overlay-page" 
        style={cannotChangeParams ? null : {display: 'none'}}
      >
        <Puff
          color="#00BFFF"
          height={100}
          visible={cannotChangeParams === true}
          width={100}
          wrapperClass="loading-spinner"
            // timeout={3000} //3 secs
        />
      </div>
    );
  }

  function closeAddGraphModal()
  {
    setNewDevice(null);
  }

  // const onChange = selectedOptions => {
  //   mustUpdate.current = 'new';
  //   setDevToGraph(selectedOptions ?? [])
  // };

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   mustUpdate.current = 'new';
  //   const newValue = arrayMove(devToGraph, oldIndex, newIndex) ?? [];
  //   setDevToGraph(newValue);
  // };

  function extractLabelMaps(percentages, normalColor, activeColor, warningColor, errorColor) {
    let colors = new Map();
    let values = new Map();

    if(percentages == null)
      return {values, colors};

    percentages.forEach((percentage) => {
      switch(percentage.valueCase) {
        default:
        case 0:
          percentage.color = normalColor ?? DEFAULT_COLORS.NORMAL;
          break;
        case 1:
          percentage.color = activeColor ?? DEFAULT_COLORS.ACTIVE;
          break;
        case 2:
          percentage.color = warningColor ?? DEFAULT_COLORS.WARNING;
          break;
        case 3:
          percentage.color = errorColor ?? DEFAULT_COLORS.ERROR;
          break;
      }

      values.set(percentage.valueCase, {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
      colors.set(colourNameToHex(percentage.color), {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
    });

    return {values, colors};
  }

  function selectColor(value) {
    let colorFreqMap = new Map();
    let deviceMap = new Map();
    GRAPH_COLORS_POOL.forEach((color) => {
      colorFreqMap.set(color, 0);
    });

    devToGraph.forEach((devInfo) => {
      deviceMap.set(devInfo.value, devInfo.graphColor);
    });

    if(deviceMap.has(value)) {
      return deviceMap.get(value);
    }

    deviceMap.forEach((graphColor) => {
      colorFreqMap.set(graphColor, colorFreqMap.get(graphColor) + 1);
    });

    let colorToUse = GRAPH_COLORS_POOL[0];
    let minUsed = 10000;
    colorFreqMap.forEach((used, color) => {
      if(used < minUsed) {
        colorToUse = color;
        minUsed = used;
      }
    });    
    return colorToUse;
  }

  useEffect(() => {
    if(mustUpdate.current === 'no')
      return;

    if((selectedInst == null) && ((devToGraph.length > 0))) {
      mustUpdate.current = 'no';
      setDevToGraph([]);
      return;
    }
    if(devToGraph === null || devToGraph.length === 0) {
      mustUpdate.current = 'no';
      return;
    }
    devToGraph?.forEach((devId, idx) => {
      if((mustUpdate.current === 'all') || (devToGraph[idx].dataList == null)) {
        setCannotChangeParams(true);
        requestDeviceData(selectedInst, devId.value, devId.startDate + startSeconds, devId.startDate + startSeconds + timeInterval)
        .then((data) => {
          setDevToGraph((cur) => {
            cur[idx].dataList = data.dataList;
            cur[idx].dataPercentages = data.percentages;
            //return cloneDeep(cur);
            let newval = [];
            cur.forEach((element, idx) => {
              newval[idx] = element;
            });
            return newval;
          });
          setCannotChangeParams(false);
        })
        .catch((error) => {
          setCannotChangeParams(false);
        });
      }
    });

    mustUpdate.current = 'no';
  }, [devToGraph, timeInterval, requestDeviceData, selectedInst, startSeconds]);

  function getDevicesListOptions() {
    if(!(devicesState instanceof Map))
      return [];

    // const genLabel = (dev) => {
    //   //const nextColor = selectColor();
    //   const nextColor = '#666';
    //   return (
    //     <div key={`${dev.id}`} className="device-selection-element">
    //       <div 
    //         key={`img-${dev.id}`}
    //         // src={IMAGE_BASE_URL+dev.iconURL} 
    //         // alt={`${dev.name} (${dev.roomName})`} 
    //         // height="32px" 
    //         // width="32px"
    //         style={
    //           {
    //             mask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`, 
    //             WebkitMask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`, 
    //             backgroundColor: `${nextColor}`, 
    //             width: '32px', 
    //             height: '32px'
    //           }
    //         }
    //       /><span style={{color: `${nextColor}`}} key={`lab-${dev.id}`} className="device-selection-description">{dev.name} ({dev.roomName})</span>
    //     </div>
    //   );
    // }

    let options = [...devicesState.values()].map( (dev) => {
      // const nextColor = selectColor();
      const nextColor = '#000000';
      if(!dev.detailAvailable)
        return null;
      if((dev.valueType !== 'int') && (dev.valueType !== 'float'))
        return null;
      return {
        key: dev.id,
        value: dev.id, 
        label: genLabel(dev),
        name: dev.name,
        roomName: dev.roomName,
        dataType: dev.valueType,
        unit: dev.valueUnit,
        decimal: dev.decimal,
        dataList: null,
        dataPercentages: null,
        graphColor: nextColor,
        iconURL: dev.iconURL
      };
    });
    options = options.filter( el => el != null);
    return options;
  }

  function generateStartTimeSelectOptions(locale, intervalMinutes = 60) 
  {
    let options = [];
    for(let i = 0; i < 86400; i += intervalMinutes * 60) {
      options.push({value: i, label: timestampToLocalStringO(i, locale, {hour: "2-digit", minute: "2-digit", timeZone: 'UTC'})});
    }
    return options;
  }

  function addGraph(graphInfo) 
  {
    if(graphInfo.value == null) {
      confirmAlert({        
        title: `${t('alerts.setAllParameters')}`,
        buttons: [
          {
            label: `${t('OK')}`
          }
        ],
        overlayClassName: "overlay-confirm-alert-over-all"
      });
      return;
    }
    for(let i = 0; i < devToGraph.length; ++i) {
      if((devToGraph[i].startDate === graphInfo.startDate) && (devToGraph[i].value === graphInfo.value)) {
        confirmAlert({        
          title: `${t('alerts.cannotAddSameGraph')}`,
          buttons: [
            {
              label: `${t('OK')}`
            }
          ],
          overlayClassName: "overlay-confirm-alert-over-all"
        });
        return;
      }
    }

    graphInfo.graphColor = selectColor(graphInfo.value);
    devToGraph.push(graphInfo);
    setDevToGraph( [...devToGraph]);
    mustUpdate.current = 'all';
    closeAddGraphModal();
  }

  const genLabel = (dev) => {
    // const nextColor = selectColor();
//    const nextColor = "#666";
    return (
      <div key={`${dev.id}`} className="device-selection-element">
        <img 
          key={`img-${dev.id}`}
          alt={`${dev.name} (${dev.roomName})`} 
          height="32px" 
          src={IMAGE_BASE_URL+dev.iconURL} 
          width="32px"
          // style={
          //   {
          //   mask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`, 
          //   WebkitMask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`, 
          //   backgroundColor: `${nextColor}`, 
          //   width: '32px', 
          //   height: '32px'
          //   }
          // }
        // /><span style={{color: `${nextColor}`}} key={`lab-${dev.id}`} className="device-selection-description">{dev.name} ({dev.roomName})</span>
        /><span key={`lab-${dev.id}`} className="device-selection-description">{dev.name} ({dev.roomName})</span>        
      </div>
    );
  };

  function decodeGraphType(valueType, percentages) {
    switch (valueType) {
      case 'int':
        if(percentages != null)
          return 1;
        return 2;

      case 'float':
        return 2;

      case 'json':
        return 0;

      default:
        return 0;
    }
  }

  return (
    <>
      <HeaderSection 
        currRoute={currRoute} 
        mainContentRef={mainContentRef}
      />
      <section 
        className="main-content" 
        ref={mainContentRef}
      >
        {renderLoadingSpinner()}
        {(selectedInstallationData?.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) && (
          <StaticBanner 
            backgroundColor="#d9534f"
            color="white" 
            icon='exclamation-triangle' 
            message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(selectedInstallationData.lastStatusUpdate).toLocaleString(i18n.language)}`}
          />
        )}
        {(hasCapability(selectedInstallationData, 'alerts-show')) && (
          <Alerts 
            alerts={selectedInstallationData.alerts} 
            canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')} 
            installationStatus={selectedInstallationData.status}
          />
        )}
        <h1>
          {`${t('trendPage.title')}`}
          <HelpButton message={'help.trendPage'}/>
        </h1>
        <hr/>
        <Modal        
          closeOnOverlayClick={false}
          onClose={closeAddGraphModal}
          open={newDevice !== null}
          styles={{modal: {minWidth: 'min(80%, 1200px)'}}}
        >
          <div className="modal-dialog-container trend-page">
            <h3>
              {`${t('addGraphModal.modalTitle')}`}
              <hr/>
            </h3>
            <Select 
              className="modal-dialog-full-width"
              closeMenuOnSelect={true}
              filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                trim: true,
                matchFrom: 'any',
                stringify: option => `${option.data.name}`
              })}
              maxMenuHeight={150}
              name="devices"
              onChange={(dev) => {
                setNewDevice((cur) => {
                  // const nextColor = selectColor();
                  const nextColor = "#000000";
                  const startDate = cur.startDate ?? dateToTimestamp(new Date(new Date().setHours(0,0,0,0)))
                  return {...cur, 
                    value: dev.value, 
                    key: dev.value + '-' + startDate,
                    label: genLabel(dev),
                    name: dev.name,
                    roomName: dev.roomName,
                    dataType: dev.dataType,
                    unit: dev.unit,
                    decimal: dev.decimal,
                    dataList: null,
                    dataPercentages: null,
                    graphColor: nextColor,
                    iconURL: dev.iconURL,
                    startDate: startDate
                  }
                });
              }}
              options={getDevicesListOptions()}
              placeholder={t('trendPage.deviceSelectionPlaceholder')}
              value={newDevice?.value != null ? {value: newDevice.value, label: newDevice.label} : null}
            />
            <div className="modal-dialog-full-width" style={{paddingBottom: '4em'}}>
              <DatePicker
                dateFormat="P"
                locale={i18n.language}
                onChange={(date) => {
                  date.setHours(0);
                  date.setMinutes(0);
                  date.setSeconds(0);
                  date.setMilliseconds(0);
                  setNewDevice((cur) => {
                    return {...cur, 
                      key: cur.value + '-' + dateToTimestamp(date),
                      startDate: dateToTimestamp(date)
                    }
                  });                
                }}
                popperPlacement="right"
                selected={timestampToDate(newDevice?.startDate) ?? new Date(new Date().setHours(0,0,0,0))} 
                showDisabledMonthNavigation 
                showMonthDropdown
                // wrapperClassName="start-filter-date-time"
                // maxDate={Date()}
                showYearDropdown
                withPortal
              />
            </div>            
            <button className="cbutton modal-buttons cancel-button first-in-grid-row" onClick={() => { closeAddGraphModal() }} type="button">{`${t('CANCEL')}`}</button>
            <button 
              className="cbutton modal-buttons ok-button last-in-grid-row" 
              onClick={() => { 
                const nowSeconds = dateToTimestamp(new Date());
                if((newDevice.startDate + newDevice.startSeconds >= nowSeconds) || (newDevice.startDate + newDevice.startSeconds + newDevice.timeInterval >= nowSeconds)) {
                  confirmAlert({
                    title: `${t('Error')}`,
                    message: `${t('trendPage.timeIntervalIncludeFuture')}`,
                    buttons: [
                      {
                        label: `${t('OK')}`,
                        // onClick: () => {handleLogout();}
                      }
                    ],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "overlay-confirm-alert-over-all"
                  });
                } else {
                  addGraph(newDevice);
                }
              }} 
              type="button"
            >{`${t('SAVE')}`}</button>            
          </div>
        </Modal>
        <div className="interval-selection trend-page">
          <div className="interval-selection-start">
            <Select
              className="trend-interval-dropdown-select"
              classNamePrefix="select"
              isDisabled={cannotChangeParams}
              isSearchable={false}
              name="start-time-selector"
              onChange={(option) => {
                const value = option.value;
                const nowSeconds = dateToTimestamp(new Date());
                for(let dev of devToGraph) {                  
                  if((dev.startDate + value > nowSeconds) || (dev.startDate + value + timeInterval > nowSeconds)) {
                    confirmAlert({
                      title: `${t('Error')}`,
                      message: `${t('trendPage.timeIntervalIncludeFuture')}`,
                      buttons: [
                        {
                          label: `${t('OK')}`,
                          // onClick: () => {handleLogout();}
                        }
                      ],
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      overlayClassName: "overlay-confirm-alert-over-all"
                    });
                    return;
                  }
                }
                mustUpdate.current = 'all'; 
                setStartSeconds(value)
              }}
              options={generateStartTimeSelectOptions(i18n.language)}
              value={{value: startSeconds, label: `${timestampToLocalStringO(startSeconds, i18n.language, {hour: "2-digit", minute: "2-digit", timeZone: 'UTC'})}`}}
            />
          </div>
          <div className="interval-selection-start-icon">
            <FontAwesomeIcon icon={faStepBackward} />
          </div>
          <div className="add-graph-icon cbutton" onClick={() => setNewDevice({"startSeconds": startSeconds, "timeInterval": timeInterval})}>
            <FontAwesomeIcon icon={faPlusCircle}/>
            <span>{`${t('trendPage.addNewGraph')}`}</span>
          </div>
          <div className="interval-selection-end-icon">
            <FontAwesomeIcon icon={faStepForward} />
          </div>
          <div className="interval-selection-end">
            <Select
              className="trend-interval-dropdown-select"
              classNamePrefix="select"
              isDisabled={cannotChangeParams}
              isSearchable={false}
              name="time-span-selector"
              onChange={(option) => {
                const value = option.value;
                const nowSeconds = dateToTimestamp(new Date());
                for(let dev of devToGraph) {                  
                  if((dev.startDate + value > nowSeconds) || (dev.startDate + value + timeInterval > nowSeconds)) {
                    confirmAlert({
                      title: `${t('Error')}`,
                      message: `${t('trendPage.timeIntervalIncludeFuture')}`,
                      buttons: [
                        {
                          label: `${t('OK')}`,
                          // onClick: () => {handleLogout();}
                        }
                      ],
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      overlayClassName: "overlay-confirm-alert-over-all"
                    });
                    return;
                  }
                }
                mustUpdate.current = 'all'; 
                setTimeInterval(value)}
              }
              options={[
                { value: 3600 * 1, label: `+1 ${t('hourAbbrev')}`}, 
                { value: 3600 * 2, label: `+2 ${t('hourAbbrev')}`}, 
                { value: 3600 * 3, label: `+3 ${t('hourAbbrev')}`}, 
                { value: 3600 * 4, label: `+4 ${t('hourAbbrev')}`}, 
                { value: 3600 * 5, label: `+5 ${t('hourAbbrev')}`}, 
                { value: 3600 * 6, label: `+6 ${t('hourAbbrev')}`}, 
                { value: 3600 * 7, label: `+7 ${t('hourAbbrev')}`}, 
                { value: 3600 * 8, label: `+8 ${t('hourAbbrev')}`}, 
                { value: 3600 * 9, label: `+9 ${t('hourAbbrev')}`}, 
                { value: 3600 * 10, label: `+10 ${t('hourAbbrev')}`}, 
                { value: 3600 * 11, label: `+11 ${t('hourAbbrev')}`}, 
                { value: 3600 * 12, label: `+12 ${t('hourAbbrev')}`}, 
                { value: 3600 * 18, label: `+18 ${t('hourAbbrev')}`}, 
                { value: 3600 * 24, label: `+24 ${t('hourAbbrev')}`}, 
                { value: 3600 * 36, label: `+36 ${t('hourAbbrev')}`}, 
                { value: 3600 * 48, label: `+48 ${t('hourAbbrev')}`}
              ]}
              value={{value: timeInterval, label: `+${timeInterval / 3600} ${t('hourAbbrev')}`}}
            />
          </div>
        </div>
        <div className="graphs-container-div">
          {devToGraph?.map((devId, idx, all) => {
            if(devId.dataList == null)
              return null;
            let graph = null;
            const graphType = decodeGraphType(devId.dataType, devId.dataPercentages);
            switch(graphType) {
              case 1:
                graph = (
                  <>
                    <BarGraph 
                      dataToRender={devId.dataList}
                      displayXScale={true}
                      end={devId.startDate + startSeconds + timeInterval}                     
                      graphClassName="graph-page bar-chart-full-width" 
                      hideMinutes={false} 
                      locale={i18n.language}
                      numberOfBars={NUMBER_OF_BARS}
                      start={devId.startDate + startSeconds}
                      valueMaps={extractLabelMaps(devId.dataPercentages, '#ffffff', devId.graphColor, null, null)}
                    />
                    <div className="trend-page graph-legend">                      
                      <p className="trend-page graph-date-start">
                        <span className="trend-page graph-erase">
                          <FontAwesomeIcon icon={faTrashAlt} onClick={() => {
                            setDevToGraph((cur) => {
                              return cur.filter(dev => dev.key !== devId.key);
                            });                        
                          }}/>
                        </span>
                        {`${timestampToLocalStringO(devId.startDate, i18n.language, {day: '2-digit', month: '2-digit', year: 'numeric', weekday: "short"})}`}
                      </p>
                      <p>{`${devId.name} (${devId.roomName}) [${t('timeResolution')} ${secondsToElapsedTimeStr(timeInterval / NUMBER_OF_BARS, t)}]`}</p>
                      <p className="trend-page graph-move">
                        {(idx > 0) && (
                          <span className="trend-page graph-up">
                            <FontAwesomeIcon icon={faArrowCircleUp} onClick={() => {
                              setDevToGraph((cur) => {
                                return arrayMove(cur, idx, idx - 1);
                              });                        
                            }}/>
                          </span>
                        )}
                        {(idx < (all.length - 1)) && (
                          <span className="trend-page graph-down">
                            <FontAwesomeIcon icon={faArrowCircleDown} onClick={() => {
                              setDevToGraph((cur) => {
                                return arrayMove(cur, idx, idx + 1);
                              });                        
                            }}/>
                          </span>
                        )}
                      </p>
                    </div>
                  </>
                );              
                break;
      
              case 2:
                graph = (
                  <>
                    <ScatterGraph 
                      dataToRender={devId.dataList}
                      decimal={devId.decimal}
                      graphClassName="graph-page line-chart-full-width" 
                      lineColor={devId.graphColor} 
                      locale={i18n.language}
                      unit={devId.unit}
                    />
                    <p className="trend-page graph-legend">{`${devId.name} (${devId.roomName})`}</p>
                  </>
                );
                break;
      
              default:
                ;
            }
            return (
              <div key={devId.key}>
                {graph}
              </div>
            );
          })}
        </div>
        <FooterSection/>
      </section>
    </>
  )
}

