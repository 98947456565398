import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { InstallationsContext } from './App';
import { extractFileName, not_null_or_undefined, null_or_undefined } from '../utilities';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';

const emptyModal = {info: null, options: null, loading: false};

export default function UserInformationModal({open, setOpen}) {
  const {t} = useTranslation(); 
  const { 
    loadUserInfo,
    updateUserInfo,
    loadUserOptions,
    updateUserOptions,
    updateUserPassword,
    requestUserSounds,
    requestCountries,
    requestTimezones,
    requestLocales
  } = React.useContext(InstallationsContext);

  const SOUNDS_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

  const wizardSoundCustomSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: '100%',
    })
  };

  const [modalInfo, setModalInfo] = useState(emptyModal);
  const [passwordChange, setPasswordChange] = useState({oldPassword: "", newPassword: "", newPassword2: ""});
  const [countriesOptions, setCountriesOptions] = useState(null);
  const [timezonesOptions, setTimezonesOptions] = useState(null);
  const [localesOptions, setLocalesOptions] = useState(null);
  const [mustUpdateInfo, setMustUpdateInfo] = useState(false);
  const [mustUpdateOptions, setMustUpdateOptions] = useState(false);

  const closeModal = useCallback( () => {
    setOpen(false);
  }, [setOpen]); 

  useEffect(() => {
    if(!open) {
      setMustUpdateInfo(false);  
      setMustUpdateOptions(false);
      setModalInfo(emptyModal);
    }
  }, [open]);

  useEffect(() => {
    async function loadInfo() {
      const info = await loadUserInfo();
      const options = await loadUserOptions();
      if(options.alertSoundOnAlarm == null)
        options.alertSoundOnAlarm = 1
      if(options.alertSoundOnWarning == null)
        options.alertSoundOnWarning = 0
      if(options.alertSoundOnDisconnection == null)
        options.alertSoundOnDisconnection = 0
      if(not_null_or_undefined(info) && not_null_or_undefined(options)) {
        setModalInfo((cur) => {
          return {info: info, options: options, loading: false};
        });
      } else {
        alert(`${t("userInformationModal.errorReadingData")}`);
        closeModal();
      }
    }
  
    if(open === false) {
      return;
    }

    if(modalInfo.info === null && modalInfo.options === null && modalInfo.loading === false) {
      setModalInfo({info: null, options: null, loading: true});
      loadInfo();
    }
  }, [modalInfo, open, loadUserInfo, loadUserOptions, t, closeModal]);

  useEffect(() => {
    async function loadCountries() {
      const countries = await requestCountries();
      if(null_or_undefined(countries)) {
        return [];
      }
      let options = [];
      countries.forEach( (name, code) => {
        options.push({value: code, label: name});
      });
      return setCountriesOptions(options);
    }

    if(countriesOptions === null) {
      loadCountries();
    }

  }, [countriesOptions, requestCountries]);

  useEffect(() => {
    async function loadTimezones() {
      const timezones = await requestTimezones();
      if(null_or_undefined(timezones)) {
        return [];
      }
      let options = [];
      timezones.forEach( (name, code) => {
        options.push({value: code, label: name});
      });
      return setTimezonesOptions(options);
    }

    if(timezonesOptions === null) {
      loadTimezones();
    }

  }, [requestTimezones, timezonesOptions]);

  useEffect(() => {
    async function loadLocales() {
      const locales = await requestLocales(true);
      if(null_or_undefined(locales)) {
        return [];
      }
      let options = [];
      locales.forEach( (localeInfo, code) => {
        options.push({value: code, label: localeInfo.name});
      });
      return setLocalesOptions(options);
    }

    if(localesOptions === null) {
      loadLocales();
    }
  }, [localesOptions, requestLocales]);

  async function saveOptions() {
    if(mustUpdateOptions) {
      const res = await updateUserOptions(modalInfo.options);
      if(not_null_or_undefined(res.userMessage)) {
        alert(res);
        return;
      }
      setModalInfo((cur) => {
        cur.options = res;
        return cloneDeep(cur);
      });
    }

    if(mustUpdateInfo) {
      const res = await updateUserInfo(modalInfo.info);
      if(not_null_or_undefined(res.userMessage)) {
        alert(res);
        return;
      }
      setModalInfo((cur) => {
        cur.info = res;
        return cloneDeep(cur);
      });
    }

    if(passwordChange.oldPassword !== "") {
      if(passwordChange.oldPassword.length < 8) {
        alert(`${t("userInformationModal.errorPasswordTooShort")}`);
        return;
      }

      if(passwordChange.newPassword.length < 12) {
        alert(`${t("userInformationModal.errorNewPasswordTooShort")}`);
        return;
      }

      const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#_\\+/-])[A-Za-z\\d@$!%*?&#_\\+/-]{12,}$', 'g');
      if(!regex.test(passwordChange.newPassword)) {
        alert(`${t("userInformationModal.errorNewPasswordTooSimple")}`);
        return;
      }
      if(passwordChange.newPassword !== passwordChange.newPassword2) {
        alert(`${t("userInformationModal.errorNewPasswordMismatch")}`);
        return;
      }
      const res = await updateUserPassword(passwordChange.oldPassword, passwordChange.newPassword);
      if(res !== true) {
        alert(res);
        return;
      }
    }
    closeModal();
  }

  async function soundsOptions() {
    const sounds = await requestUserSounds();
    if(null_or_undefined(sounds)) {
      return [];
    }
    const options = sounds.map( (sound) => {
      return ({value: sound.sound, label: <div>{extractFileName(sound.sound)}</div>});
    });
    return options;
  }  

  return (
    <Modal
      onClose={closeModal}
      open={open}
      styles={{modal: {minWidth: 'min(80%, 1200px)'}}}
    >
      {(modalInfo.loading === true || modalInfo.info === null || modalInfo.options === null) && (
        <div className="modal-dialog-container">
          <h3>
            {`${t('userInformationModal.modalTitle')}`}
            <hr/>
          </h3>
          <p>{t('userInformationModal.loading')}</p>
        </div>
      )}
      {(modalInfo.loading === false && modalInfo.info !== null && modalInfo.options !== null) && (
          <div className="modal-dialog-container col3">
            <h3>
              {`${t('userInformationModal.modalTitle')}`}
              <hr/>
            </h3>

            <div>
              <label className="input-label" htmlFor="firstname">
                {`${t('userInformationModal.firstname')}`}
              </label>
              <input
                  className="modal-dialog-input readonly"
                  id="firstname"
                  name="firstname"
                  readOnly={true}
                  type="text"
                  value={modalInfo.info.firstName !== null ? modalInfo.info.firstName : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="lastname">
                {`${t('userInformationModal.lastname')}`}
              </label>
              <input
                  className="modal-dialog-input readonly"
                  id="lastname"
                  name="lastname"
                  readOnly={true}
                  type="text"
                  value={modalInfo.info.lastName !== null ? modalInfo.info.lastName : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="organization">
                {`${t('userInformationModal.organization')}`}
              </label>
              <input
                  className="modal-dialog-input readonly"
                  id="organization"
                  name="organization"
                  readOnly={true}
                  type="text"
                  value={modalInfo.info.organization !== null ? modalInfo.info.organization : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="address-line1">
                {`${t('userInformationModal.addressLine1')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="address-line1"
                  name="address-line1"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.addressLine1 = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    });
                    setMustUpdateInfo(true);
                  }}
                  type="text"
                  value={modalInfo.info.addressLine1 !== null ? modalInfo.info.addressLine1 : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="address-line2">
                {`${t('userInformationModal.addressLine2')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="address-line2"
                  name="address-line2"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.addressLine2 = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  type="text"
                  value={modalInfo.info.addressLine2 !== null ? modalInfo.info.addressLine2 : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="city">
                {`${t('userInformationModal.city')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="city"
                  name="city"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.city = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  type="text"
                  value={modalInfo.info.city !== null ? modalInfo.info.city : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="zip-code">
                {`${t('userInformationModal.zipCode')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="zip-code"
                  name="zip-code"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.zipCode = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  type="text"
                  value={modalInfo.info.zipCode !== null ? modalInfo.info.zipCode : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="province">
                {`${t('userInformationModal.province')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="province"
                  name="province"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.province = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  type="text"
                  value={modalInfo.info.province !== null ? modalInfo.info.province : ''}
              />
            </div>

            <div className="reminder-info">
              <label className="reminder-info-field" htmlFor="country"
                     style={{textAlign: "center"}}>{`${t('userInformationModal.country')}`}</label>
              <br/>
              <Select
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultValue={
                    {
                      value: modalInfo.info.country.code,
                      label: modalInfo.info.country.name
                    }
                  }
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={150}
                  menuPlacement="top"
                  name="country"
                  onChange={(option) => {
                    setModalInfo((cur) => {
                      cur.info.country.code = option.value;
                      cur.info.country.name = option.label;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  options={countriesOptions}
                  styles={wizardSoundCustomSelectStyles}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="phone">
                {`${t('userInformationModal.phone')}`}
              </label>
              <input
                  className="modal-dialog-input readonly"
                  id="phone"
                  name="phone"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.phone = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  readOnly={true}
                  type="text"
                  value={modalInfo.info.phone !== null ? modalInfo.info.phone : ''}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="email">
                {`${t('userInformationModal.email')}`}
              </label>
              <input
                  className="modal-dialog-input readonly"
                  id="email"
                  name="email"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModalInfo((cur) => {
                      cur.info.email = val.trim().length > 0 ? val : null;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  readOnly={true}
                  type="text"
                  value={modalInfo.info.email !== null ? modalInfo.info.email : ''}
              />
            </div>

            <div className="reminder-info">
              <label className="reminder-info-field" htmlFor="locale-select"
                     style={{textAlign: "center"}}>{`${t('userInformationModal.locale')}`}</label>
              <br/>
              <Select
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultValue={
                    {
                      value: modalInfo.info.locale.code,
                      label: modalInfo.info.locale.name
                    }
                  }
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={150}
                  menuPlacement="top"
                  name="locale-select"
                  onChange={(option) => {
                    setModalInfo((cur) => {
                      cur.info.locale.code = option.value;
                      cur.info.locale.name = option.label;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  options={localesOptions}
                  styles={wizardSoundCustomSelectStyles}
              />
            </div>

            <div className="reminder-info">
              <label className="reminder-info-field" htmlFor="timezone"
                     style={{textAlign: "center"}}>{`${t('userInformationModal.timezone')}`}</label>
              <br/>
              <Select
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultValue={
                    {
                      value: modalInfo.info.timezone.code,
                      label: modalInfo.info.timezone.name
                    }
                  }
                  isClearable={false}
                  isSearchable={true}
                  maxMenuHeight={150}
                  menuPlacement="top"
                  name="timezone"
                  onChange={(option) => {
                    setModalInfo((cur) => {
                      cur.info.timezone.code = option.value;
                      cur.info.timezone.name = option.label;
                      return cloneDeep(cur);
                    })
                    setMustUpdateInfo(true);
                  }}
                  options={timezonesOptions}
                  styles={wizardSoundCustomSelectStyles}
              />
            </div>

            <h3>
              {`${t('userInformationModal.changePasswordTitle')}`}
              <hr/>
            </h3>

            <div>
              <label className="input-label" htmlFor="old-password">
                {`${t('userInformationModal.oldPassword')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="old-password"
                  name="old-password"
                  onChange={(e) => {
                    const val = e.target.value;
                    setPasswordChange((cur) => {
                      cur.oldPassword = val.trim().length > 0 ? val : "";
                      return {...cur};
                    })
                  }}
                  type="password"
                  value={passwordChange.oldPassword}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="new-password">
                {`${t('userInformationModal.newPassword')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="new-password"
                  name="new-password"
                  onChange={(e) => {
                    const val = e.target.value;
                    setPasswordChange((cur) => {
                      cur.newPassword = val.trim().length > 0 ? val : "";
                      return {...cur};
                    })
                  }}
                  type="password"
                  value={passwordChange.newPassword}
              />
            </div>

            <div>
              <label className="input-label" htmlFor="new-password">
                {`${t('userInformationModal.newPassword2')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="new-password"
                  name="new-password"
                  onChange={(e) => {
                    const val = e.target.value;
                    setPasswordChange((cur) => {
                      cur.newPassword2 = val.trim().length > 0 ? val : "";
                      return {...cur};
                    })
                  }}
                  type="password"
                  value={passwordChange.newPassword2}
              />
            </div>

            <h3>
              {`${t('userInformationModal.optionsTitle')}`}
              <hr/>
            </h3>

            <div className="reminder-info">
              <label className="reminder-info-field" htmlFor="alert-sound"
                     style={{textAlign: "center"}}>{`${t('userInformationModal.alertSoundUrl')}`}</label>
              <br/>
              <AsyncSelect
                  cacheOptions={false}
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultOptions
                  defaultValue={
                    {
                      value: modalInfo.options.alertSoundUrl,
                      label: <div>{extractFileName(modalInfo.options.alertSoundUrl)}</div>
                    }
                  }
                  isClearable={false}
                  isSearchable={false}
                  loadOptions={soundsOptions}
                  menuPlacement="top"
                  name="alert-sound"
                  onChange={(option) => {
                    setModalInfo((cur) => {
                      cur.options.alertSoundUrl = option.value;
                      return {...cur};
                    })
                    setMustUpdateOptions(true);
                  }}
                  placeholder={t('remindersAdminPage.filterSelectIcon')}
                  styles={wizardSoundCustomSelectStyles}
              />
              <FontAwesomeIcon
                  className="valign2 clickable"
                  icon={faPlay}
                  onClick={() => {
                    const x = document.getElementById("reminder-audio");
                    try {
                      x.load();
                      x.play();
                    } catch (ex) {
                      //ignore exceptions...
                    }
                  }}
                  title={`${t('remindersAdminPage.play')}`}/>
              <FontAwesomeIcon
                  className="valign2 clickable"
                  icon={faPause}
                  onClick={() => {
                    const x = document.getElementById("reminder-audio");
                    try {
                      x.pause();
                    } catch (ex) {
                      //ignore exceptions...
                    }
                  }}
                  title={`${t('remindersAdminPage.pause')}`}/>
              <audio id="reminder-audio">
                <source src={SOUNDS_BASE_URL + modalInfo.options.alertSoundUrl} type="audio/wav"/>
                Your browser does not support the audio element.
              </audio>
            </div>

            <div>
              <label className="input-label" htmlFor="alert-sound-interval">
                {`${t('userInformationModal.alertSoundInterval')}`}
              </label>
              <input
                  className="modal-dialog-input"
                  id="alert-sound-interval"
                  name="alert-sound-interval"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val.trim().length > 0) {
                      setModalInfo((cur) => {
                        cur.options.alertSoundInterval = parseInt(val.trim());
                        return {...cur};
                      })
                    }
                    setMustUpdateOptions(true);
                  }}
                  type="number"
                  value={modalInfo.options.alertSoundInterval}
              />
            </div>

            <div className="modal-user-options-checkboxes">
              <label
                  className="checkbox-label"
                  htmlFor="modal-enable-web-push-checkbox"
              >
                <input
                    defaultChecked={modalInfo.options.enableWebPush > 0}
                    id="modal-enable-web-push-checkbox"
                    name="modal-enable-web-push-checkbox"
                    onChange={() => {
                      if (modalInfo.options.enableWebPush > 0)
                        modalInfo.options.enableWebPush = 0;
                      else
                        modalInfo.options.enableWebPush = 1;
                      setMustUpdateOptions(true);
                    }}
                    type="checkbox"
                />
                {t('userInformationModal.enableWebPush')}
                <span className="checkmark"/>
              </label>
              <label
                  className="checkbox-label"
                  htmlFor="modal-play-alarm-checkbox"
              >
                <input
                    defaultChecked={modalInfo.options.alertSoundOnAlarm > 0}
                    id="modal-play-alarm-checkbox"
                    name="modal-play-alarm-checkbox"
                    onChange={() => {
                      if (modalInfo.options.alertSoundOnAlarm > 0)
                        modalInfo.options.alertSoundOnAlarm = 0;
                      else
                        modalInfo.options.alertSoundOnAlarm = 1;
                      setMustUpdateOptions(true);
                    }}
                    type="checkbox"
                />
                {t('userInformationModal.alertSoundOnAlarm')}
                <span className="checkmark"/>
              </label>
              <label
                  className="checkbox-label"
                  htmlFor="modal-paly-warning-checkbox"
              >
                <input
                    defaultChecked={modalInfo.options.alertSoundOnWarning > 0}
                    id="modal-paly-warning-checkbox"
                    name="modal-paly-warning-checkbox"
                    onChange={() => {
                      if (modalInfo.options.alertSoundOnWarning > 0)
                        modalInfo.options.alertSoundOnWarning = 0;
                      else
                        modalInfo.options.alertSoundOnWarning = 1;
                      setMustUpdateOptions(true);
                    }}
                    type="checkbox"
                />
                {t('userInformationModal.alertSoundOnWarning')}
                <span className="checkmark"/>
              </label>
              <label
                  className="checkbox-label"
                  htmlFor="modal-play-disconnection-checkbox"
              >
                <input
                    defaultChecked={modalInfo.options.alertSoundOnDisconnection > 0}
                    id="modal-play-disconnection-checkbox"
                    name="modal-play-disconnection-checkbox"
                    onChange={() => {
                      if (modalInfo.options.alertSoundOnDisconnection > 0)
                        modalInfo.options.alertSoundOnDisconnection = 0;
                      else
                        modalInfo.options.alertSoundOnDisconnection = 1;
                      setMustUpdateOptions(true);
                    }}
                    type="checkbox"
                />
                {t('userInformationModal.alertSoundOnDisconnection')}
                <span className="checkmark"/>
              </label>
            </div>

            <button className="cbutton modal-buttons cancel-button first-in-grid-row" onClick={() => {
              closeModal()
            }} type="button">{`${t('CANCEL')}`}</button>
            <button className="cbutton modal-buttons ok-button last-in-grid-row" onClick={() => {
              saveOptions()
            }} type="button">{`${t('SAVE')}`}</button>

          </div>
      )}
    </Modal>
  )
}
