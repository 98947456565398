import React from 'react';

export default function EmailContact({email, prefix}) {
  return (
    <>
      {prefix}
      <a className="email-link" href={`mailto:${email}`}>
        {`${email}`}
      </a>
    </>
  )
}
