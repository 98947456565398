import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCity, faClipboardList, faWrench, faSignOutAlt, faCalendarAlt, faPoll, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { SITE_LINKS } from '../Contants';
import { GenericContext, InstallationsContext, hasCapability } from './App';

export default function NavMenu({ sideMenuRef, currRoute, selectedInst, handleSelectInstallation }) {
  const {t/*, i18n*/} = useTranslation();

  const { 
    handleSignOut,
  } = React.useContext(GenericContext);

  const { 
    installations,
  } = React.useContext(InstallationsContext);

  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  function handleInstallationsClick() {
    handleSelectInstallation(null);
  }

  return (
    <nav className="side-menu" ref={ sideMenuRef }>
      <ul>
        <li 
          className={ "menu-element" + (currRoute === SITE_LINKS.INSTALLATIONS ? " active" : "") } 
          id="menu-installations"
        >
          <Link onClick={() => handleInstallationsClick()} to={SITE_LINKS.INSTALLATIONS}>
            <FontAwesomeIcon icon={faCity} />{t('menu.installations')}
          </Link>
        </li>
        {selectedInst !== null && (<>
          <li 
          className={ "menu-element" + (currRoute === SITE_LINKS.STATUS ? " active" : "") } 
          id="menu-status"
          >
            <Link to={SITE_LINKS.STATUS}>
              <FontAwesomeIcon icon={faHome} />{t('menu.state')}
            </Link>
          </li>
          {(hasCapability(selectedInstallationData, 'logs')) && (
            <>
              <li 
                className={ "menu-element" + (currRoute === SITE_LINKS.LOG ? " active" : "") } 
                id="menu-log"
              >
                <Link to={SITE_LINKS.LOG}>
                  <FontAwesomeIcon icon={faClipboardList} />{t('menu.log')}
                </Link>
              </li>
              <li 
                className={ "menu-element" + (currRoute === SITE_LINKS.GRAPHS ? " active" : "") } 
                id="menu-graphs"
              >
                <Link to={SITE_LINKS.GRAPHS}>
                  <FontAwesomeIcon icon={faPoll} />{t('menu.graphs')}
                </Link>
              </li>
              <li 
                className={ "menu-element" + (currRoute === SITE_LINKS.TRENDS ? " active" : "") } 
                id="menu-trend"
              >
                <Link to={SITE_LINKS.TRENDS}>
                  <FontAwesomeIcon icon={faChartLine} />{t('menu.trends')}
                </Link>
              </li>
            </>
          )}
          <li 
            className={ "menu-element" + (currRoute === SITE_LINKS.TECH ? " active" : "") } 
            id="menu-tech"
          >
            <Link to={SITE_LINKS.TECH}>
              <FontAwesomeIcon icon={faWrench} />{t('menu.tech')}
            </Link>
          </li>
          {(hasCapability(selectedInstallationData, 'reminders')) && (
            <li 
              className={ "menu-element" + (currRoute === SITE_LINKS.REMINDERS_ADMIN ? " active" : "") } 
              id="menu-reminders-admin"
            >
              <Link to={SITE_LINKS.REMINDERS_ADMIN}>
                <FontAwesomeIcon icon={faCalendarAlt} />{t('menu.reminders_admin')}
              </Link>
            </li>
          )}
          </>
        )}
        <li 
          className="menu-element" 
          id="menu-logout"
        >
          <Link 
            onClick={() => {
              handleSignOut(true)
            }} 
            to={SITE_LINKS.LOGIN}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />{t('menu.logout')}
          </Link>
        </li>
      </ul>
    </nav>    
  )
}
