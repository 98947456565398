import React, { useState, useRef, useEffect } from 'react';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import FooterSection from './FooterSection';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import { hasCapability, InstallationsContext } from './App';
import { useTranslation } from 'react-i18next';
import {
  timestampToDate,
  dateToTimestamp,
  timestampToLocalDateString,
  timestampToLocalTimeString,
  extractFileName,
  convertToDMS
} from '../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCaretLeft,
  faCaretRight,
  faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons';
import Select, { createFilter } from 'react-select';
import dateSub from 'date-fns/sub';
import cloneDeep from 'lodash/cloneDeep';
import { Puff } from "react-loader-spinner";

import '../css/log.css';
import StaticBanner from './StaticBanner';
import { useCallback } from 'react';
import HelpButton from "./HelpButton";
import ResourceLink from "./ResourceLink";

const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

//Used by react-datepicker 
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

export default function LogPage({ currRoute, errors, mqttStatus }) {
  let mainContentRef = React.createRef();

  const {t, i18n} = useTranslation();
  const { 
    installations,
    selectedInst,
    // devicesState,
    requestDeviceData,
    loadAllInstallationData,
    requestInstallationData,
    requestInstallationDataNumber,
    requestInstallationEvents,
    requestInstallationEventsNumber
  } = React.useContext(InstallationsContext);

  const LOG_FILTER_EVENTS = 0;
  const LOG_FILTER_DATA = 1;
  const LOG_FILTER_EVENTS_AND_DATA = 2;
  const LOG_TYPES_OPTIONS = [
    {value: LOG_FILTER_EVENTS, label: `${t('logPage.eventsOpt')}`},
    {value: LOG_FILTER_DATA, label: `${t('logPage.dataOpt')}`},
    {value: LOG_FILTER_EVENTS_AND_DATA, label: `${t('logPage.eventsAndDataOpt')}`}
  ];

  const [logFilterType, setLogFilterType] = useState(LOG_FILTER_EVENTS);
  const [devToList, setDevToList] = useState([]);
  const [startDateTime, setStartDateTime] = useState(dateToTimestamp(dateSub(new Date(), { days: 1 })));
  const [endDateTime, setEndDateTime] = useState(dateToTimestamp(new Date()));
  const [latestDataFirst, setLatestDataFirst] = useState(true);
  const [showTechData, setShowTechData] = useState(false);
  const [cannotChangeParams, setCannotChangeParams] = useState(true)
  const [dataNumber, setDataNumber] = useState(0);
  const [limitData, setLimitData] = useState(50); 
  const [offsetData, setOffsetData] = useState(0);
  const [data, setData] = useState([]);
  const [virtDevList, setVirtDevList] = useState(null);

  const mustUpdate = useRef('no');
  const dataLoaded = useRef(false);

  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  const genListOfPages = () => {
    let pages = [];
    const maxPages = Math.floor(dataNumber / limitData);
    for(let a = 0; a <= maxPages; ++a)
      pages.push({value: a, label: `${a + 1}`});
    return pages;
  };

  function decodeValueCase(valueCase) {
    switch(valueCase) {
      case 0:
        return "normal-state";

      case 1: 
        return "active-state";

      case 2: 
        return "warning-state";

      case 3: 
        return "error-state";

      default:
        return ""; 
    }
  }

  function renderLoadingSpinner() {
    return (
      <div 
        className="loading-overlay" 
        style={cannotChangeParams ? null : {display: 'none'}}
      >
        <Puff
          color="#00BFFF"
          height={100}
          visible={cannotChangeParams === true}
          width={100}
          wrapperClass="loading-spinner"
          // timeout={3000} //3 secs
        />
      </div>
    );
  }

  function handleTypeChange(selOption) {
    if(logFilterType === selOption.value)
      return;
    dataLoaded.current = false;
    setCannotChangeParams(true);
    setLogFilterType(selOption.value);
    setOffsetData(0);
  }

  function handleToggleLatestFirst() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setLatestDataFirst(cur => !cur);
    setOffsetData(0);
  }

  function handleToggleShowTechData() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setShowTechData(cur => !cur);
    setDevToList([]);
    setVirtDevList(null);
    setOffsetData(0);
  }

  function handleLimitChange(option) {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setLimitData(option.value); 
    setOffsetData(0);
  }

  function handlePageChange(option) {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setOffsetData(option.value * limitData); 
  }

  function handleFirstPage() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setOffsetData(0);
  }

  function handlePrevPage() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setOffsetData(cur => (cur - limitData));
  }

  function handleNextPage() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setOffsetData(cur => (cur + limitData));
  }

  function handleLastPage() {
    dataLoaded.current = false; 
    setCannotChangeParams(true);
    setOffsetData(Math.floor(dataNumber / limitData) * limitData);
  }

  function onStartDateTimeChange(date) {
    dataLoaded.current = false;
    setCannotChangeParams(true);
    setStartDateTime((cur) => {
      mustUpdate.current = 'all';
      cur = dateToTimestamp(date);
      if(cur >= endDateTime) {
        cur = endDateTime - 3600;
      }                            
      return cur;
    })
    setOffsetData(0);
  }

  function onEndDateTimeChange(date) {
    dataLoaded.current = false;
    setCannotChangeParams(true);
    setEndDateTime((cur) => {
      mustUpdate.current = 'all';
      cur = dateToTimestamp(date);
      if(cur <= startDateTime) {
        cur = startDateTime + 3600;
      }
      return cur;
    })
    setOffsetData(0);
  }

  function extractDeviceList(devToList) {
    return devToList.map(option => option.value);
  }
  
  const onChangeDeviceSelection = (selectedOptions) => {
    mustUpdate.current = 'new';
    dataLoaded.current = false;
    setCannotChangeParams(true);
    setOffsetData(0);
    setDevToList(selectedOptions ?? [])
  };

  const onSortDeviceListEnd = ({ oldIndex, newIndex }) => {
    mustUpdate.current = 'new';
    dataLoaded.current = false;
    const newValue = arrayMove(devToList, oldIndex, newIndex) ?? [];
    setDevToList(newValue);
  };

  const loadData = useCallback(() => 
  {
    return ((logFilterType === LOG_FILTER_EVENTS) ? requestInstallationEvents(
      selectedInst, 
      extractDeviceList(devToList), 
      latestDataFirst, 
      startDateTime, 
      endDateTime, 
      offsetData, 
      limitData
    ) : requestInstallationData(
      selectedInst, 
      extractDeviceList(devToList), 
      (logFilterType === LOG_FILTER_EVENTS_AND_DATA), 
      showTechData, 
      latestDataFirst, 
      startDateTime, 
      endDateTime, 
      offsetData, 
      limitData, 
      true
    ))
    .then((loadedData) => {
      setData(loadedData);
      dataLoaded.current = true;
      setCannotChangeParams(false);
      if(offsetData === 0) {
        if(loadedData?.length === limitData) {
          if(logFilterType === LOG_FILTER_EVENTS) {
            return requestInstallationEventsNumber(
              selectedInst, 
              extractDeviceList(devToList), 
              startDateTime, 
              endDateTime
            );
          } else {
            return requestInstallationDataNumber(
              selectedInst, 
              extractDeviceList(devToList), 
              (logFilterType === LOG_FILTER_EVENTS_AND_DATA), 
              showTechData, 
              startDateTime, 
              endDateTime
            );
          }
        } else {
          return loadedData?.length;
        }
      } else {
        return null;
      }
    })
    .then((number) => {
      if(number !== null) {
        setDataNumber(number);
      }
    });    
  }, [devToList, endDateTime, latestDataFirst, limitData, logFilterType, offsetData, requestInstallationData, 
    requestInstallationDataNumber, requestInstallationEvents, requestInstallationEventsNumber, selectedInst, 
    showTechData, startDateTime]);

  useEffect(() => {
    if(selectedInst !== null) {
      loadData();
    }
  }, [selectedInst, loadData])

  useEffect(() => {
    if(mustUpdate.current === 'no')
      return;

    if((selectedInst == null) && ((devToList.length > 0))) {
      setDevToList([]);
      return;
    }

    devToList?.forEach((devId, idx) => {
      if((mustUpdate.current === 'all') || (devToList[idx].dataList == null)) {
        requestDeviceData(selectedInst, devId.value, startDateTime, endDateTime)
        .then((data) => {
          if(data) {
            setDevToList((cur) => {
              cur[idx].dataList = data.dataList;
              return cloneDeep(cur);
            });
          }
        })
      }      
    });
    
    mustUpdate.current = 'no';
  }, [devToList, endDateTime, requestDeviceData, selectedInst, startDateTime]);

  useEffect(() => {
    if(selectedInst == null) {
      return;
    }

    loadAllInstallationData(selectedInst, false, false, showTechData)
    .then((devs) => {
      setVirtDevList(devs);
    })
  }, [loadAllInstallationData, selectedInst, showTechData])

  function intEventDescription(log) {
    let message = log.eventDesc;
    const regexTime = new RegExp('\\s*%time%\\s*', 'g');
    const matchesTime = log.eventDesc.matchAll(regexTime);
    for(const match of matchesTime) {
      message = message.replace(match[0], '')
    }
    return message;
  }

  function jsonEventDescription(log) {
    const regexPosition = new RegExp('%position:([+-]*\\d+\\.\\d+),([+-]*\\d+\\.\\d+);([A-Za-zÀ-ÖØ-öø-ÿ\\d \'-]+|\\?)%', 'g');
    const matchesPosition = log.eventDesc.matchAll(regexPosition);
    let message = log.eventDesc;
    let positions = [];
    for(const match of matchesPosition) {
      //message = message.replace(match[0], `${t('latitude')}: ${match[1]}, ${t('longitude')}: ${match[2]}`)
      const coords = convertToDMS(parseFloat(match[1]), parseFloat(match[2]));
      if(match[3] !== '?')
        message = message.replace(match[0], `${match[3]} (${coords.fullString})`)
      else
        message = message.replace(match[0], coords.fullString)
      positions.push({"latitude": match[1], "longitude": match[2], "beaconLabel": match[3]})
//      return <a href={`https://www.google.com/maps/search/?api=1&query=${match[1]}%2C${match[2]}`} rel="noopener" target="_blank">{t('position')}</a>;
    }
    const regexTime = new RegExp('\\s*%time%\\s*,?', 'g');
    const matchesTime = log.eventDesc.matchAll(regexTime);
    for(const match of matchesTime) {
      message = message.replace(match[0], '')
    }
    return (
        <>
          <span>{log.name + ": " + message}</span> {positions.map((position) => {
            // return (<a href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`} rel="noopener"
            //            target="_blank"> {t('position')}</a>);
            return (<ResourceLink
                className="installation-desc-link"
                icon={faMapMarkedAlt}
                linkState={{
                  url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
                  linkName: '',
                  description: t('position')
                }}
            />)
          })}
        </>
    );
  }

  function getDevicesListOptions() {
    // if(!(devicesState instanceof Map))
    //   return [];

    if (!(virtDevList instanceof Map))
      return [];

    const genLabel = (dev) => {
      return (
        <div key={`${dev.id}`} className="device-selection-element">
          <img 
            key={`img-${dev.id}`}
            alt={`${dev.name} (${dev.roomName})`} 
            height="32px" 
            src={IMAGE_BASE_URL+dev.iconURL} 
            width="32px"
          /><span key={`lab-${dev.id}`} className="device-selection-description">{dev.name} ({dev.roomName})</span>
        </div>
      );
    }

    // let options = [...devicesState.values()].map( dev => {
    let options = [...virtDevList.values()].map( (dev) => {
      if(showTechData) {
        if(!dev.isTech && !dev.detailAvailable)
          return null;
      } else {
        if(!dev.detailAvailable)
          return null;
      }
      // if((dev.valueType !== 'int') && (dev.valueType !== 'float'))
      //   return null;
      return ({
        key: dev.id,
        value: dev.id, 
        label: genLabel(dev),
        name: dev.name,
        dataType: dev.valueType,
        unit: dev.valueUnit,
        decimal: dev.decimal,
        dataList: null
      });
    });
    options = options.filter( el => el != null);
    return options;
  }

  return (
    <>
      {renderLoadingSpinner()}
      <HeaderSection 
        currRoute={currRoute} 
        errors={errors} 
        mainContentRef={mainContentRef}
        mqttStatus={mqttStatus}
        selectedInst={selectedInst}
      />
      <section className="main-content" ref={mainContentRef}>
        {(selectedInstallationData?.status === 3) && (
          <StaticBanner 
            backgroundColor="#d9534f"
            color="white" 
            icon='exclamation-triangle' 
            message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(selectedInstallationData.lastStatusUpdate).toLocaleString(i18n.language)}`}
          />
        )}
        {(hasCapability(selectedInstallationData, 'alerts-show')) && (
          <Alerts 
            alerts={selectedInstallationData.alerts} 
            canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')} 
            installationStatus={selectedInstallationData.status}
          />
        )}
        <div className="log-filter-div">
          <h1>
            {`${t('logPage.title')}`}
            <HelpButton message={'help.logPage'}/>
          </h1>
          <hr/>
          <div className="log-filter">
            <div className="log-filter-control log-filter-type">
            <Select
              // className="dropdown-select"
              // classNamePrefix="select"
              isDisabled={cannotChangeParams}
              isSearchable={false}
              name="log-data-type"
              onChange={handleTypeChange}
              options={LOG_TYPES_OPTIONS}
              value={LOG_TYPES_OPTIONS[logFilterType]}
            />
            </div>
            <div className="log-filter-control log-filter-devices">
              <Select
                closeMenuOnSelect={false}
                distance={4}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  trim: true,
                  matchFrom: 'any',
                  stringify: option => `${option.data.name}`
                })}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                isDisabled={cannotChangeParams}
                isMulti
                name="devices"
                onChange={onChangeDeviceSelection}
                onSortEnd={onSortDeviceListEnd}
                options={getDevicesListOptions()}
                placeholder={t('logPage.deviceSelectionPlaceholder')}
                useDragHandle
                value={devToList}
              />
            </div>
            <div className="interval-selection log-page">
              <div className="interval-selection-start">
                <span>{`${t('logPage.dateFrom')}`}</span>
                <DatePicker 
                  calendarClassName="log-calendar-start"
                  dateFormat="Pp"
                  disabled={cannotChangeParams}
                  endDate={timestampToDate(endDateTime)}
                  locale={i18n.language}
                  maxDate={timestampToDate(endDateTime)} 
                  onChange={onStartDateTimeChange} 
                  popperPlacement="right"
                  selected={timestampToDate(startDateTime)}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showTimeSelect
                  showYearDropdown
                  startDate={timestampToDate(startDateTime)}
                  timeCaption={`${t("Time")}`}
                  timeIntervals={60}
                  wrapperClassName="start-filter-date-time"
                />
              </div>
              <div className="interval-selection-end">
                <span>{`${t('logPage.dateTo')}`}</span>
                <DatePicker 
                  calendarClassName="log-calendar-end"
                  dateFormat="Pp"
                  disabled={cannotChangeParams}
                  endDate={timestampToDate(endDateTime)}
                  locale={i18n.language}
                  maxDate={new Date()} 
                  minDate={timestampToDate(startDateTime)}
                  onChange={onEndDateTimeChange}
                  popperPlacement="left"
                  selected={timestampToDate(endDateTime)}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showTimeSelect
                  showYearDropdown
                  startDate={timestampToDate(startDateTime)}
                  timeCaption={`${t("Time")}`}
                  timeIntervals={60}
                  wrapperClassName="end-filter-date-time"
                />
              </div>
            </div>
            <div className="log-page-navigator-block">
              <div className="log-page-navigator">
                <button 
                  className="cbutton prev-page-bt" 
                  disabled={cannotChangeParams || (offsetData < limitData)}
                  onClick={handleFirstPage}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button> 
                <button 
                  className="cbutton prev-page-bt" 
                  disabled={cannotChangeParams || (offsetData < limitData)}
                  onClick={handlePrevPage}
                >
                  <FontAwesomeIcon icon={faCaretLeft} />
                </button> 
                { <span 
                    className="data-page-position"
                  >
                    {`${(dataNumber === 0) ? '0' : (offsetData + 1)}-${((offsetData + limitData) < dataNumber) ? (offsetData + limitData) : dataNumber} ${t('of')} ${dataNumber}`}
                  </span>}
                <button 
                  className="cbutton next-page-bt" 
                  disabled={cannotChangeParams || ((offsetData + limitData) >= dataNumber)}
                  onClick={handleNextPage}
                >
                  <FontAwesomeIcon icon={faCaretRight} />
                </button> 
                <button 
                  className="cbutton next-page-bt" 
                  disabled={cannotChangeParams || ((offsetData + limitData) >= dataNumber)}
                  onClick={handleLastPage}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button> 
              </div>
              <div className="show-data-limit-block">
                <label className="select-label show-data-limit">
                  <div className="show-data-limit">
                    <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      isDisabled={cannotChangeParams}
                      isSearchable={false}
                      name="page-elements-limit"
                      onChange={handleLimitChange}
                      options={[
                        {value: 10, label: '10'}, 
                        {value: 20, label: '20'}, 
                        {value: 30, label: '30'}, 
                        {value: 50, label: '50'},
                        {value: 100, label: '100'}
                      ]}
                      value={{value: limitData, label: `${limitData}`}}
                    />
                  </div>
                </label>
                <span className="show-data-limit">{`${t('perPage')}`}</span>
                <label className="select-label show-data-limit">
                  <div className="show-data-limit">
                    <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      isDisabled={cannotChangeParams}
                      isSearchable={false}
                      name="page-selector"
                      onChange={handlePageChange}
                      options={genListOfPages()}
                      value={{value: Math.floor(offsetData / limitData), label: `${Math.floor(offsetData / limitData) + 1}`}}
                    />
                  </div>
                </label>
                <span className="show-data-limit">{`${t('page')}`}</span>
              </div>
            </div>
            <div className="log-filter-control">
              <div className="show-latest-data">
                <label className="checkbox-label show-latest-data-checkbox" htmlFor="show-latest-data-checkbox">
                  <input 
                    defaultChecked={latestDataFirst} 
                    disabled={cannotChangeParams} 
                    id="show-latest-data-checkbox" 
                    name="show-latest-data-checkbox"
                    onChange={handleToggleLatestFirst}
                    type="checkbox"
                  />
                  {t('logPage.showLatestDataFirst')}
                  <span className="checkmark"/>
                </label>
              </div>
            </div>
            <div className="log-filter-control">
              <div className="show-tech-data">
                <label className="checkbox-label show-tech-data-checkbox" htmlFor="show-tech-data-checkbox">
                  <input 
                    defaultChecked={showTechData} 
                    disabled={cannotChangeParams || logFilterType === LOG_FILTER_EVENTS} 
                    id="show-tech-data-checkbox" 
                    name="show-tech-data-checkbox"
                    onChange={handleToggleShowTechData}
                    type="checkbox"
                  />
                  {t('logPage.showTechData')}
                  <span className="checkmark"/>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="main-inner-div">
          <table className="log-table">
            <thead>
              <tr>
                <th className="log-header-item log-header-date">{`${t('logPage.date')}`}</th>
                <th className="log-header-item log-header-time">{`${t('logPage.time')}`}</th>
                <th className="log-header-item log-header-icon"></th>
                <th className="log-header-item log-header-event">{`${t('logPage.registration')}`}</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((log) => {
                return (
                  <tr key={log.entryId}>
                    <td className="log-item log-row-date">{timestampToLocalDateString(log.timestamp)}</td>
                    <td className="log-item log-row-time">{timestampToLocalTimeString(log.timestamp)}</td>
                    <td className="log-item log-row-icon">
                      <span className={`icona ${decodeValueCase(log.valueCase)} table-icon`}>
                        {(!log.eventDesc) && (
                          <i className={`icon ${extractFileName(log.iconURL)}`}></i>
                        )}
                        {(log.eventDesc) && (
                          <i className={`icon ${extractFileName(log.iconURL)}`}></i>
                        )}
                      </span>
                    </td>
                    <td className="log-item log-row-event">
                      {((log.entryType === 1) || (log.entryType == null)) && (
                        log.eventDesc
                      )}
                      {((log.entryType === 0) && (log.valueType !== 'json')) && (
                        log.name + ": " + intEventDescription(log)
                      )}
                      {((log.entryType === 0) && (log.valueType === 'json')) && (
                        jsonEventDescription(log)
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div className="log-buttons">
            <button id="exportButton" type="button" className="log-button cbutton">Export</button>
            <button id="printButton" type="button" className="log-button cbutton">Print</button>
          </div> */}
        </div>
        <FooterSection/>
      </section>
    </>
  )
}
