import {
  faBullhorn,
  faClipboardList,
  faCog,
  faConciergeBell,
  faExclamationTriangle,
  faHammer,
  faHandPaper,
  faHourglassEnd,
  faHourglassHalf, faLink,
  faLock,
  faLockOpen,
  faMagic,
  faMapMarkedAlt,
  faStar,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Fragment } from 'react';
import {
  convertFontAwesomeIconName,
  null_or_undefined,
  not_null_or_undefined,
  timestampToLocalString,
  timestampToLocalStringO
} from '../utilities';
import { useTranslation } from "react-i18next";
import ResourceLink from "./ResourceLink";

export default function CaseTableRow({caseInfo, deviceInfo, deviceInfos, inhibitHandler, takeHandler, reopenHandler, openJournalHandler, installationOffline}) {
  const {t, i18n} = useTranslation();
  const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

  function decodeCaseIcon(type) {
    switch(type) {
      case 1:
        return (
          <FontAwesomeIcon icon={faExclamationTriangle} />
        );

      case 2:
        return (
          <FontAwesomeIcon icon={faBullhorn} />
        );

      case 3:
        return (
          <FontAwesomeIcon icon={faHammer} />
        );

      case 4:
        return (
          <FontAwesomeIcon icon={faConciergeBell} />
        );
  
      case 5:
        return (
          <FontAwesomeIcon icon={faClipboardList} />
        );
  
      default:
        break;
    }
  }

  function decodeCaseEntryIcon(type) {
    switch(type) {
      case 1:
        return (
          <FontAwesomeIcon icon={faStar} />
        );

      case 2:
        return (
          <FontAwesomeIcon icon={faHourglassHalf} />
        );

      case 3:
        return (
          <FontAwesomeIcon icon={faHandPaper} />
        );

      case 4:
        return (
          <FontAwesomeIcon icon={faHourglassEnd} />
        );
  
      case 5:
        return (
          <FontAwesomeIcon icon={faLockOpen} />
        );
    
      case 6:
        return (
          <FontAwesomeIcon icon={faClipboardList} />
        );
      
      default:
        break;
    }
  }

  function decodeCaseClass(type, isInhibited, isClosed, isJournal) {
    if(isInhibited)
      return 'inhibited-bg';

    if(isClosed)
      return 'case-closed-bg';

    if(isJournal)
      return 'journal-bg';

    switch(type) {
      case 1:
        return 'alarm-bg';

      case 2:
        return 'warning-bg';

      case 3:
        return 'warning-bg';

      case 4:
        return 'warning-bg';
  
      default:
        break;
    }
  }

  function decodeCaseEntryAuthor(entry)
  {
    if(entry == null)
      return null;

    if((entry.userFirstName == null || entry.userFirstName === '-') && 
      (entry.userLastName == null || entry.userLastName === '-') && 
      (entry.userOrganization == null || entry.userOrganization === '-')) {
      return (
        <>
          <FontAwesomeIcon 
            icon={convertFontAwesomeIconName(entry?.userIconUrl) ?? faCog} 
          /> {`${t('alerts.system')}`}
        </>
      );
    }
    return (
      <>
        <FontAwesomeIcon 
          icon={convertFontAwesomeIconName(entry?.userIconUrl) ?? faUser} 
        /> {`${entry?.userFirstName ?? ''} ${entry?.userLastName ?? ''}${entry?.userOrganization ? ', ' + entry.userOrganization : ''}`}
      </>
    );
  }

  // function generateCaseOptionalLinks(deviceInfo, isClosed)
  // {
  //   if(isClosed)
  //     return null;
  //   let elements = [];
  //   if(deviceInfo) {
  //     if(deviceInfo.customAttrib) {
  //       // console.log(deviceInfo.customAttrib);
  //       for(const attrib in deviceInfo.customAttrib) {
  //         if(deviceInfo.customAttrib[attrib].type === 'link') {
  //           elements.push(
  //               <ResourceLink
  //                   key={deviceInfo.id+attrib}
  //                   className="case-desc-link"
  //                   icon={faLink}
  //                   linkState={{
  //                     url: deviceInfo.customAttrib[attrib].href,
  //                     linkName: `${deviceInfo.customAttrib[attrib].name[i18n.language]}`,
  //                     description: `${deviceInfo.customAttrib[attrib].name[i18n.language]}`
  //                   }}
  //               />
  //           )
  //         } else if (deviceInfo.customAttrib[attrib].type === 'positionIcon') {
  //           const referencedDeviceInfo = deviceInfos.has(deviceInfo.customAttrib[attrib].deviceId) ? deviceInfos.get(deviceInfo.customAttrib[attrib].deviceId) : null
  //           if(referencedDeviceInfo) {
  //             const position = JSON.parse(referencedDeviceInfo.value);
  //             // console.log(position)
  //             elements.push(
  //                 <ResourceLink
  //                     key={deviceInfo.id+attrib}
  //                     className="case-desc-position-link"
  //                     icon={faMapMarkedAlt}
  //                     linkState={{
  //                       url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
  //                       linkName: `${t('updated')}: ${timestampToLocalStringO(referencedDeviceInfo.lastStateChange, i18n.language,
  //                           {day: '2-digit', month: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit"})}, ${t('accuracy')}: ${position.accuracy} m`,
  //                       description: `${t('position')}`
  //                     }}
  //                 />
  //             )
  //           }
  //         }
  //       }
  //     }
  //
  //     return (<>
  //       {elements.map((el) => {
  //         return (<div>{el}</div>);
  //       })}
  //     </>);
  //   }
  // }

  function generateCaseOptionalLinks(deviceInfo, caseData, isClosed)
  {
    if(isClosed)
      return null;
    let elements = [];
    let counter = 0;
    if(deviceInfo) {
      if(caseData) {
        // console.log(deviceInfo.customAttrib);
        for(const attrib in caseData) {
          if(attrib === 'link') {
            elements.push(
                <ResourceLink
                    key={counter++}
                    className="case-desc-link"
                    icon={faLink}
                    linkState={{
                      url: caseData[attrib].url,
                      linkName: `${caseData[attrib].name}`,
                      description: `${caseData[attrib].name}`
                    }}
                />
            )
          } else if (attrib === 'dynamicPosition') {
            const referencedDeviceInfo = deviceInfos.has(caseData[attrib].deviceId) ? deviceInfos.get(caseData[attrib].deviceId) : null
            if(referencedDeviceInfo) {
              const position = JSON.parse(referencedDeviceInfo.value);
              // console.log(position)
              elements.push(
                  <ResourceLink
                      key={counter++}
                      className="case-desc-position-link"
                      icon={faMapMarkedAlt}
                      linkState={{
                        url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
                        linkName: `${t('updated')}: ${timestampToLocalStringO(referencedDeviceInfo.lastStateChange, i18n.language,
                            {day: '2-digit', month: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit"})}, ${t('accuracy')}: ${position.accuracy} m`,
                        description: `${t('position')}`
                      }}
                  />
              )
            }
          }
        }
      }

      return (<>
        {elements.map((el) => {
          return (<div>{el}</div>);
        })}
      </>);
    }
  }

  function generateCaseEntryOptionalLinks(entryData)
  {
    let elements = [];
    let counter = 0;
    if(entryData) {
      // console.log(entryData);
      for(const attrib in entryData) {
        if(attrib === 'link') {
          elements.push(
              <ResourceLink
                  key={counter++}
                  className="case-desc-link"
                  icon={faLink}
                  linkState={{
                    url: entryData[attrib].href,
                    linkName: `${entryData[attrib].name[i18n.language]}`,
                    description: `${entryData[attrib].name[i18n.language]}`
                  }}
              />
          )
        } else if (attrib === 'position') {
          const position = entryData[attrib];
          // console.log(position)
          elements.push(
              <ResourceLink
                  key={counter++}
                  className="case-desc-position-link"
                  icon={faMapMarkedAlt}
                  linkState={{
                    url: `https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`,
                    linkName: `${t('updated')}: ${timestampToLocalStringO(position.timestamp, i18n.language,
                        {day: '2-digit', month: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit"})}, ${t('accuracy')}: ${position.accuracy} m`,
                    description: `${t('position')}`
                  }}
              />
          )
        }
      }

      return (<>
        {elements.map((el) => {
          return (<div>{el}</div>);
        })}
      </>);
    }
  }

  if (caseInfo == null)
    return null;

  return (
    <div className={`case-table-row ${decodeCaseClass(caseInfo.type, caseInfo.lastState === 2, caseInfo.lastState === 0, caseInfo.lastState === 5)}`} >
      <div className="case-table-row-info">
        <div className="case-info">
          <span className="case-dev-icon">
            {(deviceInfo == null || null_or_undefined(deviceInfo.iconURL)) && (
              <FontAwesomeIcon icon={faCog} />
            )}
            {((deviceInfo != null) && not_null_or_undefined(deviceInfo.iconURL)) && (
              <img alt={deviceInfo.name} src={IMAGE_BASE_URL+deviceInfo.iconURL} />
            )}
          </span>
          <span className="case-title">
            {caseInfo.title}
          </span>
          <span className="case-desc">
            {caseInfo.description}
          </span>
          <span className="case-optional-links">
            {/*{generateCaseOptionalLinks(deviceInfo, caseInfo.lastState === 0)}*/}
            {generateCaseOptionalLinks(deviceInfo, caseInfo.caseData, caseInfo.lastState === 0)}
          </span>
        </div>
        
        <div className="case-icon">
          {decodeCaseIcon(caseInfo.type)}
        </div>

        {caseInfo?.instCaseEntries?.map?.((entry, idx) => {
          return (
            <Fragment key={entry.id}>
              <div className={"case-entry-desc-row" + (entry.entryType === 1 ? " case-begin-entry" : "") + (idx % 2 ? " dark" : " light")}>
                <span className="case-entry-desk-icon">{decodeCaseEntryIcon(entry.entryType)}</span>
                <span className="case-entry-timestamp">{`${timestampToLocalString(entry.dateTimeUtc)}`}</span>
                <span className="respect-whitespace">{`${entry.description}`}</span>
                {generateCaseEntryOptionalLinks(entry.entryData)}
              </div>
              <div className={"case-entry-author-row" + (idx % 2 ? " dark" : " light")}>
                {decodeCaseEntryAuthor(entry)}
              </div>
            </Fragment>
          );
        })}
      </div>
      {((caseInfo?.lastState === 1) || (caseInfo?.lastState === 2)|| (caseInfo?.lastState === 3) || (caseInfo?.lastState === 4)) && (caseInfo?.canBeInhibited !== false) && (
        <div className={"case-inhibition-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } inhibitHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faHourglassHalf} />
        </div>
      )}
      {((caseInfo?.lastState !== 0) && (caseInfo?.lastState !== 5)) && (caseInfo?.selfClosing !== true) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } takeHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faHandPaper} />
        </div>
      )}
      {(caseInfo?.lastState === 0) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } reopenHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faLock} />
        </div>
      )}
      {(caseInfo?.lastState === 5) && (
        <div className={"case-closing-icon " + (installationOffline ? "disabled" : "")}
             onClick={() => { if(installationOffline) { return alert(`${t('alertPageDetail.cannotExecuteWhileDisconnected')}`); } openJournalHandler(caseInfo)}}>
          <FontAwesomeIcon icon={faMagic} />
        </div>
      )}
    </div>
  );
}
