import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";

let serviceWorkerRegistration = null;
let firebaseApp = null;

export const initializeFirebase = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyB3OfgkjJ0aLZs95LkCdO05RCSozh6X9UE",
    authDomain: "wimhomecloud.firebaseapp.com",
    databaseURL: "https://wimhomecloud.firebaseio.com",
    projectId: "wimhomecloud",
    storageBucket: "wimhomecloud.appspot.com",
    messagingSenderId: "819929715162",
    appId: "1:819929715162:web:cb394c033b6435db59f0e3",
    measurementId: "G-HBWRY22D93"
  };
  firebaseApp = initializeApp(firebaseConfig);
  return firebaseApp;
};

export const getPushToken = async () => {
  const messaging = getMessaging(firebaseApp);
  const serviceUrl = process.env.REACT_APP_SITE_ROOT +'firebase-messaging-sw.js';
  return navigator.serviceWorker.register(serviceUrl)
  .then((registration) => {
    serviceWorkerRegistration = registration;
    return getToken(messaging, {
      vapidKey: 'BO2fAJIrrTkq1jF_lx4WpL330lgrY3AGcApuVyfHZltMRzzvO3IClcP9dqs7UVRb220n2irpR54Sa4Z3thPRv4Q', 
      serviceWorkerRegistration: registration 
    });
  })
  .then((currentToken) => {
    if (currentToken) {
      return {result: 0, err: null, token: currentToken};
    } else {
      // console.log('No registration token available. Request permission to generate one.');
      return {result: 1, err: null, token: null};
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    return {result: 2, err: err, token: null};
  });
};

export const requestPushPermission = async () => {
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return true;
    } else {
      return false;
    }
  });
};

export const deletePushToken = async () => {
  const messaging = getMessaging(firebaseApp);
  return deleteToken(messaging)
  .then(() => {
    // console.log('Token deleted.');
    return true;
  }).catch((err) => {
    // console.log('Unable to delete token. ', err);
    return {error: err};
  });
};

export function receivePushMessage() {
  const messaging = getMessaging(firebaseApp);
  // messaging.onMessage((payload) => {
  //   try {
  //     let n = new Notification(payload.notification.title, { body: payload.notification.body, icon: payload.notification.image });
  //     document.addEventListener('visibilitychange', () => {
  //       if (document.visibilityState === 'visible') {
  //         // The tab has become visible so clear the now-stale Notification.
  //         n.close();
  //       }
  //     });
  //   }
  //   catch(ex) {
  //     console.log(ex);
  //   }
  // });
  onMessage(messaging, (payload) => {
    // console.log('[firebase-messaging-sw.js] Received message ', payload);
    // Customize notification here
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.image
    };

    serviceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
  });
}