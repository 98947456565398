import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { SITE_LINKS } from '../Contants';

export default function Error404() {
  const {t/*, i18n*/} = useTranslation();

  return (
    <div className="error-page">
      <div className="error-msg-div">
        <h1>{t('pageNotFound')}</h1>
        <Link to={SITE_LINKS.LOGIN}>{t('goToHome')}</Link>
      </div>
    </div>
  )
}
