import React from 'react';

export default function PhoneContact({phone, prefix}) {
  return (
    <>
      {prefix}
      <a className="phone-link" href={`tel:${phone}`}>
        {`${phone}`}
      </a>
    </>
  )
}
