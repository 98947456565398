import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { faBellSlash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { not_null_or_undefined, timestampToLocalString } from '../utilities';
import { InstallationsContext } from './App';
import { SITE_LINKS } from '../Contants';
import {
  // INSTALLATION_MODE_NORMAL,
  INSTALLATION_MODE_DISABLED,
  INSTALLATION_MODE_MAINTENANCE,
  // INSTALLATION_MODE_MAINTENANCE_ALARM_TEST,
  // INSTALLATION_ALERT_STATUS_NO_ALARM,
  // INSTALLATION_ALERT_STATUS_WARNING ,
  // INSTALLATION_ALERT_STATUS_ALARM,
  INSTALLATION_ALERT_STATUS_DISCONNECTED, INSTALLATION_STATUS_OBJID_VAR
} from '../api/wimhome-api';

export default withRouter(function AlertsBar(props) {
  const { history } = props;
  const {t/*, i18n*/} = useTranslation();
  const { 
    selectedInst, 
    handleSelectInstallation,
    installations,
    ackAlerts,
    setAckAlerts,
    acknowledgeAlerts,
    alertSoundInfo,
    alerter, 
    setAlerter,
    alerts, 
    setAlerts,
    curAlertsNumber,
    setCurAlertsNumber
  } = React.useContext(InstallationsContext);

  const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;
  const SOUNDS_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

  function jumpToInstallation(serial, alertsToMark) {
    acknowledgeAlerts(serial, alertsToMark);
    // setAckAlerts( (cur) => {
    //   let newAcks = new Map(cur);
    //   if(newAcks.has(serial)) {
    //     newAcks.get(serial).alerts.concat(alertsToMark);
    //   } else {
    //     newAcks.set(serial, {id: serial, alerts: alertsToMark});
    //   }
    //   return newAcks;
    // });

    if(serial === selectedInst)
      return;
    handleSelectInstallation(serial);
    history.push(SITE_LINKS.STATUS);
  }

  function replaceOlderAndDeduplicateAcks(ackArray) {
    let newArray = [];
    for (const ack of ackArray) {
      let found = false
      for (const newAck of newArray) {
        if (newAck.id === ack.id) {
          found = true
          if(newAck.date < ack.date) {
            newAck.date = ack.date
            newAck.description = ack.description
          }
          break
        }
      }
      if(!found) {
        newArray.push(ack)
      }
    }
    return newArray;
  }

  function markAllAlertsAsSeen(alertsToMark) {
    setAckAlerts( (cur) => {
      let newAcks = new Map(cur);
      alertsToMark.forEach((inst) => {
        if(newAcks.has(inst.id)) {
          const concatAlerts = newAcks.get(inst.id).alerts.concat(inst.alerts);
          newAcks.get(inst.id).alerts = replaceOlderAndDeduplicateAcks(concatAlerts);
        } else {
          newAcks.set(inst.id, {id: inst.id, alerts: inst.alerts});
        }
      });
      return newAcks;
    });
  }

  useEffect(() => {
    function cleanUpAckAlerts() {
      let isChanged = false;
      if(ackAlerts.size === 0)
        return;

      let currentAlerts = new Map();
      Object.values(installations).forEach((installation) => {
        if(installation.mode === INSTALLATION_MODE_DISABLED || installation.mode === INSTALLATION_MODE_MAINTENANCE)
          return;
        if(installation.alerts.length || (installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED)) {
          let instAlerts = [];
          installation.alerts.forEach( (al) => {
            if(al.value > 0) {
              instAlerts.push({
                id: al.deviceId,
                date: al.date
              });
            }
          });
          if(installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) {
            instAlerts.push( {
              id: INSTALLATION_STATUS_OBJID_VAR,
              date: installation.lastStatusUpdate
            });
          } 
          if(instAlerts.length) {
            currentAlerts.set(installation.id, {id: installation.id, description: installation.title, alerts: instAlerts});
          }
        }
      });
      
      let newAckAlerts = new Map();
      ackAlerts.forEach((inst) => {
        if(!currentAlerts.has(inst.id)) {
          isChanged = true;
        } else {
          newAckAlerts.set(inst.id, {id: inst.id, alerts: []});
          inst.alerts.forEach((ack) => {
            if(currentAlerts.get(inst.id).alerts.findIndex(al => ((ack.id === al.id) && (ack.date === al.date))) !== -1) {
              newAckAlerts.get(inst.id).alerts.push(ack);
            } else {
              isChanged = true;
            }
          });
        }
      });

      if(isChanged)
        setAckAlerts(newAckAlerts);
    }

    if(not_null_or_undefined(installations) && not_null_or_undefined(ackAlerts)) {
      let currentAlertsNumber = {"alarms": 0, "warnings": 0, "disconnections": 0};
      let newAlerts = new Map();
      Object.values(installations).forEach((installation) => {
        if(installation.mode === INSTALLATION_MODE_DISABLED || installation.mode === INSTALLATION_MODE_MAINTENANCE)
          return;
        let acks = [];
        if(ackAlerts.has(installation.id)) {
          acks = ackAlerts.get(installation.id).alerts;
        }

        if(installation.alerts.length || (installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED)) {
          let instAlerts = [];
          installation.alerts.forEach( (al) => {
            if((al.value > 0) && (acks.findIndex(ack => ((ack.id === al.deviceId) && (ack.date === al.date))) === -1)) {
              if(installation.id === selectedInst) {
                if((al.type === 2 && alertSoundInfo.playForAlarms) || (al.type === 1 && alertSoundInfo.playForWarnings)) {
                  setTimeout(() => {
                    const x = document.getElementById("alert-bar-audio");
                    if (not_null_or_undefined(x))
                      x.play();
                  }, 500);
                }

                setAckAlerts( (cur) => {
                  if(!cur.has(installation.id)) {
                    cur.set(installation.id, {id: installation.id, alerts: []});
                  }
                  cur.get(installation.id).alerts.push({id: al.deviceId, date: al.date});
                  return cur;
                });
              } else {
                instAlerts.push({
                  id: al.deviceId, 
                  description: `${timestampToLocalString(al.date)}: ${al.title} - ${al.message}`, 
                  icon: <img alt={`${t(al.message)}`} src={IMAGE_BASE_URL+al.iconUrl} style={{filter: "invert(100%)"}}/>,
                  type: al.type,
                  date: al.date
                });
                if(al.type === 1)
                  currentAlertsNumber.warnings++;
                else if(al.type === 2)
                  currentAlertsNumber.alarms++;
              }
            }
          });
          if(installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) {
            if(acks.findIndex(ack => ((ack.id === INSTALLATION_STATUS_OBJID_VAR) && (ack.date === installation.lastStatusUpdate))) === -1) {
              if(installation.id === selectedInst) {
                if(alertSoundInfo.playForDisconnections) {
                  setTimeout(() => {
                    const x = document.getElementById("alert-bar-audio");
                    if (not_null_or_undefined(x))
                      x.play();
                  }, 500);
                }
                
                setAckAlerts( (cur) => {
                  if(!cur.has(installation.id)) {
                    cur.set(installation.id, {id: installation.id, alerts: []});
                  }
                  cur.get(installation.id).alerts.push({id: INSTALLATION_STATUS_OBJID_VAR, date: installation.lastStatusUpdate});
                  return cur;
                });
              } else {
                instAlerts.push( {
                  id: INSTALLATION_STATUS_OBJID_VAR,
                  description: `${t('installations.disconnectedSince')} ${timestampToLocalString(installation.lastStatusUpdate)} `, 
                  icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
                  type: 3,
                  date: installation.lastStatusUpdate
                });
                currentAlertsNumber.disconnections++;
              }
            } 
          }
          if(instAlerts.length) {
            newAlerts.set(installation.id, {id: installation.id, description: installation.title, alerts: instAlerts});
          }
        }
      });
      cleanUpAckAlerts();
      setAlerts(newAlerts);

      if((alertSoundInfo != null) && ((currentAlertsNumber.alarms > curAlertsNumber.alarms) && alertSoundInfo?.playForAlarms) ||
          ((currentAlertsNumber.warning > curAlertsNumber.warning) && alertSoundInfo?.playForWarnings) ||
          ((currentAlertsNumber.disconnections > curAlertsNumber.disconnections) && alertSoundInfo?.playForDisconnections)) {
        setTimeout(() => {
          const x = document.getElementById("alert-bar-audio");
          if (not_null_or_undefined(x))
            x.play();
        }, 500);
      }

      if(currentAlertsNumber.disconnections !== curAlertsNumber.disconnections || currentAlertsNumber.alarms !== curAlertsNumber.alarms ||
          currentAlertsNumber.warnings !== curAlertsNumber.warnings) {
        setCurAlertsNumber(currentAlertsNumber);
      }
    }
  }, [installations, ackAlerts, setAckAlerts, t, IMAGE_BASE_URL, setAlerts, selectedInst, curAlertsNumber, setCurAlertsNumber]);

  useEffect(() => {
    if((alerter.currentAlertsNumber) && alerter.currentAlertsNumber.alarms === curAlertsNumber.alarms
        && alerter.currentAlertsNumber.warnings === curAlertsNumber.warnings
        && alerter.currentAlertsNumber.disconnections === curAlertsNumber.disconnections)
      return;

    if(alerts.size === 0) {
      if(alerter.timer !== null) {
        clearInterval(alerter.timer);
        setAlerter({timer: null, currentAlertsNumber: {...curAlertsNumber}});
        const x = document.getElementById("alert-bar-audio"); 
        if(not_null_or_undefined(x))
          x.pause();
      }
    } else {
      if((alertSoundInfo !== null)) {
        let alertToPlay = false;
        alerts.forEach((instAlerts) => {
          if(alertToPlay)
            return;
          for (const al of instAlerts.alerts) {
            if ((al.type === 2 && alertSoundInfo.playForAlarms) ||
                (al.type === 1 && alertSoundInfo.playForWarnings) ||
                (al.type === 3 && alertSoundInfo.playForDisconnections)) {
              alertToPlay = true;
              break;
            }
          }
        });
        if(!alertToPlay && (alerter.timer !== null)) {
          clearInterval(alerter.timer);
          setAlerter({timer: null, currentAlertsNumber: {...curAlertsNumber}});
          const x = document.getElementById("alert-bar-audio");
          if(not_null_or_undefined(x))
            x.pause();
        } else if(alertToPlay && (alerter.timer == null)) {
          const timerH = setInterval(() => {
            const x = document.getElementById("alert-bar-audio");
            if(not_null_or_undefined(x))
              x.play();
          }, alertSoundInfo.interval * 1000);

          setTimeout(() => {
            const x = document.getElementById("alert-bar-audio");
            if(not_null_or_undefined(x))
              x.play();
          }, 500);

          setAlerter({timer: timerH, currentAlertsNumber: {...curAlertsNumber}});
        }
      }
    }
  }, [alertSoundInfo, alerter.timer, alerts, setAlerter, curAlertsNumber]);

  return (
    <>
      {(alertSoundInfo !== null) && (
        <audio id="alert-bar-audio">
          <source src={SOUNDS_BASE_URL+alertSoundInfo.sound} type="audio/wav"/>
          Your browser does not support the audio element.
        </audio>
      )}
      {(alerts.size > 0) && (
        <div className="alert-bar">
          <div className="alert-list">
            {[...alerts.values()].map( (inst) => {
              return inst.alerts.map( (alert) => {
                return (
                  <p key={inst.id + alert.id} className="alert-entry" onClick={() => jumpToInstallation(inst.id, inst.alerts)}>
                    {alert.icon} <span className="installation-desc">{inst.description}</span> - <span className="alert-desc">{alert.description}</span>
                  </p>
                );
              })
            })}
          </div>
          <div className="taker-control">
            <FontAwesomeIcon className="taker-control-bell" icon={faBellSlash} onClick={() => markAllAlertsAsSeen(alerts)}/>
          </div>
        </div>
      )}
    </>
  );
});
