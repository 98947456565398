import React, { useState, useEffect, useRef } from 'react';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import FooterSection from './FooterSection';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import { hasCapability, InstallationsContext } from './App';
import { useTranslation } from 'react-i18next';
import { timestampToDate, dateToTimestamp, /*secondsToElapsedTimeStr, timestampToLocalStringO,*/ colourNameToHex, secondsToElapsedTimeStr } from '../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import Select, { components, createFilter } from 'react-select';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import dateSub from 'date-fns/sub';
import { DEFAULT_COLORS } from '../Contants';
import useDebounceCallback from "../custom-hooks/use-debounce-callback";

import '../css/graphical.css';
import BarGraph from './BarGraph';
import ScatterGraph from './ScatterGraph';
import StaticBanner from './StaticBanner';
import { Puff } from "react-loader-spinner";
import HelpButton from "./HelpButton";
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api";

const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;
const MAX_GRAPH_INTERVAL = 3600*24*30*6;  // 6 months
const MAX_GRAPH_INTERVAL_ERROR_MESSAGE = 'applicationErrors.cannotRetrieveMoreThan6Months';

export const GRAPH_COLORS_POOL = [
  '#75ac65',
  '#f39c12',
  '#d9534f',
  '#993366',
  '#3d6dc3',
  '#0033cc',
  '#666699',
  '#999999'
];

//Used by react-datepicker 
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = (props) => {
  return (
      <Draggable draggableId={props.data.value} index={props.index}>
        {provided => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
              <components.MultiValue
                  {...props}
              />
            </div>)
        }
      </Draggable>
  );
};


export default function GraphPage({ currRoute }) {
  let mainContentRef = React.createRef();

  const {t, i18n} = useTranslation();
  const {
    installations,
    selectedInst,
    devicesState,
    requestDeviceData,
  } = React.useContext(InstallationsContext);

  const NUMBER_OF_BARS = 720;

  const [devToGraph, setDevToGraph] = useState([]);
  // const [startDateTime, setStartDateTime] = useState(dateToTimestamp(dateSub(new Date(), { days: 1 })));
  // const [endDateTime, setEndDateTime] = useState(dateToTimestamp(new Date()));
  const [dateTimeInterval, setDateTimeInterval] =
      useState({start: dateToTimestamp(dateSub(new Date(), { days: 1 })), end: dateToTimestamp(new Date())})
  const [cannotChangeParams, setCannotChangeParams] = useState(false)
  const mustUpdate = useRef('no');

  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  const delayedUpdateDateTime = useDebounceCallback(2000);
  // delayedUpdateDateTime((textToDisplay) => {
  //   alert(textToDisplay)
  // }, 'Ciao');

  function renderLoadingSpinner() {
    return (
        <div
            className="embedded-loading-overlay-page"
            id="embedded-loading-overlay-page"
            style={cannotChangeParams ? null : {display: 'none'}}
        >
          <Puff
              color="#00BFFF"
              height={100}
              // visible={cannotChangeParams === true}
              visible={true}
              width={100}
              wrapperClass="loading-spinner"
              // timeout={3000} //3 secs
          />
        </div>
    );
  }

  function forceSpinnerVisibility(visible)
  {
    document.getElementById("embedded-loading-overlay-page").setAttribute('display',null);
  }

  const onChange = (selectedOptions) => {
    mustUpdate.current = 'new';
    setDevToGraph(selectedOptions ?? [])
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newValue = arrayMove(devToGraph, result.source.index, result.destination.index) ?? [];
    onChange(newValue);
  };

  function extractLabelMaps(percentages, normalColor, activeColor, warningColor, errorColor) {
    let colors = new Map();
    let values = new Map();

    if(percentages == null)
      return {values, colors};

    percentages.forEach((percentage) => {
      switch(percentage.valueCase) {
        default:
        case 0:
          percentage.color = normalColor ?? DEFAULT_COLORS.NORMAL;
          break;
        case 1:
          percentage.color = activeColor ?? DEFAULT_COLORS.ACTIVE;
          break;
        case 2:
          percentage.color = warningColor ?? DEFAULT_COLORS.WARNING;
          break;
        case 3:
          percentage.color = errorColor ?? DEFAULT_COLORS.ERROR;
          break;
      }

      values.set(percentage.valueCase, {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
      colors.set(colourNameToHex(percentage.color), {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
    });
    return {values, colors};
  }

  function selectColor() {
    let colorFreqMap = new Map();
    GRAPH_COLORS_POOL.forEach((color) => {
      colorFreqMap.set(color, 0);
    });
    devToGraph.forEach((devInfo) => {
      colorFreqMap.set(devInfo.graphColor, colorFreqMap.get(devInfo.graphColor) + 1);
    });
    let colorToUse = GRAPH_COLORS_POOL[0];
    let minUsed = 10000;
    colorFreqMap.forEach((used, color) => {
      if(used < minUsed) {
        colorToUse = color;
        minUsed = used;
      }
    });
    return colorToUse;
  }

  useEffect(() => {
    if(mustUpdate.current === 'no')
      return;

    if((selectedInst == null) && ((devToGraph.length > 0))) {
      setDevToGraph([]);
      return;
    }

    devToGraph?.forEach((devId, idx) => {
      if((mustUpdate.current === 'all') || (devToGraph[idx].dataList == null)) {
        setCannotChangeParams(true);
        requestDeviceData(selectedInst, devId.value, dateTimeInterval.start, dateTimeInterval.end)
            .then((data) => {
              if(data == null) {
                alert("Invalid data returned for device " + devId.value)
                setCannotChangeParams(false);
                return;
              }
              setDevToGraph((cur) => {
                cur[idx].dataList = data.dataList;
                cur[idx].dataPercentages = data.percentages;
                //return cloneDeep(cur);
                let newval = [];
                cur.forEach((element, idx) => {
                  newval[idx] = element;
                });
                return newval;
              });
              setCannotChangeParams(false);
            })
            .catch((error) => {
              setCannotChangeParams(false);
            })
      }
    });

    mustUpdate.current = 'no';
  }, [devToGraph, dateTimeInterval, requestDeviceData, selectedInst]);

  function getDevicesListOptions() {
    if(!(devicesState instanceof Map))
      return [];

    const genLabel = (dev) => {
      const nextColor = selectColor();
      return (
          <div key={`${dev.id}`} className="device-selection-element">
            <div
                key={`img-${dev.id}`}
                // src={IMAGE_BASE_URL+dev.iconURL}
                // alt={`${dev.name} (${dev.roomName})`}
                // height="32px"
                // width="32px"
                style={
                  {
                    mask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`,
                    WebkitMask: `url("${IMAGE_BASE_URL+dev.iconURL}") center/contain`,
                    backgroundColor: `${nextColor}`,
                    width: '32px',
                    height: '32px'
                  }
                }
            /><span key={`lab-${dev.id}`} className="device-selection-description" style={{color: `${nextColor}`}}>{dev.name} ({dev.roomName})</span>
          </div>
      );
    }

    let options = [...devicesState.values()].map( (dev) => {
      const nextColor = selectColor();
      if(!dev.detailAvailable)
        return null;
      if((dev.valueType !== 'int') && (dev.valueType !== 'float'))
        return null;
      return {
        key: dev.id,
        value: dev.id,
        label: genLabel(dev),
        name: dev.name,
        roomName: dev.roomName,
        dataType: dev.valueType,
        unit: dev.valueUnit,
        decimal: dev.decimal,
        dataList: null,
        dataPercentages: null,
        graphColor: nextColor
      };
    });
    options = options.filter( el => el != null);
    return options;
  }

  function decodeGraphType(valueType, percentages) {
    switch (valueType) {
      case 'int':
        if(percentages != null)
          return 1;
        return 2;

      case 'float':
        return 2;

      case 'json':
        return 0;

      default:
        return 0;
    }
  }

  return (
      <>
        <HeaderSection
            currRoute={currRoute}
            mainContentRef={mainContentRef}
        />
        <section
            className="main-content"
            ref={mainContentRef}
        >
          {renderLoadingSpinner()}
          {(selectedInstallationData?.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) && (
              <StaticBanner
                  backgroundColor="#d9534f"
                  color="white"
                  icon='exclamation-triangle'
                  message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(selectedInstallationData.lastStatusUpdate).toLocaleString(i18n.language)}`}
              />
          )}
          {(hasCapability(selectedInstallationData, 'alerts-show')) && (
              <Alerts
                  alerts={selectedInstallationData.alerts}
                  canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')}
                  installationStatus={selectedInstallationData.status}
              />
          )}
          <h1 className="graph-page-header" >
            {`${t('graphPage.title')}`}
            <HelpButton message={'help.graphPage'}/>
          </h1>
          <hr/>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                direction="horizontal"
                droppableId="droppable"
            >
              {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Select
                        // react-sortable-hoc props:
                        axis="xy"
                        closeMenuOnSelect={false}
                        components={{
                          MultiValue: SortableMultiValue
                        }}
                        distance={4}
                        filterOption={createFilter({
                          ignoreCase: true,
                          ignoreAccents: true,
                          trim: true,
                          matchFrom: 'any',
                          stringify: option => `${option.data.name}`
                        })}
                        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                        // react-select props:
                        isMulti
                        name="devices"
                        onChange={onChange}
                        options={getDevicesListOptions()}
                        placeholder={t('graphPage.deviceSelectionPlaceholder')}
                        useDragHandle
                        value={devToGraph}
                    />
                    {provided.placeholder}
                  </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="interval-selection graph-page">
            <div className="interval-selection-start">
              <DatePicker
                  dateFormat="Pp"
                  endDate={timestampToDate(dateTimeInterval.end)}
                  locale={i18n.language}
                  maxDate={new Date()}
                  onChange={(date) => {
                    delayedUpdateDateTime( () => {
                      setDateTimeInterval((cur) => {
                        mustUpdate.current = 'all';
                        cur.start = dateToTimestamp(date);
                        if(cur.start >= cur.end) {
                          cur.start = cur.end - 3600;
                        } else if(cur.end - cur.start > MAX_GRAPH_INTERVAL) {
                          alert(`${t(MAX_GRAPH_INTERVAL_ERROR_MESSAGE)}`);
                          cur.end = cur.start + MAX_GRAPH_INTERVAL;
                        }
                        return {...cur};
                      })
                    })
                  }}

                  popperPlacement="right"
                  selected={timestampToDate(dateTimeInterval.start)}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showTimeSelect
                  showYearDropdown
                  startDate={timestampToDate(dateTimeInterval.start)}
                  timeCaption={`${t("Time")}`}
                  timeIntervals={60}
                  wrapperClassName="start-filter-date-time"
              />
            </div>
            <div className="interval-selection-start-icon">
              <FontAwesomeIcon icon={faStepBackward} />
            </div>
            <div className="interval-selection-end-icon">
              <FontAwesomeIcon icon={faStepForward} />
            </div>
            <div className="interval-selection-end">
              <DatePicker
                  dateFormat="Pp"
                  endDate={timestampToDate(dateTimeInterval.end)}
                  locale={i18n.language}
                  maxDate={new Date()}
                  minDate={timestampToDate(dateTimeInterval.start)}
                  onChange={(date) => {
                    delayedUpdateDateTime( () => {
                      setDateTimeInterval((cur) => {
                        mustUpdate.current = 'all';
                        cur.end = dateToTimestamp(date);
                        if(cur.end <= cur.start) {
                          cur.end = cur.start + 3600;
                        } else if(cur.end - cur.start > MAX_GRAPH_INTERVAL) {
                          alert(`${t(MAX_GRAPH_INTERVAL_ERROR_MESSAGE)}`);
                          cur.start = cur.end - MAX_GRAPH_INTERVAL;
                        }
                        return {...cur};
                      })
                    })
                  }}
                  popperPlacement="left"
                  selected={timestampToDate(dateTimeInterval.end)}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showTimeSelect
                  showYearDropdown
                  startDate={timestampToDate(dateTimeInterval.start)}
                  timeCaption={`${t("Time")}`}
                  timeIntervals={60}
                  wrapperClassName="end-filter-date-time"
              />
            </div>
          </div>
          <div
              className="graphs-container-div"
              onDoubleClick={(param) => {
                setCannotChangeParams(true);
                forceSpinnerVisibility(true);
                param.persist();
                const clientWidth = document.documentElement.clientWidth;
                // const percentPos = param.clientX / clientWidth * 100;

                const interval = dateTimeInterval.end - dateTimeInterval.start;
                const center = (param.clientX / clientWidth) * interval + dateTimeInterval.start;

                // console.log(param);
                if(param.ctrlKey) {
                  const newStart = Math.round(center - interval / 2);
                  const newEnd = Math.round(center + interval / 2);
                  setDateTimeInterval({start: newStart, end: newEnd});
                  mustUpdate.current = 'all';
                  // alert(`${percentPos}% interval: ${secondsToElapsedTimeStr(interval, t)} clickPos: ${timestampToLocalStringO(center)}, start: ${timestampToLocalStringO(newStart)}, end: ${timestampToLocalStringO(newEnd)} newInterval: ${secondsToElapsedTimeStr(newEnd - newStart, t)}`);
                } else {
                  if(param.shiftKey) {
                    const newStart = Math.round(center - interval);
                    const newEnd = Math.round(center + interval);
                    setDateTimeInterval({start: newStart, end: newEnd});
                    mustUpdate.current = 'all';
                    // alert(`${percentPos}% interval: ${secondsToElapsedTimeStr(interval, t)} clickPos: ${timestampToLocalStringO(center)}, start: ${timestampToLocalStringO(newStart)}, end: ${timestampToLocalStringO(newEnd)} newInterval: ${secondsToElapsedTimeStr(newEnd - newStart, t)}`);
                  } else {
                    const newStart = Math.round(center - interval / 4);
                    const newEnd = Math.round(center + interval / 4);
                    setDateTimeInterval({start: newStart, end: newEnd});
                    mustUpdate.current = 'all';
                    // alert(`${percentPos}% interval: ${secondsToElapsedTimeStr(interval, t)} clickPos: ${timestampToLocalStringO(center)}, start: ${timestampToLocalStringO(newStart)}, end: ${timestampToLocalStringO(newEnd)} newInterval: ${secondsToElapsedTimeStr(newEnd - newStart, t)}`);
                  }
                }
              }}
          >
            {devToGraph?.map((devId, idx) => {
              if(devId.dataList == null)
                return null;
              let graph = null;
              const graphType = decodeGraphType(devId.dataType, devId.dataPercentages);
              switch(graphType) {
                case 1:
                  graph = (
                      <>
                        <BarGraph
                            dataToRender={devId.dataList}
                            displayXScale={true}
                            end={dateTimeInterval.end}
                            graphClassName="graph-page bar-chart-full-width"
                            hideMinutes={false}
                            locale={i18n.language}
                            numberOfBars={NUMBER_OF_BARS}
                            start={dateTimeInterval.start}
                            valueMaps={extractLabelMaps(devId.dataPercentages, '#ffffff', devId.graphColor, null, null)}
                        />
                        <p className="graph-page graph-legend">{`${devId.name} (${devId.roomName}) [${t('timeResolution')} ${secondsToElapsedTimeStr((dateTimeInterval.end - dateTimeInterval.start) / NUMBER_OF_BARS, t)}]`}</p>
                      </>
                  );
                  break;

                case 2:
                  graph = (
                      <>
                        <ScatterGraph
                            dataToRender={devId.dataList}
                            decimal={devId.decimal}
                            graphClassName="graph-page line-chart-full-width"
                            lineColor={devId.graphColor}
                            locale={i18n.language}
                            unit={devId.unit}
                        />
                        <p className="graph-page graph-legend">{`${devId.name} (${devId.roomName})`}</p>
                      </>
                  );
                  break;

                default:
                  ;
              }
              return (
                  <div key={devId.value}>
                    {graph}
                  </div>
              );
            })}
          </div>
          <FooterSection/>
        </section>
      </>
  )
}
