import React, {useEffect, useState, useRef } from 'react';
import 'react-responsive-modal/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt, faUpload} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import {InstallationsContext} from "./App";
import {null_or_undefined} from "../utilities";
import { confirmAlert } from "react-confirm-alert"; // Import

const MAX_UPLOADABLE_FILE_SIZE = 10 * 1048576;

export default function InstallationLinkElement({id, type, linkData, setLinkData, deleteFn, verifyLinkNameUniqueness}) {
    function isUrlInfoDoc(url) {
        return url.substring(0,21) === "wim-file://info/docs/";
    }

    function extractFileFromInfoDocUrl(url) {
        if(!isUrlInfoDoc(url))
            return null;
        return url.substring(21);
    }

    const {t} = useTranslation();

    const {
        selectedInst,
        postInstallationInfoDoc,
        deleteInstallationInfoDoc
    } = React.useContext(InstallationsContext);

    const [uploadFileInput, setUploadFileInput] = useState(null);

    const isUploadedFile = useRef(isUrlInfoDoc(linkData.url));

    useEffect(() => {
        let el = document.getElementById("file-custom-" + id);
        if(el !== null) {
            if(uploadFileInput === null) {
                el.setAttribute('data-content', `${t('remindersAdminPage.selectFile')}`);
            } else {
                if(uploadFileInput.size > MAX_UPLOADABLE_FILE_SIZE) {
                    alert(`${t('remindersAdminPage.errorFileTooBig')}: ${Math.round(uploadFileInput.size/1024)}k >= ${Math.round(MAX_UPLOADABLE_FILE_SIZE/1024)}k`);
                    setUploadFileInput(null);
                }
                el.setAttribute('data-content', `${uploadFileInput.name} (${Math.round(uploadFileInput.size/1024)}k)`);
                isUploadedFile.current = true;
            }
        }
    }, [t, uploadFileInput, id, setLinkData, linkData.linkName, linkData.description]);

    async function sendUploadFile() {
        if(null_or_undefined(uploadFileInput)) {
            alert(`${t('remindersAdminPage.selectFile')}`);
            return;
        }
        const res = await postInstallationInfoDoc(selectedInst, uploadFileInput, linkData.linkName, linkData.description);
        if(res === void 0) {
            alert(`${t('uploadFailed')}`);
        } else {
            alert(`${t('uploadCompleted')}`);
            setUploadFileInput(null);
            let el = document.getElementById("file-custom-" + id);
            if(el !== null) {
                el.setAttribute('readonly', 'readonly');
            }
        }
    }

    async function deleteFile() {
        const infoDocFileName = extractFileFromInfoDocUrl(linkData.url);
        if(infoDocFileName == null) {
            deleteFn(id);
            return;
        }
        confirmAlert({
            title: `${t('installationLink.deleteFileAlert')}`,
            buttons: [
                {
                    label: `${t("Yes")}`,
                    onClick: () => {
                        deleteInstallationInfoDoc(selectedInst, infoDocFileName)
                            .then( (res) => {
                                if(res)
                                    deleteFn(id);
                                else
                                    alert(`${t('installationLink.fileDeleteError')}`);
                            });
                    },
                },
                {
                    label: `${t("No")}`,
                },
            ],
            overlayClassName: "overlay-confirm-alert-over-all"
        });
    }

    return (
        <>
            {
                (type === 'edit') && (
                <form className='links-modify-form'>
                    <div className="link-column link-first-column">
                        <div className="guest-form-row">
                            <label className="guest-form-row" htmlFor={"link-name" + id}>
                                {t('installationLink.linkName')}:
                                <input
                                    defaultValue={`${linkData.linkName ?? ''}`}
                                    id={"link-name-" + id}
                                    name={"link-name" + id}
                                    onBlur={(e) => {
                                        let linkName = e.target.value;
                                        setLinkData(id, linkName, linkData.description, linkData.url);
                                    }}
                                    type="text"
                                />
                            </label>
                        </div>
                        <div className="guest-form-row">
                            <label className="guest-form-row" htmlFor={"description" + id}>
                                {t('installationLink.description')}:
                                <input
                                    defaultValue={`${linkData.description ?? ''}`}
                                    id={"description-" + id}
                                    name={"description" + id}
                                    onChange={(e) => {
                                        let description = e.target.value;
                                        setLinkData(id, linkData.linkName, description, linkData.url);
                                    }}
                                    type="text"
                                />
                            </label>
                        </div>
                        <div className="guest-form-row">
                            <label className="guest-form-row" htmlFor={"url" + id}>
                                {t('installationLink.url')}:
                                <input
                                    defaultValue={`${linkData.url ?? ''}`}
                                    id={"url-" + id}
                                    name={"url" + id}
                                    onChange={(e) => {
                                        let url = e.target.value;
                                        setLinkData(id, linkData.linkName, linkData.description, url);
                                    }}
                                    readOnly={ isUploadedFile.current }
                                    type="text"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="link-column link-second-column">
                        <span className="link-trash-link" onClick={() => {deleteFile().then();}}>
                            <FontAwesomeIcon className="link-trash-icon" icon={faTrashAlt}/>
                        </span>
                    </div>
                </form>)
            }
            {
                (type === 'upload') && (
                    <form className='links-modify-form'>
                        <div className="link-column link-first-column">
                            <div className="guest-form-row">
                                <label className="guest-form-row" htmlFor={"link-name" + id}>
                                    {t('installationLink.linkName')}:
                                    <input
                                        defaultValue={`${linkData.linkName ?? ''}`}
                                        id={"link-name-" + id}
                                        name={"link-name" + id}
                                        onBlur={(e) => {
                                            let linkName = e.target.value;
                                            setLinkData(id, linkName, linkData.description, linkData.url);
                                        }}
                                        type="text"
                                    />
                                </label>
                            </div>
                            <div className="guest-form-row">
                                <label className="guest-form-row" htmlFor={"description" + id}>
                                    {t('installationLink.description')}:
                                    <input
                                        defaultValue={`${linkData.description ?? ''}`}
                                        id={"description-" + id}
                                        name={"description" + id}
                                        onChange={(e) => {
                                            let description = e.target.value;
                                            setLinkData(id, linkData.linkName, description, linkData.url);
                                        }}
                                        type="text"
                                    />
                                </label>
                            </div>
                            <div className="reminder-info-centered reminder-dialog-full-width">
                                <label className="file">
                                    <input
                                        accept={"application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
                                            "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," +
                                            "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presen,tation," +
                                            "application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.presentation," +
                                            "application/vnd.oasis.opendocument.spreadsheet,application/rtf," +
                                            "text/plain,image/jpeg,image/png,image/tiff,image/webp," +
                                            "application/dxf,application/x-dxf,image/vnd.dxf,image/x-dxf,application/x-autocad,image/x-autocad,image/x-dwg,drawing/x-dwf"}
                                        id={"file-" + id}
                                        onChange={e => setUploadFileInput(e.target.files[0])}
                                        type="file"
                                    />
                                    <span className="file-custom" data-content={`${t('remindersAdminPage.selectFile')}`} id={"file-custom-" + id}></span>
                                </label>
                                <FontAwesomeIcon className="valign3 clickable" icon={faUpload} onClick={() => {
                                    if(!verifyLinkNameUniqueness()) {
                                        alert(t('installationLinks.linkNamesNotUnique'));
                                        return;
                                    }
                                    sendUploadFile();
                                }}
                                />
                            </div>
                        </div>
                        <div className="link-column link-second-column">
                        <span className="link-trash-link" onClick={() => {deleteFile().then();}}>
                            <FontAwesomeIcon className="link-trash-icon" icon={faTrashAlt}/>
                        </span>
                        </div>
                    </form>)
            }
            {
                (type === 'show') && (
                <div>
                    <p><span>{t('installationLink.linkName')}: </span>{linkData.linkName}</p>
                    <p><span>{t('installationLink.description')}: </span>{linkData.description}</p>
                    <p><span>{t('installationLink.url')}: </span>{linkData.url}</p>
                </div>)
            }
        </>
    );
}