import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import '../css/StaticBanner.css';

library.add(faExclamationTriangle);

export default function StaticBanner({message, icon, color, backgroundColor}) {
    return (
      <div className="static-banner" style={{color: color, backgroundColor: backgroundColor}}>
        {(icon !== null) && (
          <FontAwesomeIcon className="static-banner-icon" icon={icon}/>
        )}
        <span className="static-banner-message">{message}</span>
      </div>
    )
}
  