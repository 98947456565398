import React, { useState } from 'react';

export default function TileBadge( {tileId, badgeNumber, isUnread, handleCollapseChange} ) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  function genClassName(isCollapsed, isUnread) {
    return `notifica ${isCollapsed ? 'collapsed' : ''} ${isUnread ? 'unread' : ''}`;
  }

  function handleOnClick() {
    if(handleCollapseChange !== null) {
      handleCollapseChange(tileId, isCollapsed);
      setIsCollapsed( collapsed => !collapsed );
    }
  }

  return (
    <div 
      className={`${genClassName(isCollapsed, isUnread)} ${handleCollapseChange !== null ? 'clickable' : ''}`} 
      data-toggle="collapse" 
      id={`id_notifica_${tileId}`}
      onClick={() => handleOnClick()}
    >
      {isCollapsed && (<span className="chiuso">{badgeNumber}</span>)}
      {!isCollapsed && (<span className="aperto">X</span>)}
    </div>
  )
}
