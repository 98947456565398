import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export default function ResourceLink({
  linkState,
  setLinkState,
  className,
  editDivClassName,
  icon,
  edit,
  nameEditLabel,
  descriptionEditLabel,
  urlEditLabel,
}) {
  return (
    <>
      {edit !== true && (
        <>
          <a
            alt={linkState.description}
            className={className}
            href={linkState.url}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={className}>
              <FontAwesomeIcon icon={icon ? icon : faLink} />
            </span> {linkState.linkName}
          </a>
        </>
      )}
      {edit === true && (
        <div className={editDivClassName}>
          <div className={className}>
            <label htmlFor={'nameEdit_'}>
              <b>{nameEditLabel}</b>
              <input
                  // defaultValue={linkState.linkName}
                name={'nameEdit_'}
                onChange={(e) => {
                  const newValue = e.target.value;
                  linkState.linkName = newValue;
                  setLinkState(linkState);
                }}
                type='text'
                value={linkState.linkName}
              />
            </label>
          </div>
          <div className={className}>
            <label htmlFor='descriptionEdit'>
              <b>{descriptionEditLabel}</b>
              <input
                name='descriptionEdit'
                // defaultValue={description}
                onChange={(e) => {
                  const newValue = e.target.value;
                  linkState.description = newValue;
                  setLinkState(linkState);
                }}
                type='text'
                value={linkState.description}
              />
            </label>
          </div>
          <div className={className}>
            <label htmlFor='urlEdit'>
              <b>{urlEditLabel}</b>
              <input
                name='urlEdit'
                // defaultValue={linkState.url}
                onChange={(e) => {
                  const newValue = e.target.value;
                  linkState.url = newValue;
                  setLinkState(linkState);
                }}
                type='text'
                value={linkState.url}
              />
            </label>
          </div>
        </div>
      )}
    </>
  );
}
