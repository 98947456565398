import Paho from 'paho-mqtt';
import { v4 as uuidv4 } from 'uuid';

class WiMHomeMQTT {
  constructor() {
    if (!WiMHomeMQTT.wiMHomeMQTTClient) {
      this.hostname = 'mqtt.wimonitor.cloud';
      this.port = 9001;
      this.useSSL = true;
      this.reconnect = false;
      this.clientId = uuidv4();

      this.username = null;
      this.password = null;

      this.onConnect = onConnect;
      this.onFailure = onFailure;
      this.onMessageArrived = null;
      this.onConnectionLost = null;

      this.connected = false;

      this.client = null;

      //      this.installations = [];
      this.instSerial = null;

      this.connectionCb = null;

      this.execDeviceUpdate = null;
      this.execAlertsUpdate = null;

      this.connectionOptions = {
        onSuccess: this.onConnect,
        onFailure: this.onFailure,
        userName: this.username,
        password: this.password,
        useSSL: this.useSSL,
        invocationContext: this,
        reconnect: this.reconnect,
      };
    }

    return WiMHomeMQTT.wiMHomeMQTTClient;
  }

  connect(
    hostname,
    port,
    useSSL,
    reconnect,
    clientId,
    username,
    password,
    onConnect,
    onFailure,
    onMessageArrived,
    onConnectionLost,
    execDeviceUpdate,
    execAlertsUpdate
  ) {
    // console.log("Connecting...");

    if (hostname == null) {
      this.hostname = 'mqtt.wimonitor.cloud';
    } else {
      this.hostname = hostname;
    }

    if (port != null) {
      this.port = port;
    }

    if (useSSL != null) {
      this.useSSL = useSSL;
    }

    if (reconnect != null) {
      this.reconnect = reconnect;
    }

    if (clientId != null) {
      this.clientId = clientId;
    }

    this.username = username;
    this.password = password;

    if (onConnect != null) {
      this.onConnect = onConnect;
    }

    if (onFailure != null) {
      this.onFailure = onFailure;
    }
    this.onMessageArrived = onMessageArrived;
    this.onConnectionLost = onConnectionLost;

    this.execDeviceUpdate = execDeviceUpdate;
    this.execAlertsUpdate = execAlertsUpdate;

    this.connected = false;

    this.client = new Paho.Client(this.hostname, Number(this.port), this.clientId);

    // set callback handlers
    this.client.onConnectionLost = this.onConnectionLost;
    this.client.onMessageArrived = this.onMessageArrived;

    this.connectionOptions = {
      onSuccess: this.onConnect,
      onFailure: this.onFailure,
      userName: this.username,
      password: this.password,
      useSSL: this.useSSL,
      invocationContext: this,
      reconnect: this.reconnect,
    };

    // connect the client
    this.client.connect(this.connectionOptions);
  }

  disconnect() {
    if (this.client != null) {
      if (this.client.isConnected()) {
        this.client.disconnect();
      }
    }
  }

  // subscribeInstallations() {
  //   if(this.client.isConnected) {
  //     this.installations.forEach(instSerial => {
  //       this.client.subscribe(`interfaces/${instSerial}/updates/+/1/json`);
  //     });
  //   }
  // }

  subscribeInstallation() {
    if (this.client && this.client.isConnected() && this.instSerial !== null) {
      this.client.subscribe(`interfaces/${this.instSerial}/updates/+/1/json`);
    }
  }

  unsubscribeInstallation() {
    if (this.client.isConnected() && this.instSerial !== null) {
      this.client.unsubscribe(`interfaces/${this.instSerial}/updates/+/1/json`);
    }
  }

  subscribeUserAlerts() {
    if (this.client.isConnected()) {
      this.client.subscribe(`interfaces/users/${this.username}/alerts/1/json`);
    }
  }

  unsubscribeUserAlerts() {
    if (this.client.isConnected()) {
      this.client.unsubscribe(`interfaces/users/${this.username}/alerts/1/json`);
    }
  }

  isConnected() {
    return this.client.connected;
  }
}

// called when the client connects
function onConnect(object) {
  // Once a connection has been made, make the subscription(s)
  object.invocationContext.connected = true;
  if (object.invocationContext.connectionCb != null) {
    object.invocationContext.connectionCb(true);
  }
  // console.log("onConnect");
  // console.log(object);
  setTimeout(() => {
    //    object.invocationContext.subscribeInstallations();
    object.invocationContext.subscribeUserAlerts();
  }, 0);
}

function onFailure(object) {
  console.log("onFailure");
  console.log(object);
  object.invocationContext.connected = false;
  if (object.invocationContext.connectionCb != null) {
    object.invocationContext.connectionCb(false);
  }
}

export const wiMHomeMQTTClient = new WiMHomeMQTT();
