export function ColorLegend({colorsMap, customClassName}) {
  return (
    <div className={customClassName}>
      {
        colorsMap.map((colorInfo) => {
            return (<p key={colorInfo.color} className={'legend-element'}><span className={'legend-color-symbol'} style={{color: colorInfo.color}}>{'\u25FC'}</span> <span className={'legend-color-text'}>{colorInfo.text}</span></p>)
        })
      }
    </div>
  )
}