import { faQuestionCircle, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { SITE_LINKS, EXTERNAL_LINKS } from '../Contants';
import { GenericContext } from './App';
import FooterSection from './FooterSection';
import LanguageSelector from './LanguageSelector';

export default withRouter(function Login(props) {
  const { authenticatedUser, handleLogin, handleRememberLoginChange, handleStayConnectedChange, history, lang } = props;
  const [formState, setFormState] = useState({});
  const [loggingIn, setLoggingIn] = useState(false);
  const { t /*, i18n*/ } = useTranslation();

  const { lastError, handleLanguageChange } = React.useContext(GenericContext);

  function handleChange(e) {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  }

  function handleFormSubmit(e) {
    if (loggingIn === false) {
      setLoggingIn(true);
      handleLogin(
        formState.username ? formState.username : authenticatedUser.storedUsername,
        formState.password,
        authenticatedUser.stayConnected
      );
    }
    e.preventDefault();
  }

  function handleFormRememberLogin(e) {
    if (authenticatedUser.storedUsername !== null) {
      formState.username = authenticatedUser.storedUsername;
    }
    handleRememberLoginChange();
  }

  function handleFormStayConnected(e) {
    handleStayConnectedChange();
  }

  useEffect(() => {
    document.documentElement.setAttribute('lang', lang.length > 0 ? lang[0] : 'it');
  }, [lang]);

  useEffect(() => {
    if (authenticatedUser.username != null) {
      // console.log("Current authenticated user: ", authenticatedUser.username);
      if (lastError.lastErrorCode !== 0) {
        // console.log("Last error: " + lastError.lastErrorMessage + " code: " + lastError.lastErrorCode);
      } else {
        history.push(SITE_LINKS.INSTALLATIONS);
      }
    } else {
      // console.log("No authenticated user");
    }
    if (lastError.lastErrorCode > 0) {
      setLoggingIn(false);
    }
  }, [authenticatedUser, lastError, history]);

  return (
    <>
      {/*{console.log(lang)}*/}
      {/*{console.log(i18n.services.languageDetector.detect())}*/}
      <div
        className='login-container'
        style={{ background: `url('${process.env.REACT_APP_LOGIN_BG_IMG_FILE}') no-repeat center center fixed` }}
      >
        <form className='form-login' id='login_form' onSubmit={handleFormSubmit}>
          <img alt={process.env.REACT_APP_LOGO_LOGIN_ALT} className='logo' src={process.env.REACT_APP_LOGO_LOGIN} />
          {lastError.lastErrorCode > 0 && (
            <div className='login-error'>
              <label>
                <strong>{t(lastError.lastErrorMessage)}</strong>
              </label>
            </div>
          )}
          <div className='login-wrap'>
            <LanguageSelector currentLanguage={lang.length > 0 ? lang[0] : null} languageSelector={handleLanguageChange} />

            <input
              autoFocus
              className='form-control login-user'
              defaultValue={authenticatedUser.storedUsername ? authenticatedUser.storedUsername : ''}
              name='username'
              onChange={handleChange}
              placeholder={t('login.username')}
              type='text'
            />
            <br />
            <input
              className='form-control login-pass'
              name='password'
              onChange={handleChange}
              placeholder={t('login.password')}
              type='password'
            />
            <button
              className={`cbutton btn-login${loggingIn ? ' btn-login-in-progress' : ''}`}
              id='Entra'
              name='LOGIN'
              title={t('login.login')}
              type='submit'
              value='login'
              // className="cbutton btn-login"
            >
              <i>{t('login.login')}</i>
            </button>
            <div className='remember-login'>
              <label className='checkbox-label' htmlFor='remember-login-checkbox'>
                <input
                  defaultChecked={authenticatedUser.rememberUsername}
                  id='remember-login-checkbox'
                  name='remember-login-checkbox'
                  onChange={handleFormRememberLogin}
                  type='checkbox'
                />
                {t('login.rememberLogin')}
                <span className='checkmark' />
              </label>
            </div>
            <div className='stay-connected'>
              <label className='checkbox-label' htmlFor='stay-connected-checkbox'>
                <input
                  defaultChecked={authenticatedUser.stayConnected}
                  id='stay-connected-checkbox'
                  name='stay-connected-checkbox'
                  onChange={handleFormStayConnected}
                  type='checkbox'
                />
                {t('login.stayConnected')}
                <span className='checkmark' />
              </label>
            </div>
            <div className='request-password-reset'>
              <a
                alt={t('login.passwordRecovery')}
                href={process.env.REACT_APP_PSW_RECOVERY_LINK}
                rel='noopener noreferrer'
                target='_blank'
              >
                <FontAwesomeIcon icon={faQuestionCircle} /> {`${t('login.passwordRecovery')}`}
              </a>
            </div>
            <div style={{ display: 'flex', paddingTop: '20px' }}>
              <div className='mobile-store-badge-block' id='appstore-badge-block'>
                <div className='mobile-store-badge' id='appstore-badge'>
                  <a href={`${EXTERNAL_LINKS.APP_STORE_APP}`}>
                    <img
                      alt={`${t('login.appStoreMessage')}`}
                      height={'50px'}
                      src={`${t('login.appStoreIconUrl')}`}
                      style={{ marginTop: '8px', marginBottom: '8px', marginRight: '12.2px' }}
                    />
                    <details className='qr-icon'>
                      <summary className='qr-icon'>
                        <FontAwesomeIcon icon={faQrcode} />
                      </summary>
                      <div className='mobile-store-qr' id='appstore-qr' style={{ paddingRight: '5%' }}>
                        <img alt={`${t('login.appStoreMessage')}`} height={'100px'} src='images/appstore-qr.svg' />
                      </div>
                    </details>
                  </a>
                </div>
              </div>
              <div
                className='mobile-store-badge-block'
                id='playstore-badge-block'
                style={{ marginLeft: 'auto' }}
              >
                <div className='mobile-store-badge' id='playstore-badge'>
                  <a href={`${EXTERNAL_LINKS.PLAY_STORE_APP}`}>
                    <img
                      alt={`${t('login.googlePlayMessage')}`}
                      height={'65px'}
                      src={`${t('login.googlePlayIconUrl')}`}
                    />
                    <details className='qr-icon'>
                      <summary className='qr-icon'>
                        <FontAwesomeIcon icon={faQrcode} />
                      </summary>
                      <div className='mobile-store-qr' id='playstore-qr'>
                        <img alt={`${t('login.googlePlayMessage')}`} height={'100px'} src='images/playstore-qr.svg' />
                      </div>
                    </details>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FooterSection login={true} />
        </form>
      </div>
    </>
  );
});
