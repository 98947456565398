import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown, faMapMarkedAlt, faClipboardList, faTasks, faEdit } from '@fortawesome/free-solid-svg-icons';
import ResourceLink from './ResourceLink';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import ContactPersons from './ContactPersons';
import { encodeRFC5987ValueChars } from '../utilities';
import { InstallationsContext } from './App';
import { SITE_LINKS } from '../Contants';
import JournalEntry from './JournalEntry';
import JournalInterface from './JournalInterface';
import LinksEditInterface from './LinksEditInterface';

export default function InstallationDesc({ installationData, persons, canViewAlerts, canManageAlerts, canEditInstInfo }) {
  const {t} = useTranslation(); 

  const { 
    selectedInst,
    installations,
    loadLastJournalEntry
  } = React.useContext(InstallationsContext);

  const title = `${t("statusPage.installationDescTitle")} ${installationData ? installationData.title : '...'}`;
  const description = `${installationData ? installationData.description : ''}`;
  const note = installationData ? installationData.note : null;
  //const links = installationData ? installationData.links : null;
  const address = installationData ? installationData.address : null;

  const [show, setShow] = useState(false);
  const [links, setLinks] = useState(installationData ? installationData.links : null);
  const [lastJournalEntry, setLastJournalEntry] = useState(null);
  const [journalModalType, setJournalModalType] = useState('closed');
  const [linksEditModalType, setLinksEditModalType] = useState('closed');
  const userCapabilities = useRef(null);

  const nodeRef = React.useRef(null)

  function toggleShow() {
    setShow( (show) => { return !show; });
  }

  useEffect(() => {
    if(selectedInst) {
      userCapabilities.current = installations[selectedInst]?.userCapabilities;
    } else {
      userCapabilities.current = null;
    }
  }, [installations, selectedInst]);

  useEffect(() => {
    if(userCapabilities.current?.includes('alerts-management')) {
      loadLastJournalEntry(selectedInst)
      .then(((entry) => {
        setLastJournalEntry(entry);
      }));
    }
  }, [loadLastJournalEntry, selectedInst]);

  return (
    <div className="installation-desc-div">
      <h1 className="installation-desc-header" onClick={toggleShow}>
        <span className="collapsable-section-desc" title={`Seriale: ${selectedInst}`}>
          <FontAwesomeIcon icon={(show ? faArrowCircleUp : faArrowCircleDown )}/>
          {title}
        </span>
        {(canViewAlerts) && (
          <Link className="ticketing-entry-access" to={SITE_LINKS.ALERT_DETAIL}>
            {t("statusPage.ticketing")}
            <FontAwesomeIcon className="ticketing-entry-icon" icon={faTasks}/>
          </Link>
        )}
        {(canManageAlerts) && (
          <span className="journal-entry-access" onClick={(e) => {e.stopPropagation(); setJournalModalType('show');}}>
            {t("statusPage.journal")}
            <FontAwesomeIcon className="add-journal-entry-icon" icon={faClipboardList}/>
            {/* <FontAwesomeIcon icon={faPlusCircle} className="add-journal-entry-icon" onClick={handleAddNewJournalEntry}/> */}
          </span>
        )}
      </h1>
      <hr/>
      {(journalModalType !== 'closed') && (
        <JournalInterface setType={setJournalModalType} type={journalModalType} />
      )}
      {(canEditInstInfo && linksEditModalType !== 'closed') && (
          <LinksEditInterface links={links} setLinks={setLinks} setType={setLinksEditModalType} type={linksEditModalType} />
      )}
      <CSSTransition classNames="installation-desc-trans" in={show} nodeRef={nodeRef} timeout={500} unmountOnExit>
        <div ref={nodeRef}>
          <div>
            <p className="respect-whitespace">{description}</p>
            {note && (<p className="respect-whitespace">{note}</p>)}
            <h2>{t('statusPage.addressHeader')} <ResourceLink 
                          className="installation-desc-link"       
                          icon={faMapMarkedAlt}
                          linkState={{
                            url: `https://www.google.com/maps/search/?api=1&query=${encodeRFC5987ValueChars(address)}`,
                            linkName: '',
                            description: t('statusPage.mapLinkName')
                          }}
                        /></h2>
            <p>{address}</p>
            {(canEditInstInfo) && (
                <span className="installation-desc-link link-edit-access" onClick={(e) => {
                  e.stopPropagation();
                  setLinksEditModalType('edit');
                }}>
              <FontAwesomeIcon className="link-edit-icon" icon={faEdit}/> {t('statusPage.editLinks')}
            </span>)}
            {links && (
              links.map( link =>
                <ResourceLink 
                  key={link.url}
                  className="installation-desc-link" 
                  linkState={link}
                />
              )
            )}
            {lastJournalEntry && (
              <div>
                <h2>{t('statusPage.lastJournalEntry')}</h2>
                <JournalEntry 
                  authorColor={lastJournalEntry.userColor}
                  authorIcon={lastJournalEntry.userIconUrl}
                  authorName={lastJournalEntry.userFirstName}
                  authorOrg={lastJournalEntry.userOrganization}
                  authorSurname={lastJournalEntry.userLastName}
                  caseDesc={lastJournalEntry.description ? lastJournalEntry.description : null}
                  dateTime={lastJournalEntry.dateTimeUtc}
                  entry={lastJournalEntry.entryDescription}
                  title={lastJournalEntry.title}/>
              </div>)
            }
          </div>
          {/*{persons && (<ContactPersons persons={persons} standalone={false}/>)}*/}
        </div>
      </CSSTransition>
    </div>   
  );
}
