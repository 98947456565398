import { useReducer, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from '../Contants';

export const CURRENT_USER_ACTIONS = {
  RESET: 'reset',
  SET_USER: 'set-user',
  SET_LANGUAGE: 'set-lang',
  SET_STAY_CONNECTED: 'set-stay-connected',
  SET_REMEMBER_USERNAME: 'set-remember-username',
  SET_ALL: 'set-all',
  LOGOUT: 'log-out'
};

const defaultEmptyUserState = {
  username: null, 
  firstName: '',
  lastName: '',
  type: null,
  sessionDuration: 900,
  language: null,
  rememberUsername: false,
  storedUsername: null,
  stayConnected: false
};

function currentUserReducer(curUser, action) {
  switch(action.type) {
    case CURRENT_USER_ACTIONS.RESET:
      return defaultEmptyUserState;

    case CURRENT_USER_ACTIONS.SET_ALL:
      return action.info;

    case CURRENT_USER_ACTIONS.SET_USER:
      return { ...curUser, 
        userId: action.info.userId, 
        username: action.info.username, 
        firstName: action.info.firstName, 
        lastName: action.info.lastName, 
        type: action.info.type };

    case CURRENT_USER_ACTIONS.SET_LANGUAGE:
      return { ...curUser, 
        language: action.info.language };

    case CURRENT_USER_ACTIONS.SET_STAY_CONNECTED:
      return { ...curUser, 
        stayConnected: action.info.stayConnected };

    case CURRENT_USER_ACTIONS.SET_REMEMBER_USERNAME:
      return { ...curUser, 
        rememberUsername: action.info.rememberUsername, 
        storedUsername: (!action.info.rememberUsername) ? null : curUser.username };

    case CURRENT_USER_ACTIONS.LOGOUT:
      return {...defaultEmptyUserState, 
        rememberUsername: curUser.rememberUsername, 
        storedUsername: curUser.rememberUsername ? curUser.storedUsername : null
      };

    default:
      return curUser;
  }
}

export function CurrentUser() {
  const [currentUser, dispatch] = useReducer(currentUserReducer, defaultEmptyUserState);

  useEffect(() => {
    const currentUserJSON = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_USER);
    if(currentUserJSON != null) {
      dispatch({ type: CURRENT_USER_ACTIONS.SET_ALL, info: JSON.parse(currentUserJSON) });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_USER, JSON.stringify(currentUser));
  }, [currentUser]);

  return [currentUser, dispatch];
}
