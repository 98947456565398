import React, { useState, useEffect, useRef } from 'react';
import { not_null_or_undefined, null_or_undefined } from '../utilities';

export default function LanguageSelector({currentLanguage, languageSelector}) {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    if(showMenu) {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
    }
  }, [showMenu]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  function setNewLanguage(lang) {
    languageSelector(lang);
    setShowMenu(false);
  }

  function renderMenu(show) {
    if(!show)
      return;

    return (
      <div className="language-menu" ref={wrapperRef}>
        <div className="language-menu-option">
          <img 
            alt="english language" 
            className="flag-img" 
            onClick={() => {setNewLanguage('en');}} 
            src="images/en_flag.png" 
            title="English language"
          />
          <span onClick={() => {setNewLanguage('en');}}>English</span>
        </div>
        <div className="language-menu-option">
          <img 
            alt="lingua italiana" 
            className="flag-img" 
            onClick={() => {setNewLanguage('it');}} 
            src="images/it_flag.png" 
            title="Lingua italiana"
          />
          <span onClick={() => {setNewLanguage('it');}}>Italiano</span>
        </div>
        <div className="language-menu-option">
          <img 
            alt="deutsche Sprache" 
            className="flag-img" 
            onClick={() => {setNewLanguage('de');}} 
            src="images/de_flag.png" 
            title="deutsche Sprache"
          />
          <span onClick={() => {setNewLanguage('de');}}>Deutsche</span>
        </div>
        <div className="language-menu-option">
          <img 
            alt="langue française" 
            className="flag-img" 
            onClick={() => {setNewLanguage('fr');}} 
            src="images/fr_flag.png" 
            title="langue française"
          />
          <span onClick={() => {setNewLanguage('fr');}}>Français</span>
        </div>
      </div>
    );
  }
  
  return (
    <>
      {renderMenu(showMenu)}
      <span className="flags">
        {(not_null_or_undefined(currentLanguage) && (currentLanguage.substring(0,2) === 'en')) && (
          <img 
            alt="english language" 
            className="flag-img" 
            onClick={() => {setShowMenu(show => !show);}} 
            src="images/en_flag.png" 
            title="English language"
          />
        )}
        {(null_or_undefined(currentLanguage) || (currentLanguage.substring(0,2) === 'it')) && (
          <img 
            alt="lingua italiana" 
            className="flag-img" 
            onClick={() => {setShowMenu(show => !show);}} 
            src="images/it_flag.png" 
            title="Lingua italiana"
          />
        )}
        {(not_null_or_undefined(currentLanguage) && (currentLanguage.substring(0,2) === 'de')) && (
          <img 
            alt="deutsche Sprache" 
            className="flag-img" 
            onClick={() => {setShowMenu(show => !show);}} 
            src="images/de_flag.png" 
            title="deutsche Sprache"
          />
        )}
        {(not_null_or_undefined(currentLanguage) && (currentLanguage.substring(0,2) === 'fr')) && (
          <img 
            alt="langue française" 
            className="flag-img" 
            onClick={() => {setShowMenu(show => !show);}} 
            src="images/fr_flag.png" 
            title="langue française"
          />
        )}
      </span>
    </>
  )
}
