import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import { useTranslation } from 'react-i18next';
import { /*GenericContext,*/ InstallationsContext } from './App';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faBook, faEdit, faLightbulb, faLongArrowAltDown, faLongArrowAltUp, faMinusCircle, 
  faMobileAlt, faPlusCircle, faSms, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FooterSection from './FooterSection';
import Select from 'react-select';
import { SITE_LINKS } from '../Contants';
import { null_or_undefined, not_null_or_undefined } from '../utilities';

const newEmptyAction = {
  isNew: true,
  type: null,
  messageTemplate: null,
  recipients: ["*"]
};

const newEmptyInstAction = {
  isNew: true,
  id: null,
  label: '',
  actions: []
};

export default function ReminderActionsPage({ currRoute, devicesState }) {
  const {t, /*i18n*/} = useTranslation();
  const { 
    selectedInst, 
    installations,
    loadInstReminderActions,
    deleteReminderAction,
    updateReminderAction,
    createReminderAction
  } = useContext(InstallationsContext);

  // const { 
  //   lastError
  // } = React.useContext(GenericContext);

  const [requestingInstActions, setRequestingInstActions] = useState(false);
  const [instActions, setInstActions] = useState(null);
  const [orderedInstActionsRefs, setOrderedInstActionsRefs] = useState(null);
  const [listActionsOptions, setListActionsOptions] = useState(
    {
      orderByField: "label",
      orderDirectionAsc: true,
      elementPerPage: -1,  //-1 => all
      pageOffset: 0
    }
  );
  
  const [actionsEditModal, setActionsEditModal] = useState(null);
  const [actionEditModal, setActionEditModal] = useState(null);
  const [showTypeLegend, setShowTypeLegend] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  let mainContentRef = React.createRef();
  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  function actionsEditOnClickHandler(actions) {
    setActionsEditModal(cloneDeep(actions));
  }

  function closeActionsEditModal() {
    setActionsEditModal(null);
  }

  async function saveActionsEditModal() {
    if(actionsEditModal.actions.length === 0) {
      alert(`${t('remindersAdminPage.atLeastAnActionNeeded')}`);
      return;
    }

    if(actionsEditModal.isNew === true) {
      actionsEditModal.isNew = null;
      const res = await createReminderAction(selectedInst, {...actionsEditModal});
      if(not_null_or_undefined(res)) {
        let newInstActions = new Map(instActions);
        newInstActions.set(res.id, res);
        setInstActions(newInstActions);
        setActionsEditModal(null);
      }  
    } else {
      const res = await updateReminderAction(selectedInst, actionsEditModal.id, {...actionsEditModal});
      if(not_null_or_undefined(res)) {
        let newInstActions = new Map(instActions);
        newInstActions.set(actionsEditModal.id, res);
        setInstActions(newInstActions);
        setActionsEditModal(null);
      }  
    }
  }

  function actionEditOnClickHandler(action) {
    setActionEditModal(cloneDeep(action));
  }

  function closeActionEditModal() {
    setActionEditModal(null);
  }

  function saveActionEditModal() {    
    if(actionEditModal.isNew === true) {
      actionEditModal.isNew = null;
      actionsEditModal.actions.push(cloneDeep(actionEditModal));
    } else {
      for(let a = 0; a < actionsEditModal.actions.length; ++a) {
        if(actionsEditModal.actions[a].type === actionEditModal.type) {
          actionsEditModal.actions[a] = cloneDeep(actionEditModal);
        }
      }
    }
    setActionEditModal(null);
  }

  function closeTypeLegendModal() {
    setShowTypeLegend(false);
  }

  function renderTypeLegendModal() {
    return (
      <Modal
        onClose={closeTypeLegendModal}
        open={showTypeLegend}
      >
        <>
          <div className="legend">
            <h3>{`${t('remindersAdminPage.typeLegendTitle')}`}</h3>
            <FontAwesomeIcon icon={faMobileAlt} /><span>{`${t('remindersAdminPage.actionAppNotification')}`}</span>
            <FontAwesomeIcon icon={faAt} /><span>{`${t('remindersAdminPage.actionEmail')}`}</span>
            <FontAwesomeIcon icon={faSms} /><span>{`${t('remindersAdminPage.actionSms')}`}</span>
            <FontAwesomeIcon icon={faLightbulb} /><span>{`${t('remindersAdminPage.actionDevice')}`}</span>
            <div className="reminder-buttons">
              <button className="cbutton reminder-button" onClick={() => { closeTypeLegendModal() }} type="button">{`${t('back')}`}</button>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  function actionRow(instAction, action)
  {
    return(
      <tr key={action.type}>
        <td className="reminder-action-fulltype">{actionsTypes([action], true)}</td>
        <td className="reminder-action-recipients">{userListToString(action.recipients)}</td>
        <td className="reminder-action-model">{action.messageTemplate}</td>
        <td className="reminder-action-details">
          {(instAction.actions.length > 1) && (
            <FontAwesomeIcon 
              className="clickable" 
              icon={faTrashAlt} 
              onClick={ () => actionDeleteOnClickHandler(instAction, action.type)} 
              title={`${t('remindersAdminPage.actionDelete')}`} 
            />
          )}
          <FontAwesomeIcon 
            className="clickable" 
            icon={faEdit} 
            onClick={ () => actionEditOnClickHandler(action)} 
            title={`${t('remindersAdminPage.actionEdit')}`} 
          />
        </td>
      </tr>
    );
  }

  function rendersActionsEditModal() {
    if(null_or_undefined(actionsEditModal))
      return;

      return (<Modal
        onClose={closeActionsEditModal}
        open={actionsEditModal !== null}
        styles={{modal: {maxWidth: '95%'}}}
      >
        <>
          {rendersActionEditModal()}
          <div style={{marginTop: '2em'}}>
            <h3 className="modal-header reminder-dialog-full-width">
              <label>
              {`${t('remindersAdminPage.reminderActionFor')}`}
                <input 
                  id="label" 
                  name="label" 
                  onChange={(e) => {
                    const newLabel = e.target.value;
                    // setActionsEditLabel( (cur) => {
                    setActionsEditModal( (cur) => {
                      // return {oLabel: cur.oLabel, label: newLabel}; 
                      return {...cur, label: newLabel}; 
                    })}
                  } 
                  type="text" 
                  //value={actionsEditLabel.label !== null ? actionsEditLabel.label : ''} 
                  value={actionsEditModal.label !== null ? actionsEditModal.label : ''}/>
              </label>
              {/* {(actionsEditLabel.oLabel !== actionsEditLabel.label) && (
                <button 
                  type="button" 
                  id="modify-label-button" 
                  className="cbutton reminder-button" 
                  onClick={() => {updateActionsLabel()}}
                >
                  {`${t('SAVE')}`}
                </button>
              )} */}
              {(allowedActionTypesOptions(actionsEditModal.actions.map( action => action.type )).length > 0) && (
                <button 
                  className="cbutton reminder-button reminder-add-control" 
                  id="add-button" 
                  onClick={() => {actionEditOnClickHandler(newEmptyAction)}} 
                  type="button"
                >
                  {`${t('ADD')}`}
                </button>
              )}
            </h3>
            <div className="reminder-action-table-container">
              <table className="reminder-action-table">
                <thead>
                  <tr>
                    <th className="reminder-action-fulltype">
                      <FontAwesomeIcon 
                        icon={faBook} 
                        onClick={(e) => {e.stopPropagation(); setShowTypeLegend(true);}} 
                        title={`${t('remindersAdminPage.typeLegendTitle')}`} 
                      /> {`${t('remindersAdminPage.actionsTableType')}`}
                    </th>
                    <th className="reminder-action-recipients">
                      {`${t('remindersAdminPage.actionsTableRecipients')}`}
                    </th>
                    <th className="reminder-action-model">
                      {`${t('remindersAdminPage.reminderTableMessageModel')}`}
                    </th>
                    <th className="reminder-action-actions"></th>
                  </tr>
                </thead>
                <tbody>
                  {actionsEditModal.actions.map( (action) => {
                      return actionRow(actionsEditModal, action);
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="reminder-dialog-action-buttons">
              <button 
                className="cbutton reminder-dialog-action-button cancel-button" 
                onClick={() => {closeActionsEditModal()}} 
                type="button"
              >
                {`${t('CANCEL')}`}
              </button>
              <button 
                className="cbutton reminder-dialog-action-button ok-button" 
                onClick={() => {saveActionsEditModal()}} 
                type="button"
              >
                {`${t('OK')}`}
              </button>
            </div>
          </div>        
        </>
      </Modal>);
  }

  function decodeSelectVoiceForActionType(type) {
    switch(type) {
      case 'push':
        return {value: 'push', label: `${t('remindersAdminPage.actionAppNotification')}`};
      case 'email':
        return {value: 'email', label: `${t('remindersAdminPage.actionEmail')}`};
      case 'sms':
        return {value: 'sms', label: `${t('remindersAdminPage.actionSms')}`};
      case 'device':
        return {value: 'device', label: `${t('remindersAdminPage.actionDevice')}`};
      default:
        return null;
    }    
  }

  function allowedActionTypesOptions(currentTypes) {
    const options = [
      {value: 'push', label: `${t('remindersAdminPage.actionAppNotification')}`},
      {value: 'email', label: `${t('remindersAdminPage.actionEmail')}`},
      {value: 'sms', label: `${t('remindersAdminPage.actionSms')}`},
      {value: 'device', label: `${t('remindersAdminPage.actionDevice')}`}
    ];
    return options.filter( opt => !currentTypes.includes(opt.value) );
  }

  function renderUsersCheckboxes() {
    const users = getUsersDescriptions();
    let checkboxes = [];
    let a = 0;
    users.forEach( (user, userId) => {
      checkboxes[a++] = (
        <label key={a} className="checkbox-label">
          {user}
          <input 
            data-user-id={userId} 
            defaultChecked={actionEditModal.recipients.includes(`${userId}`)} 
            onClick={ (e) => {
              const checked = e.target.checked;
              if(checked) {
                if(!actionEditModal.recipients.includes(`${userId}`)) {
                  let newActionModal = cloneDeep(actionEditModal.recipients);
                  newActionModal.push(`${userId}`);
                  setActionEditModal(cur => ({...cur, recipients: newActionModal}))
                }
              } else {
                if(actionEditModal.recipients.includes(`${userId}`)) {
                  const newActionModal = actionEditModal.recipients.filter((el) => {
                    return el !== `${userId}`;
                  });
                  setActionEditModal(cur => ({...cur, recipients: newActionModal}))
                }
              }
            }}
            type="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      );
    });
    return checkboxes;
  }

  function devicesOptions() {
    let opts = [];
    devicesState.forEach((device, deviceId) => {
      if(not_null_or_undefined(device.allowedWriteValues) && 
        (device.allowedWriteValues.type !== 0)) {
        opts.push({value: deviceId, label: `${device.name} (${device.categoryName}, ${device.roomName})`});
      }
    });
    return opts;
  }

  function decodeVoiceDeviceOption(deviceId) {
    if(devicesState.has(deviceId)) {
      const device = devicesState.get(deviceId);
      return {value: deviceId, label: `${device.name} (${device.categoryName}, ${device.roomName})`};
    }
    return null;
  }

  function multivaluesOptions(deviceId) {
    let opts = [];
    if(devicesState.has(deviceId)) {
      const device = devicesState.get(deviceId);
      if(not_null_or_undefined(device.allowedWriteValues) && 
        ((device.allowedWriteValues.type === 1) || (device.allowedWriteValues.type === 2))) {
        device.allowedWriteValues.multivalues.forEach((entry) => {
          opts.push({value: entry.value, label: `${entry.name}`});
        });
        return opts;
      }
    }
    return [{value: null, label: `???`}];
  }

  function decodeMultivaluesOptions(deviceId, value) {
    let res = null;
    if(devicesState.has(deviceId)) {
      const device = devicesState.get(deviceId);
      if(not_null_or_undefined(device.allowedWriteValues) && 
        ((device.allowedWriteValues.type === 1) || (device.allowedWriteValues.type === 2))) {
        device.allowedWriteValues.multivalues.forEach((entry) => {
          if(entry.value === value) {
            res = {value: entry.value, label: `${entry.name}`};
          }          
        });
      }
    }
    return res;
  }

  function renderSpecificRecipientsEmails() {
    const filteredRecipients = actionEditModal.recipients.filter(recipient => recipient.includes('@'));
    if(filteredRecipients.length === 0)
      return;
    return (
      <>
        <label className="reminder-dialog-full-width">{`${t('remindersAdminPage.otherEmailAddresses')}`}:</label>
        {filteredRecipients.map((recipient, index) => {
          return (
            <React.Fragment key={index}>
              <input                 
                className="reminder-dialog-input removable-list-element"
                defaultValue={recipient} 
                name={`added-email-${index}`}
                readOnly
                type="text"
              />
              <FontAwesomeIcon 
                className="remove-list-element clickable" 
                data-email={recipient} 
                icon={faMinusCircle}
                onClick={(e) => {
                  const email = e.target.dataset.email;
                  setActionEditModal( (cur) => {
                    return {...cur, recipients: actionEditModal.recipients.filter(rec => rec !== email)};
                  });
                }}
                title={`${t('remindersAdminPage.remove')}`}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  function rendersActionEditModal() {
    if(null_or_undefined(actionEditModal))
      return;
      
    return (
      <Modal
        onClose={closeActionEditModal}
        open={actionEditModal !== null}
        styles={{modal: {minHeight: '24em'}}}
      >
        <>
          <div className="action-dialog-container">
            <h3 className="reminder-dialog-title reminder-dialog-full-width">{`${t('remindersAdminPage.reminderActionInfoTitle')}`}</h3>

            <div className="reminder-dialog-full-width">
              <label className="select-label">{`${t('remindersAdminPage.actionsTableType')}`}</label>
              <div className="select-control">
                <Select
                  // className="dropdown-select"
                  // classNamePrefix="select"
                  defaultValue={decodeSelectVoiceForActionType(actionEditModal.type)}
                  isDisabled={actionEditModal.isNew !== true}
                  isSearchable={false}
                  name="action-type"
                  onChange={(option) => {setActionEditModal((action) => {
                    action.type = option.value; 
                      return {...action}})
                    }
                  }
                  options={allowedActionTypesOptions(actionsEditModal.actions.map( action => action.type ))}
                  value={decodeSelectVoiceForActionType(actionEditModal.type)}
                />
              </div>
            </div>
            {(not_null_or_undefined(actionEditModal.type) && (actionEditModal.type !== 'device')) && (
              <>
                <div className="reminder-dialog-full-width">
                  <label>{`${t('remindersAdminPage.messageModel')}`}</label>
                  <input 
                    className="reminder-dialog-input" 
                    id="message-model" 
                    name="message-model" 
                    onChange={(e) => {
                        const newMessageTemplate = e.target.value;
                        setActionEditModal( action => ({...action, messageTemplate: newMessageTemplate}))
                      }
                    }
                    type="text"
                    value={actionEditModal.messageTemplate !== null ? actionEditModal.messageTemplate : ''}
                  />
                </div>
                <div className="reminder-dialog-selection reminder-dialog-full-width" id="recipient-selection">
                  <label className="radio-label">
                    <input 
                      defaultChecked={actionEditModal.recipients === null || (actionEditModal.recipients.length > 0 || actionEditModal.recipients[0] === '*')} 
                      id="all-recipients" 
                      name="recipient-group" 
                      onClick={(e) => {
                          setActionEditModal(opt => ({...opt, recipients: ['*']}))
                        }
                      } 
                      type="radio"
                      value="*"
                    /> {`${t('All')}`}
                    <span className="checkmark-radio"></span>
                  </label>
                  <label className="radio-label">
                    <input 
                      defaultChecked={actionEditModal.recipients !== null && (actionEditModal.recipients.length === 0 || (actionEditModal.recipients.length > 0 && actionEditModal.recipients[0] !== '*'))} 
                      id="some-recipient" 
                      name="recipient-group" 
                      onClick={(e) => {
                          setActionEditModal(opt => ({...opt, recipients: []}))
                        }
                      }
                      type="radio"
                      value="0"
                    /> {`${t('remindersAdminPage.radioSelect')}`}
                    <span className="checkmark-radio"></span>
                  </label>
                </div>
              </>
            )}
            {(actionEditModal.recipients !== null && (actionEditModal.recipients.length === 0 || (actionEditModal.recipients.length > 0 && actionEditModal.recipients[0] !== '*'))) && (
              <div className="reminder-action-users-check-list reminder-dialog-full-width">
                {renderUsersCheckboxes()}
              </div>
            )}
            {(actionEditModal.type === 'device') && (
              <>
                <div>
                  <label className="select-label">{`${t('remindersAdminPage.device')}`}</label>
                  <div className="select-control">
                    <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      defaultValue={decodeSelectVoiceForActionType(actionEditModal.deviceId)}
                      isSearchable={false}
                      name="action-type"
                      onChange={(option) => {setActionEditModal((action) => {
                        action.deviceId = option.value; 
                          return {...action}})
                        }
                      }
                      options={devicesOptions()}
                      value={decodeVoiceDeviceOption(actionEditModal.deviceId)}
                    />
                  </div>
                </div>          
                <div>
                  {(actionEditModal.deviceId !== null && 
                    devicesState.has(actionEditModal.deviceId) && 
                    (devicesState.get(actionEditModal.deviceId).allowedWriteValues.type === 1 || 
                      devicesState.get(actionEditModal.deviceId).allowedWriteValues.type === 2)) && (
                    <>
                      <label>{`${t('remindersAdminPage.valueToWrite')}`}</label>
                      <div className="select-control">
                        <Select
                          // className="dropdown-select"
                          // classNamePrefix="select"
                          defaultValue={decodeMultivaluesOptions(actionEditModal.deviceId, actionEditModal.deviceValue)}
                          isSearchable={false}
                          name="action-type"
                          onChange={(option) => {setActionEditModal((action) => {
                            action.deviceValue = option.value; 
                              return {...action}})
                            }
                          }
                          options={multivaluesOptions(actionEditModal.deviceId)}
                          value={decodeMultivaluesOptions(actionEditModal.deviceId, actionEditModal.deviceValue)}
                        />
                      </div>
                    </>
                  )}
                  {(actionEditModal.deviceId !== null && 
                    devicesState.has(actionEditModal.deviceId) && 
                    (devicesState.get(actionEditModal.deviceId).allowedWriteValues.type !== 1 && 
                      devicesState.get(actionEditModal.deviceId).allowedWriteValues.type !== 2)) && (
                    <>
                      <label>{`${t('remindersAdminPage.valueToWrite')}`}</label>
                      <input 
                        className="reminder-dialog-input" 
                        defaultValue={actionEditModal.deviceValue !== null ? actionEditModal.deviceValue : ''} 
                        id="message-model" 
                        name="message-model"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setActionEditModal( (cur) => {
                            return {...cur, deviceValue: newValue}; 
                          });
                        }}
                        type="text"
                      />
                    </>
                  )}
                </div>
              </>
            )}
            {((actionEditModal.type === 'email') && (actionEditModal.recipients !== null) && 
              ((actionEditModal.recipients.length !== 1) || ((actionEditModal.recipients.length === 1) && (actionEditModal.recipients[0] !== '*')))) && (
              <div className="reminder-dialog-full-width email-address-list">
                {renderSpecificRecipientsEmails()}
                <label className="reminder-dialog-full-width">{`${t('remindersAdminPage.newEmailAddress')}`}:</label>
                <input 
                  className="reminder-dialog-input" 
                  id="email-to-add" 
                  name="email-to-add" 
                  onChange={(e) => {
                    const val = e.target.value;
                    setNewEmail(val);
                  }}
                  type="text"
                  value={newEmail}
                />
                <FontAwesomeIcon 
                  className="remove-list-element clickable" 
                  icon={faPlusCircle} 
                  onClick={() => {
                    if(newEmail === null || newEmail.length === 0 || !newEmail.includes('@'))
                      return
                    let newRecepients = actionEditModal.recipients;
                    newRecepients.push(newEmail);
                    setNewEmail('');
                    setActionEditModal( (cur) => {
                      return {...cur, recipients: newRecepients};
                    });
                  }}
                  title={`${t('add')}`}
                />
              </div>
            )}
            <p className="reminder-info-full-width"></p>
            <button 
              className="cbutton reminder-dialog-action-button cancel-button"
              id="cancel-button" 
              onClick={() => {closeActionEditModal()}} 
              type="button"
            >
              {`${t('CANCEL')}`}
            </button>
            <button 
              className="cbutton reminder-dialog-action-button ok-button"
              id="ok-button" 
              onClick={() => {saveActionEditModal()}} 
              type="button"
            >
              {`${t('OK')}`}
            </button>
          </div>
        </>
      </Modal>
    );
  }

  const asyncLoadInstReminderActions = useCallback(async function () {
    const instActionsData = await loadInstReminderActions(selectedInst);
    if(instActionsData) {
      setInstActions(instActionsData);
    } else {
      setInstActions(null);
    }
    setRequestingInstActions(false);
  }, [loadInstReminderActions, selectedInst]);

  const isInstActionsListValid = useCallback( function () {
    return (selectedInst !== null && instActions instanceof Map);
  }, [instActions, selectedInst]);

  async function actionsDeleteOnClickHandler(actionId)
  {
    const res = await deleteReminderAction(selectedInst, actionId);
    if(res === true) {
      let newInstActions = new Map();
      instActions.forEach((action) => {
        if(action.id !== actionId)
          newInstActions.set(action.id, action);
      });
      const refs = generateActionsRefs(newInstActions, listActionsOptions.orderByField, listActionsOptions.orderDirectionAsc);
      setOrderedInstActionsRefs(refs);      
      setInstActions(newInstActions);    
    } else {
      alert(res);
    }
  }

  async function actionDeleteOnClickHandler(actionToModify, specificAction)
  {
    let newAction = cloneDeep(actionToModify);
    newAction.actions = newAction.actions.filter(action => action.type !== specificAction);
    setActionsEditModal(newAction);
  }

  useEffect(() => {
    if( (selectedInst !== null) && (!(instActions instanceof Map)) && (!requestingInstActions)) {
      setRequestingInstActions(true);
      asyncLoadInstReminderActions();
    } 
  }, [selectedInst, instActions, requestingInstActions, asyncLoadInstReminderActions]);

  function getUsersDescriptions() {
    let users = new Map();
    selectedInstallationData.contactPerson.forEach( (contact) => {
      let desc = `${contact.firstName} ${contact.lastName}`;
      if(not_null_or_undefined(contact.organization))
        desc = desc + ` (${contact.organization})`;
      users.set(contact.userId, desc);
    });
    return users;
  }

  function userListToString(usersList) {
    if(usersList === null)
      return `${t('all')}`;
    if(usersList === '*')
      return `${t('all')}`;
    if(Array.isArray(usersList)) {
      if(usersList.length === 0)
        return `${t('all')}`;

      if(usersList[0] === '*')
        return `${t('all')}`;

      const users = getUsersDescriptions();
      const usersDescs = usersList.map( (user) => {
        let userInt = parseInt(user);
        if(isNaN(userInt)) {
          return user;
        }
        if(users.has(userInt)) {
            return users.get(userInt);
        } else {
          return '?';
        }
      });

      return usersDescs.join(', ');
    }
    return '?';
  }

  function actionsTypes(actions, appendName) {
    return actions.map( (action) => {
      switch(action.type) {
        case 'push':
          return (
            <React.Fragment key="push">
              <FontAwesomeIcon key={'faMobileAlt'} icon={faMobileAlt} title={`${t('remindersAdminPage.actionAppNotification')}`} />
              {(appendName) && (
                <span>{`${t('remindersAdminPage.actionAppNotification')}`}</span>
              )}
            </React.Fragment>
          );
        case 'email':
          return (
            <React.Fragment key="email">
              <FontAwesomeIcon key={'faAt'} icon={faAt} title={`${t('remindersAdminPage.actionEmail')}`} />
              {(appendName) && (
                <span>{`${t('remindersAdminPage.actionEmail')}`}</span>
              )}
            </React.Fragment>
          );
        case 'sms':
          return (
            <React.Fragment key="sms">
              <FontAwesomeIcon key={'faSms'} icon={faSms} title={`${t('remindersAdminPage.actionSms')}`} />
              {(appendName) && (
                <span>{`${t('remindersAdminPage.actionSms')}`}</span>
              )}
            </React.Fragment>
          );
        case 'device':
          return (
            <React.Fragment key="device">
              <FontAwesomeIcon key={'faLightbulb'} icon={faLightbulb} title={`${t('remindersAdminPage.actionDevice')}`} />
              {(appendName) && (
                <span>{`${t('remindersAdminPage.actionDevice')}`}</span>
              )}
            </React.Fragment>
          );
        default:
          return "";
      }
    });
  }

  function actionsRow(instActions)
  {
    return(
      <tr key={instActions.id}>
        <td className="reminder-action-label">{instActions.label}</td>
        <td className="reminder-action-number">{instActions.actions.length}</td>
        <td className="reminder-action-types">{actionsTypes(instActions.actions, false)}</td>
        <td className="reminder-action-details">
          <FontAwesomeIcon 
            className="clickable" 
            icon={faTrashAlt} 
            onClick={ () => actionsDeleteOnClickHandler(instActions.id)} 
            title={`${t('remindersAdminPage.actionsDelete')}`} 
          />
          <FontAwesomeIcon 
            className="clickable" 
            icon={faEdit} 
            onClick={ () => actionsEditOnClickHandler(instActions)} 
            title={`${t('remindersAdminPage.actionsEdit')}`} 
          />
        </td>
      </tr>
    );
  }

  const orderBy = useCallback(function (field) {
    let newOpts = {...listActionsOptions};
    if(listActionsOptions.orderByField === field) {
      newOpts.orderDirectionAsc = !listActionsOptions.orderDirectionAsc;
    } else {
      newOpts.orderByField = field;
      newOpts.orderDirectionAsc = true;
    }
    setListActionsOptions(newOpts);
  }, [listActionsOptions]);

  function generateActionsRefs(actionsList, orderByField, orderDirectionAsc) {
    let newRefs = [];
    switch(orderByField) {
      case 'label':
        actionsList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.label});
        });
      break;
      case 'number':
        actionsList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.actions.length});
        });
      break;
      case 'types':
        actionsList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.actions[0].type});
        });
      break;
      default:
    }
    newRefs.sort((a, b) => {
      if(orderDirectionAsc) {
        if(a.key < b.key)
          return -1;
        if(a.key > b.key)
          return 1;
      } else {
        if(a.key < b.key)
          return 1;
        if(a.key > b.key)
          return -1;
      }
      return 0;
    });
    return newRefs;
  }

  useEffect( () => {
    if(isInstActionsListValid()) {
      const refs = generateActionsRefs(instActions, listActionsOptions.orderByField, listActionsOptions.orderDirectionAsc);
      setOrderedInstActionsRefs(refs);
    } else {
      return setOrderedInstActionsRefs(refs => null);
    }
  }, [instActions, isInstActionsListValid, listActionsOptions.orderByField, listActionsOptions.orderDirectionAsc]);

  return (
    <>
      <HeaderSection 
        currRoute={currRoute} 
        mainContentRef={mainContentRef} 
      />
      <section className="main-content" ref={mainContentRef}>
        {selectedInstallationData && (
          <>
            {/* {((lastError.lastErrorMessage !== null) && (lastError.lastErrorMessage !== void 0)) && (
              <div className="error-banner-div">{lastError.lastErrorMessage}</div>
            )} */}
            <Alerts alerts={selectedInstallationData.alerts} installationStatus={selectedInstallationData.status} />
            <div className="main-inner-div">
              <h1 className="main-content-header">
                <span>{`${t("remindersAdminPage.actionsTitle")}`}</span>
                <button 
                  className="cbutton reminder-button reminder-add-control" 
                  id="add-button" 
                  onClick={() => {actionsEditOnClickHandler({...newEmptyInstAction})}}
                  type="button"
                >
                  {`${t('ADD')}`}
                </button>
              </h1>
              <hr/>
              {renderTypeLegendModal()}
              {rendersActionsEditModal()}
              {(orderedInstActionsRefs !== null) && isInstActionsListValid() && (
                <>
                  <div className="reminder-table-container">
                    <table className="reminder-table">
                      <thead>
                        <tr>
                          <th className="reminder-action-label" onClick={() => orderBy('label')}>
                            {`${t('remindersAdminPage.actionsTableLabel')}`}
                            {listActionsOptions.orderByField === 'label' && listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {listActionsOptions.orderByField === 'label' && !listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-action-number" onClick={() => orderBy('number')}>
                            {`${t('remindersAdminPage.actionsTableNumber')}`}
                            {listActionsOptions.orderByField === 'number' && listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {listActionsOptions.orderByField === 'number' && !listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-action-types" onClick={() => orderBy('types')}>
                            <FontAwesomeIcon 
                              icon={faBook} 
                              onClick={(e) => {e.stopPropagation(); setShowTypeLegend(true);}} 
                              title={`${t('remindersAdminPage.typeLegendTitle')}`} 
                            />
                            {`${t('remindersAdminPage.reminderTableTypes')}`}
                            {listActionsOptions.orderByField === 'types' && listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {listActionsOptions.orderByField === 'types' && !listActionsOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-action-actions"></th>
                        </tr>
                      </thead>
                      <tbody>              
                        {orderedInstActionsRefs.map( (actionRef) => {
                          if(instActions.has(actionRef.id)) {
                            return actionsRow(instActions.get(actionRef.id));
                          }
                          return null;
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="reminder-buttons">
                    <Link className="reminder-actions-control" to={SITE_LINKS.REMINDERS_ADMIN}>
                      <button 
                        className="cbutton reminder-button" 
                        id="reminder-actions-button" 
                        type="button"
                      >
                        {`${t('BACK')}`}
                      </button>
                    </Link>                    
                  </div>
                </>
              )} 
              {((orderedInstActionsRefs === null) || !isInstActionsListValid()) && (
                <p>{`${t("remindersAdminPage.loadingMessage")}`}</p>
              )} 
            </div>
          </>
        )}
      </section>
      <FooterSection/>
    </>
  )
}
