import React from 'react';

export default function MainArea(props) {
  const title = props.title;
  return (
    <div className="main-inner-div">
      <h1 className="main-content-header">
        {title}
        {props.headerAddOns}
      </h1>
      <hr/>
      <div className="info-riassuntive">
        {props.children}
      </div>
    </div>   
  );
}
