import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import MainArea from './MainArea';
import Guests from './Guests';
import Services from './Services';
import InstallationDesc from './InstallationDesc';
import { InstallationsContext, hasCapability } from './App';
// import { GenericContext } from './App';
import ActiveTile from './ActiveTile';
import ContactPersons from './ContactPersons';
import { extractFileName, not_null_or_undefined } from '../utilities';
import cloneDeep from 'lodash/cloneDeep';
import FooterSection from './FooterSection';
import StaticBanner from './StaticBanner';
import DisplayOptionModal from './DisplayOptionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import CategoryLabel from './CategoryLabel';
import { timestampToDate } from '../utilities';
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api";

export default function StatusPage({ currRoute, devicesState, installationServices, setInstallationServices }) {
  const { t, i18n } = useTranslation();
  const {
    selectedInst,
    installationGuests,
    setInstallationGuests,
    installationContacts,
    setInstallationContacts,
    installations,
    changeDeviceValue,
    requestDeviceDetails,
    loadUserDisplay,
    updateUserDisplay,
  } = React.useContext(InstallationsContext);

  // const {
  //   userType
  // } = React.useContext(GenericContext);

  const [devicesDetails, setDevicesDetails] = useState(new Map());

  let mainContentRef = React.createRef();
  const [currentStepTime, setCurrentStepTime] = useState(new Date().getTime());

  const [userDisplayOptions, setUserDisplayOptions] = useState(null);
  const [displayOptionsModal, setDisplayOptionsModal] = useState(null);
  const userCapabilities = useRef(null);

  useEffect(() => {
    if (selectedInst) {
      userCapabilities.current = installations[selectedInst]?.userCapabilities;
    } else {
      userCapabilities.current = null;
    }
  }, [installations, selectedInst]);

  async function handleDeviceDetailsRequest(deviceId, isCollapsed) {
    if (isCollapsed) {
      const details = await requestDeviceDetails(selectedInst, deviceId, false);
      let newDeviceDetails = cloneDeep(devicesDetails);
      newDeviceDetails.set(deviceId, details);
      setDevicesDetails(newDeviceDetails);
    }
  }

  function getCurrentDeviceDetails(deviceId) {
    if (!devicesDetails.has(deviceId)) return null;

    return devicesDetails.get(deviceId);
  }

  async function execUserDisplayOptions(displayOptions) {
    const options = await updateUserDisplay(selectedInst, displayOptions);
    if (not_null_or_undefined(options)) {
      setUserDisplayOptions(options);
    } else {
    }
  }

  useEffect(() => {
    const timeUpdateTimer = setInterval(() => {
      const newTime = new Date();
      setCurrentStepTime(newTime.getTime());
    }, 5000);
    return () => {
      clearInterval(timeUpdateTimer);
    };
  }, []);

  function isDeviceStateValid() {
    return selectedInst !== null && devicesState instanceof Map && devicesState.size > 0;
  }

  function onDeviceClickHandler(deviceData) {
    // console.log("onDeviceClickHandler(deviceData)", deviceData);
    if (deviceData.allowedWriteValues.type === 1 || deviceData.allowedWriteValues.type === 2) {
      if (deviceData.allowedWriteValues.multivalues.length === 1) {
        if (
          Array.isArray(deviceData.allowedWriteValues.multivalues[0].defaultForValues) === false ||
          (Array.isArray(deviceData.allowedWriteValues.multivalues[0].defaultForValues) &&
            deviceData.allowedWriteValues.multivalues[0].defaultForValues.indexOf(deviceData.value) !== -1)
        ) {
          changeDeviceValue(selectedInst, deviceData.id, deviceData.allowedWriteValues.multivalues[0].value)
            .then((esit) => {
              if (esit === true) {
                //set in on_change mode...
              }
            })
            .catch((error) => {
              alert('Error on write: ' + error);
            });
        }
      } else {
        for (const wValue of deviceData.allowedWriteValues.multivalues) {
          if (Array.isArray(wValue.defaultForValues) && wValue.defaultForValues.indexOf(parseInt(deviceData.value)) !== -1) {
            changeDeviceValue(selectedInst, deviceData.id, wValue.value)
              .then((esit) => {
                if (esit === true) {
                  //set in on_change mode...
                }
              })
              .catch((error) => {
                alert('Error on write: ' + error);
              });
            break;
          }
        }
      }
    }
  }

  function includeTimePlaceholder(message) {
    return message.indexOf('%time%') !== -1;
  }

  function decodeValueCase(valueCase) {
    switch (valueCase) {
      case 0:
        return 'normal-state';

      case 1:
        return 'active-state';

      case 2:
        return 'warning-state';

      case 3:
        return 'error-state';

      default:
        return '';
    }
  }

  const selectedInstallationData = selectedInst && installations ? installations[selectedInst] : null;

  function findDeviceCategory(categorizedDeviceData, categoryLabel) {
    for (let a = 0; a < categorizedDeviceData.length; ++a) {
      if (categorizedDeviceData[a].label === categoryLabel) {
        return categorizedDeviceData[a];
      }
    }
    const idx = categorizedDeviceData.push({ label: categoryLabel, devices: [] }) - 1;
    return categorizedDeviceData[idx];
  }

  let categorizedDeviceData = [];
  //Structure the data...
  if (not_null_or_undefined(userDisplayOptions)) {
    categorizedDeviceData.push({ label: '$uncategorized$', devices: [] });
    if (userDisplayOptions.showSafetySensors === 'separated') {
      categorizedDeviceData.push({ label: '$safety$', devices: [] });
    }

    if (isDeviceStateValid()) {
      let categoryClustering = null;
      let categoryClusteringOrdering = 'asc';
      if (userDisplayOptions.orderingCriteria.length > 0) {
        categoryClustering = userDisplayOptions.orderingCriteria[0].criterionName;
        categoryClusteringOrdering = userDisplayOptions.orderingCriteria[0].ordering;
      }

      devicesState.forEach((value, key) => {
        if (userDisplayOptions.showSafetySensors === 'separated' && value.isSafety) {
          let cat = findDeviceCategory(categorizedDeviceData, '$safety$');
          cat.devices.push({ type: 0, ref: key });
        } else if (
          userDisplayOptions.showSafetySensors !== 'false' ||
          (userDisplayOptions.showSafetySensors === 'false' && !value.isSafety)
        ) {
          let cat = null;
          switch (categoryClustering) {
            case 'name':
            case 'last-change':
            case 'default':
            case 'events':
            case 'alerts':
            default:
              cat = findDeviceCategory(categorizedDeviceData, '$uncategorized$');
              cat.devices.push({ type: 0, ref: key });
              break;

            case 'category':
              cat = findDeviceCategory(categorizedDeviceData, value.categoryName);
              cat.devices.push({ type: 0, ref: key });
              break;

            case 'room':
              cat = findDeviceCategory(categorizedDeviceData, value.roomName);
              cat.devices.push({ type: 0, ref: key });
              break;
          }
        }
      });

      //Ordercategories
      categorizedDeviceData.sort((a, b) => {
        if (a.label === '$safety$') return 1;
        if (b.label === '$safety$') return -1;

        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (categoryClusteringOrdering === 'asc') {
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
        } else {
          if (labelA < labelB) {
            return 1;
          }
          if (labelA > labelB) {
            return -1;
          }
        }
        return 0;
      });

      //Default and residual ordering is by name
      categorizedDeviceData.forEach((cat) => {
        cat.devices.sort((a, b) => {
          const valA = devicesState.get(a.ref).name.toUpperCase();
          const valB = devicesState.get(b.ref).name.toUpperCase();
          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          return 0;
        });
      });

      //Order in categories
      const first = userDisplayOptions.orderingCriteria.length - 1;
      const last = categoryClustering === 'category' || categoryClustering === 'room' ? 1 : 0;
      for (let idx = first; idx >= last; --idx) {
        const criterion = userDisplayOptions.orderingCriteria[idx].criterionName;
        const criterionOrg = userDisplayOptions.orderingCriteria[idx].ordering;

        const invert = criterionOrg === 'asc' ? 1 : -1;

        categorizedDeviceData.forEach((cat) => {
          cat.devices.sort((a, b) => {
            let valA = devicesState.get(a.ref).displayPriority;
            let valB = devicesState.get(b.ref).displayPriority;
            switch (criterion) {
              case 'category':
                valA = devicesState.get(a.ref).categoryName.toUpperCase();
                valB = devicesState.get(b.ref).categoryName.toUpperCase();
                break;
              case 'room':
                valA = devicesState.get(a.ref).roomName.toUpperCase();
                valB = devicesState.get(b.ref).roomName.toUpperCase();
                break;
              case 'name':
                valA = devicesState.get(a.ref).name.toUpperCase();
                valB = devicesState.get(b.ref).name.toUpperCase();
                break;
              case 'events':
                const aHasNewEvents = devicesState.get(a.ref).eventsNew;
                const bHasNewEvents = devicesState.get(b.ref).eventsNew;
                if (aHasNewEvents === false && bHasNewEvents === true) return -1 * invert;
                if (aHasNewEvents === true && bHasNewEvents === false) return 1 * invert;
                return 0;
              case 'alerts':
                if (
                  not_null_or_undefined(devicesState.get(a.ref).timeThresholds) ||
                  not_null_or_undefined(
                    devicesState.get(b.ref).timeThresholds ||
                      devicesState.get(a.ref).status === 'error' ||
                      devicesState.get(b.ref).status === 'error'
                  )
                ) {
                  let detailsTimeA = includeTimePlaceholder(devicesState.get(a.ref).stateDesc)
                    ? parseInt(devicesState.get(a.ref).lastStateChange) * 1000
                    : null;
                  let aIsAlerted = false;
                  if (detailsTimeA !== null && not_null_or_undefined(devicesState.get(a.ref).timeThresholds)) {
                    aIsAlerted =
                      devicesState.get(a.ref).timeThresholds[0].threshold * 1000 < currentStepTime - detailsTimeA;
                  }
                  if (devicesState.get(a.ref).status === 'error') {
                    aIsAlerted = true;
                  }

                  let detailsTimeB = includeTimePlaceholder(devicesState.get(b.ref).stateDesc)
                    ? parseInt(devicesState.get(b.ref).lastStateChange) * 1000
                    : null;
                  let bIsAlerted = false;
                  if (detailsTimeB !== null && not_null_or_undefined(devicesState.get(b.ref).timeThresholds)) {
                    bIsAlerted =
                      devicesState.get(b.ref).timeThresholds[0].threshold * 1000 < currentStepTime - detailsTimeB;
                  }
                  if (devicesState.get(b.ref).status === 'error') {
                    bIsAlerted = true;
                  }
                  if (aIsAlerted === false && bIsAlerted === true) return 1 * invert;
                  if (aIsAlerted === true && bIsAlerted === false) return -1 * invert;
                }
                return 0;
              case 'last-change':
                valA =
                  currentStepTime - includeTimePlaceholder(devicesState.get(a.ref).stateDesc)
                    ? parseInt(devicesState.get(a.ref).lastStateChange) * 1000
                    : 0;
                valB =
                  currentStepTime - includeTimePlaceholder(devicesState.get(b.ref).stateDesc)
                    ? parseInt(devicesState.get(b.ref).lastStateChange) * 1000
                    : 0;
                break;

              case 'default':
              default:
                break;
            }
            if (valA < valB) {
              return -1 * invert;
            }
            if (valA > valB) {
              return 1 * invert;
            }
            return 0;
          });
        });
      }
    }
  }

  useEffect(() => {
    async function asyncLoadUserDisplayOptions(instSerial) {
      const options = await loadUserDisplay(instSerial);
      if (not_null_or_undefined(options)) setUserDisplayOptions(options);
    }
    if (selectedInst !== null) {
      asyncLoadUserDisplayOptions(selectedInst);
    }
  }, [loadUserDisplay, selectedInst]);

  return (
    <>
      <HeaderSection currRoute={currRoute} mainContentRef={mainContentRef} />
      <section className='main-content' ref={mainContentRef} style={{ lineHeight: 1 }}>
        {selectedInstallationData && (
          <>
            {not_null_or_undefined(displayOptionsModal) && (
              <DisplayOptionModal
                displayOptions={displayOptionsModal}
                setDisplayOptions={setDisplayOptionsModal}
                updateDisplayOptions={execUserDisplayOptions}
              />
            )}
            {selectedInstallationData.status === INSTALLATION_ALERT_STATUS_DISCONNECTED && (
              <StaticBanner
                backgroundColor='#d9534f'
                color='white'
                icon='exclamation-triangle'
                message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(
                  selectedInstallationData.lastStatusUpdate
                ).toLocaleString(i18n.language)}`}
              />
            )}
            {hasCapability(selectedInstallationData, 'alerts-show') && (
              <Alerts
                alerts={selectedInstallationData.alerts}
                canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')}
                installationStatus={selectedInstallationData.status}
              />
            )}
            <InstallationDesc
              canEditInstInfo={hasCapability(selectedInstallationData, 'inst-info-edit')}
              canManageAlerts={hasCapability(selectedInstallationData, 'alerts-management')}
              canViewAlerts={hasCapability(selectedInstallationData, 'alerts-show')}
              installationData={selectedInstallationData}
              persons={selectedInstallationData.contactPerson}
            />
            {/* <Guests guests={installationGuests} standalone={true}/> */}
            <Guests
              allowModify={hasCapability(selectedInstallationData, 'inst-info-edit')}
              guests={installationGuests}
              setGuests={setInstallationGuests}
              standalone={false}
            />
            <ContactPersons
                allowModify={hasCapability(selectedInstallationData, 'inst-info-edit')}
                defaultOpen={true}
                persons={installationContacts}
                setPersons={setInstallationContacts}
                standalone={true}/>
            <MainArea
              headerAddOns={
                <span
                  className='options-menu-icon'
                  onClick={() => setDisplayOptionsModal(cloneDeep(userDisplayOptions))}
                >
                  <FontAwesomeIcon className='options-menu-icon' icon={faEllipsisV} />
                </span>
              }
              title={`${t('statusPage.title')}`}
            >
              {isDeviceStateValid() && not_null_or_undefined(userDisplayOptions) && (
                <>
                  {categorizedDeviceData.map((category, index) => {
                    let auxDescCreator = (showDeviceAssignation, dd, guests) => {
                      const guestId = dd?.customAttrib?.guestId;
                      if(showDeviceAssignation && guests instanceof Map && guestId) {
                        let separator = "";
                        if (dd.optionalDesc) {
                          separator = " - "
                        }
                        let guest = guests.get(guestId);
                        let guestStr = "?user?"
                        if(guest) {
                          guestStr = guest.name + " " + guest.surname;
                        }
                        return dd.optionalDesc + separator + guestStr;
                      }
                      return dd.optionalDesc;
                    }
                    return (
                      <React.Fragment key={index}>
                        <CategoryLabel
                          defaultClosed={false}
                          devices={category.devices.map((value, idx) => {
                            const deviceData = devicesState.get(value.ref);
                            const detailRequestHandler = userCapabilities.current?.includes('logs')
                              ? (id, isCollapsed) => {
                                  handleDeviceDetailsRequest(id, isCollapsed);
                                }
                              : (id, isCollapsed) => {};
                            const statisticsType = userCapabilities.current?.includes('logs')
                              ? (deviceData.noGraph ? 0 : null) : 0;
                            return (
                              <ActiveTile
                                key={deviceData.id}
                                badgeNumber={deviceData.detailAvailable ? deviceData.eventsNumber : null}
                                currentTime={currentStepTime}
                                dataUnit={deviceData.valueUnit}
                                descType={''}
                                details={deviceData.stateDesc}
                                detailsTime={parseInt(deviceData.lastStateChange) * 1000}
                                iconName={extractFileName(deviceData.iconURL)}
                                isUnread={deviceData.eventsNew}
                                moreDetails={auxDescCreator(userDisplayOptions.showDeviceAssignation, deviceData, installationGuests)}
                                onBadgeClickHandler={detailRequestHandler}
                                onClickHandler={
                                  typeof deviceData.allowedWriteValues !== 'undefined'
                                    ? () => onDeviceClickHandler(deviceData)
                                    : null
                                }
                                showSpinner={deviceData.status === 'on_change'}
                                showWarning={deviceData.status === 'error'}
                                statisticsData={getCurrentDeviceDetails(deviceData.id)}
                                statisticsType={statisticsType}
                                tileId={deviceData.id}
                                tileState={decodeValueCase(deviceData.valueCase)}
                                timeThresholds={
                                  not_null_or_undefined(deviceData.timeThresholds)
                                    ? deviceData.timeThresholds.map(val => ({
                                        threshold: val.threshold * 1000,
                                        backgroundColor: val.backgroundColor,
                                      }))
                                    : []
                                }
                                title={deviceData.name}
                              />
                            );
                          })}
                          label={category.label}
                          showLabel={userDisplayOptions.showStatusCategories}
                        />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              {!isDeviceStateValid() && selectedInst[0] !== '_' && <p>{`${t('statusPage.loadingMessage')}`}</p>}
              {!isDeviceStateValid() && selectedInst[0] === '_' && <p>-</p>}
              {hasCapability(selectedInstallationData, 'services-show') && (
                <Services
                  allowModify={hasCapability(selectedInstallationData, 'services-edit')}
                  installationServices={installationServices}
                  setInstallationServices={setInstallationServices}
                  standalone={false}
                />
              )}
            </MainArea>
          </>
        )}
      </section>
      <FooterSection />
    </>
  );
}
