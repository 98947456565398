import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

export default function FooterSection({login}) {
  const {t/*, i18n*/} = useTranslation();  
  return (
    <div className={(login === true) ? "login-footer" : "footer"}>
      <p>
        <span className="copyright">WiMonitor &copy; 2019-{new Date().getFullYear()}</span>
        <span className="cookies">
          {(process.env.REACT_APP_COOKIE_LINK.charAt(0) === '/') && (
            <a href={process.env.REACT_APP_COOKIE_LINK} title={t('footer.cookiesInformation')}>
              Cookies <FontAwesomeIcon icon={ faExternalLinkAlt }/>
            </a>
          )}
          {(process.env.REACT_APP_COOKIE_LINK.charAt(0) !== '/') && (
            <a href={process.env.REACT_APP_COOKIE_LINK} rel="noopener noreferrer" target="_blank" title={t('footer.cookiesInformation')}>
              Cookies <FontAwesomeIcon icon={ faExternalLinkAlt }/>
            </a>
          )}
        </span>
        <span className="privacy">
          {(process.env.REACT_APP_PRIVACY_LINK.charAt(0) === '/') && (
            <a href={process.env.REACT_APP_PRIVACY_LINK} title={t('footer.privacyInformation')}>
              Privacy <FontAwesomeIcon icon={faExternalLinkAlt}/>
            </a>
          )}
          {(process.env.REACT_APP_PRIVACY_LINK.charAt(0) !== '/') && (
            <a href={process.env.REACT_APP_PRIVACY_LINK} rel="noopener noreferrer" target="_blank" title={t('footer.privacyInformation')}>
              Privacy <FontAwesomeIcon icon={faExternalLinkAlt}/>
            </a>
          )}
        </span>
        <span className="version">ver. {process.env.REACT_APP_VERSION}</span>
      </p>
    </div>
  )
}
