import React from 'react';
// import { useTranslation } from "react-i18next";

import "../css/cookies.css";

export default function Cookies(props) {
  // const {t/*, i18n*/} = useTranslation();

  const { history } = props;

  return (
    <div className="cookies-info">
      <h1>Informativa sull’uso dei cookie (Cookie Policy)</h1>
      <p>Wimonitor s.r.l. con sede in Via Per Marco 12/E, 38068 Rovereto (TN), Italia, Titolare del trattamento dei dati personali, rende la seguente informativa
        in merito all’uso dei cookie sul proprio sito internet, ai sensi del provvedimento nr. 229 dell’8 maggio 2014 del Garante per la Protezione dei Dati 
        Personali (pubblicato sulla Gazzetta Ufficiale n. 126 del 3 giugno 2014), dell’art. 13 del D.Lgs. n. 196/2003 (d’ora in poi “CP”) e dell’art. 13 
        Regolamento (UE) 2016/679 – “Regolamento generale sulla protezione dei dati” (d’ora in poi “RGPD”). Si precisa che tutte le altre informazioni 
        richieste dall’art. 13 del CP e dell’art. 13 del RGPD sono contenute nella privacy policy, reperibile cliccando&nbsp;
        {(process.env.REACT_APP_PRIVACY_LINK.charAt(0) === '/') && (
          <a href={process.env.REACT_APP_PRIVACY_LINK}>QUI</a>
        )}
        {(process.env.REACT_APP_PRIVACY_LINK.charAt(0) !== '/') && (
          <a href={process.env.REACT_APP_PRIVACY_LINK} rel="noopener noreferrer" target="_blank">QUI</a>
        )}
        .<br />
        La presente informativa si applica al sito web <a href="http://www.wimonitor.cloud/" rel="noopener noreferrer" target="_blank">www.wimonitor.cloud</a><br /></p>
      <h2>Che cos’è un Cookie</h2>
      <p>
        I cookie sono stringhe di testo inviate da un web server (per es. il sito) al browser Internet dell’utente, da quest’ultimo memorizzati automaticamente 
        sul computer dell’utente e rinviati automaticamente al server a ogni occorrenza o successivo accesso al sito. Ogni cookie contiene diversi dati come, ad 
        esempio, il nome del server da cui proviene, un identificatore numerico, ecc. I cookie possono rimanere nel sistema per la durata di una sessione (cioè 
        fino a che non si chiude il browser utilizzato per la navigazione sul web) o per lunghi periodi e possono contenere un codice identificativo unico. Per 
        impostazione iniziale (default) quasi tutti i browser web sono impostati per accettare automaticamente i cookie, ma ogni browser Web consente di limitare 
        ed eliminare i cookie (per maggiori informazioni consultare la sezione della presente informativa relativa a “Come disattivare, attivare o eliminare i 
        cookie”). Ricordiamo, tuttavia, che la disabilitazione o la cancellazione dei cookie preclude la fruizione del sito.<br />
      </p>
      <h2>Tipologie di Cookie utilizzati da Wimonitor</h2>
      <p>Il sito di Wimonitor utilizza le seguenti categorie di cookie:<br />- Cookie tecnici: i cookie tecnici sono quelli utilizzati al solo fine di effettuare 
        la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della 
        società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio. Questi possono essere suddivisi in: cookie di 
        navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web, cookie analitici, assimilati ai cookie tecnici laddove 
        utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il 
        sito stesso, oltre che cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati al fine di 
        migliorare il servizio reso allo stesso.
      </p>
      <h2>Come disattivare, attivare o eliminare i cookie</h2>
      <p>Per default quasi tutti i browser web sono impostati per accettare automaticamente i cookie i quali possono essere limitati o bloccati mediante le 
        impostazioni del browser. 
        Il sito web NON può funzionare se non sono attivati i cookies, quindi se non intendete autorizzare l'utilizzo degli stessi dovete abbandonare il sito.
        Per maggiori informazioni sulla gestione dei cookie, riportiamo i link relativi ai browser più utilizzati:<br />
        Internet Explorer™: <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies" rel="noopener noreferrer" target="_blank">https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies</a><br />
        Safari™: <a href="http://www.apple.com/it/support/" rel="noopener noreferrer" target="_blank">http://www.apple.com/it/support/</a><br />
        Chrome™: <a href="https://support.google.com/chrome/answer/95647?hl=it&amp;hlrm=en" rel="noopener noreferrer" target="_blank">https://support.google.com/chrome/answer/95647?hl=it&amp;hlrm=en</a><br />
        Firefox™: <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" rel="noopener noreferrer" target="_blank">http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie</a><br />
        Opera™: <a href="http://help.opera.com/Windows/10.20/it/cookies.html" rel="noopener noreferrer" target="_blank">http://help.opera.com/Windows/10.20/it/cookies.html</a><br />
      </p>
      <button className="cbutton" onClick={ history.goBack }>Chiudi</button>
    </div>
  );
}