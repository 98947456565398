import React from 'react';

const useDebounceCallback = (delay = 100, cleaning = true) => { // or: delayed debounce callback
  const ref = React.useRef();
  React.useEffect(() => {
    if (cleaning) {
      // cleaning uncalled delayed callback with component destroying
      return () => {
        if (ref.current) clearTimeout(ref.current);
      };
    }
  }, []);
  return (callback, ...params) => {
    if (ref.current) clearTimeout(ref.current);
    ref.current = setTimeout(callback, delay, ...params);
  };
};

export default useDebounceCallback;