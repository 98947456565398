import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//import Backend from 'i18next-xhr-backend';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
//  .use(Backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    // debug: true,

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      useSuspense: true
    },

    backend: {
      // loadPath: '/locales/{{lng}}/{{ns}}.json'
      // loadPath: () => {
      //   return `${process.env.REACT_APP_SITE_ROOT}locales/{{lng}}/{{ns}}.json`;
      // }
      loadPath: `${process.env.REACT_APP_SITE_ROOT}locales/{{lng}}/{{ns}}.json`
    },

    supportedLngs: ['it', 'en', 'de', 'fr'],
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
  });

export default i18next;