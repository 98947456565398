import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './components/App';
import {initializeFirebase, receivePushMessage} from './push-notification';
import {BrowserRouter as Router} from 'react-router-dom';
import Bootstrap from "./components/Bootstrap";
import {ErrorBoundary} from "react-error-boundary";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

ReactDOM.render(
    <Router basename={process.env.REACT_APP_SITE_ROOT}>
      <React.StrictMode>
        <ErrorBoundary fallback={<div>React Error</div>}>
          <Suspense fallback={<Bootstrap/>}>
            <DevSupport ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
            >
              <App/>
            </DevSupport>
          </Suspense>
        </ErrorBoundary>
      </React.StrictMode>
    </Router>,
    document.getElementById('root')
);

export const firebaseApp = initializeFirebase();

receivePushMessage();