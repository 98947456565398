import React from 'react';
// import { useTranslation } from "react-i18next";

import "../css/privacy.css";

export default function Privacy(props) {
  // const {t/*, i18n*/} = useTranslation();

  const { history } = props;

  return (
    <div className="privacy-info">
      <h1>Informativa Privacy sul trattamento dei dati (Art. 13 e 14 Regolamento UE GDPR 2016/679)</h1>
      <p>In questo documento si descrivono le modalità di gestione in riferimento al trattamento dei dati personali degli utenti che usufruiscono dei 
        servizi resi dall’azienda per conto del Committente. Si tratta di un'informativa che è resa anche ai sensi REGOLAMENTO (UE) 2016/679 DEL 
        PARLAMENTO EUROPEO E DEL CONSIGLIO (a seguito Reg. GDPR EU 679/2016) del 27 aprile 2016, pienamente operativa dalla data del 25 maggio 2018, 
        emanata in Italia dal Dlgs. 101/2018 del 10 Agosto 2018 e pienamente operativa dal 19 settembre 2018.</p>
      <h2>IL TITOLARE DEL TRATTAMENTO</h2>
      <p>A seguito della volontà del cliente di aderire ad uno dei servizi offerti dall’azienda, possono essere trattati dati relativi a persone 
        identificate o identificabili.</p>
      <p>
        Il Titolare del Trattamento è <strong>WIMONITOR SRL</strong> con Sede Legale in VIA PER MARCO, 12/E - 38068 ROVERETO (TN) <strong>legalmente
        rappresentato dai Sigg. GROSSI FERDINANDO e LOSARDO AGOSTINO,</strong> attualmente in carica, ai quali sono stati affidati
        i compiti di controllo attraverso i reparti interni dei servizi previsti dal
        contratto con il Committente e di seguito riassunti:
      </p>
      <ul>
        <li>
          Richiesta informazioni dell'interessato;
        </li>
        <li>
          Preventivo fornitura di beni e/o servizi (anche per i servizi di/e parte Terza);
        </li>
        <li>
          Fornitura del bene e/o servizio (anche per i servizi di/e parte Terza)
        </li>
        <li>
          Reclamo e contestazione;
        </li>
        <li>
          Comunicazione di servizio da parte degli operatori tecnici, commerciali e amministrativi (anche per i servizi di/e parte Terza) tramite 
          mezzi di comunicazione esistenti;
        </li>
        <li>
          Attività di raccolta e memorizzazione, laddove espressamente necessario e richiesto dalla tipologia di attività richiesta dal Committente, 
          anche di Dati Personali Sensibili e Biomedici, questi ultimi definiti anche come Dati Particolari, di informazioni sull’attività che viene 
          svolta in locali pubblici e/o privati, quali ambienti socio-assistenziali, sanitari - ospedalieri e domestici, dove una volta monitorati 
          servono a verificare le attività conseguenti ai nostri servizi di vigilanza e controllo, monitoraggio puntuale e reportistica periodica di 
          rilevazioni delle funzioni redatte sempre su base contrattuale con il Committente;
        </li>
        <li>
          Attività di ricerca, analisi e ricezione di Bandi economici provenienti da Enti per erogazioni alle aziende di sovvenzioni o proventi anche 
          di carattere fiscale e attività di ricerca e preventivazione su erogazioni di Noleggio Operativo Strumentale o di carattere finanziario;
        </li>
        <li>
          Attività connesse alla gestione dei fornitori dell'azienda e dei contratti di fornitura rivolti ai clienti;
        </li>
        <li>
          Attività connesse alla gestione delle Risorse Umane dell’azienda e processo interno dei passaggi dei dipendenti, anche di candidati previa 
          ricezione del Curriculm Vitae;
        </li>
        <li>
          Attività di comunicazione commerciale: i Dati Personali verranno trattati per le seguenti finalità di marketing e <strong>non di profilazione</strong>: 
          Inviare comunicazioni commerciali e/o materiale informativo per mezzo di Newsletter e/o pubblicitario su servizi e/o invito a eventi anche di 
          carattere formativo e workshop e/o rilevazioni del grado di soddisfazione sulla qualità dei servizi.
        </li>
      </ul>
      <h2>LUOGO DI TRATTAMENTO DEI DATI</h2>
      <p>
        I trattamenti connessi ai servizi hanno luogo presso delle Sedi decise dal Titolare del Trattamento sulla base dell’attività svolta, per 
        migliorare il servizio fornito e una più curata e attenta mitigazione dei Rischi connessi, e sono curati solo da personale tecnico degli 
        uffici incaricati del trattamento. In caso di necessità, i dati connessi al servizio possono essere trattati dal personale della società 
        attraverso l'uso del gestionale interno o in cloud anche in modalità mobile.
      </p>
      <h2>TIPI DI DATI TRATTATI</h2>
      <h3>Dati forniti volontariamente dall'utente</h3>
      <p>
        La trasmissione, la comunicazione e/o l'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati dall'azienda 
        attraverso il sito web <strong>www.wimonitor.it</strong> e piattaforme inerenti, tra cui <strong>www.wimonitor.cloud</strong>, comporta la 
        successiva acquisizione dell'indirizzo del mittente nonché dei dati dell’utente necessari per rispondere alle richieste.
      </p>
      <h2>EVENTUALI DESTINATARI DEI DATI</h2>
      <p>
        Senza la necessità di un espresso consenso (art. 6 lett. b e c) del Reg. UE 2016/679 il Titolare potrà comunicare i Dati per le finalità 
        di cui all’art. 2 del Reg. UE 2016/679 a: Organismi di vigilanza e di qualità, autorità giudiziarie, nonché a quei soggetti ai quali la 
        comunicazione sia obbligatoria per Legge per l’espletamento delle finalità espresse.
      </p>
      <p>Detti soggetti tratteranno i Dati nella loro qualità di autonomi titolari del trattamento.</p>
      <p>I Dati potranno essere resi accessibili per le finalità di cui sopra a dipendenti e collaboratori del Titolare o delle Società correlate a <strong>
        WIMONITOR SRL</strong> in Italia e all’Estero, nella loro qualità di incaricati e/o responsabili interni del trattamento e/o amministratori 
        di sistema; a società terze o altri soggetti che svolgono attività di outsourcing per conto del Titolare, nella loro qualità di responsabili 
        esterni del trattamento, quali Studi Commercialisti abilitati e Studi Legali riconosciuti.
      </p>
      <h2>NATURA OBBLIGATORIA E CONFERIMENTO DEI DATI</h2>
      <p>
        Il conferimento dei dati e il relativo trattamento sono obbligatori in relazione alle finalità sopra riportate relative; ne consegue che 
        l’eventuale rifiuto a fornire i dati per tali finalità potrà determinare l’impossibilità del titolare del trattamento a dar corso ai rapporti 
        professionali medesimi e agli obblighi di Legge.<br /> Il conferimento dei dati e il relativo trattamento è da ritenersi facoltativo negli 
        altri casi, senza alcuna conseguenza.
      </p>
      <h2>MODALITA' DEL TRATTAMENTO</h2>
      <p>
        Nello specifico interesse tra le Parti, i Dati necessari sono trattati con strumenti da noi nominati e definiti come “prodotto”, che posso 
        essere anche automatizzati, dove per loro natura e composizione è stata definita internamente una procedura di Accountability (Artt. 24 e 25) 
        così come richiesto dal Reg. EU 679/2016, per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche 
        misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
      </p>
      <p>
        <strong>WiMonitor Srl, qualora Nominato Responsabile del Trattamento, si impegna a far rispettare tali modalità operative e segnalare le anomalie nei 
        tempi prescritti dalla Legge.</strong>
      </p>
      <h2>DURATA DEL TRATTAMENTO</h2>
      <p>
        Al termine della prestazione o della fornitura del servizio ed in ogni caso per il tempo necessario per adempiere alle finalità di cui sopra, 
        i dati personali <strong>saranno conservati ano</strong><strong>nimi esclusivamente per scopi fiscali, storici o statistici</strong>, in 
        conformità alla legge, ai regolamenti, alla normativa comunitaria e ai codici di deontologia e di buona condotta sottoscritti ai sensi dell’articolo 
        40 del Reg. UE 2016/679.
      </p>
      <p>
        Alla luce di tale principio, i Suoi dati personali saranno trattati dal Titolare del Trattamento limitatamente a quanto necessario per il 
        perseguimento della finalità di cui alla presente informativa. In particolare, i suoi dati personali saranno trattati per un periodo di tempo 
        pari al minimo necessario.
      </p>
      <p>
        <strong>Per “durata del Trattamento” si intende il periodo che intercorre nell’uso del Dato per l’attività legittimata da WiMonitor Srl nel 
        rapporto contrattuale specifico e sottoscritto con il proprio Cliente come da Considerando 39 e 65 Art. 99 Reg. GDPR EU 679/2016, sia in qualità 
        di Titolare del Trattamento, sia in qualità di Responsabile del Trattamento.</strong>
      </p>
      <h2>DIRITTO DEGLI INTERESSATI</h2>
      <p>
        In relazione ai predetti trattamenti, l’interessato ha il diritto di chiedere l’accesso ai suoi Dati Personali, Sensibili “Particolari” (Art. 15) 
        e la rettifica (Art. 16) o la cancellazione degli stessi (Art. 17) o la limitazione del trattamento che lo riguardano (Art. 18) o di opporsi al
        loro trattamento (Art. 21), oltre ad avere il diritto alla portabilità dei Dati (Art. 20).
      </p>
      <p>
        Se il trattamento si basa sul consenso, il titolare informa l’interessato che ha il diritto di revocarlo in qualsiasi momento senza pregiudicare 
        la liceità del trattamento basata sul consenso prestato prima della revoca (Art. 20).
      </p>
      <h2>DIRITTO DI PROPORRE RECLAMO</h2>
      <p>
        Ai sensi dell'Articolo 77 Regolamento UE 2016/679 il Titolare informa l’interessato che ha il diritto di proporre reclamo al Garante per la 
        Protezione dei Dati Personali in quanto autorità amministrativa indipendente istituita dalla legge n. 675 del 31 dicembre 1996, per assicurare 
        la tutela dei diritti e delle libertà fondamentali e il rispetto della dignità nel trattamento dei dati personali sita in Piazza Venezia, 11, 
        Scala B - 00186 Roma.
      </p>
      <p>
        <strong>WIMONITOR SRL mette a disposizione per comunicare la facoltà di esercitare questi diritti attraverso l'indirizzo di Posta elettronica 
        </strong> <a href="mailto:privacy@wimonitor.it"> <strong>privacy@wimonitor.it</strong></a> <strong>o all'indirizzo postale Via Per Marco, 12/E - 38068
        Rovereto (TN).</strong><br /><br />
      </p>
      <p style={{textAlign: 'center'}}>
        <strong>
          <a 
            href="https://www.wimonitor.it/wimonitor/images/pdf/WiMonitor_Informativa_Rev1_310521.pdf" 
            rel="noopener noreferrer" 
            target="_blank" 
            title="Informativa Privacy" 
          >Scarica informativa in PDF</a><br />
          <a 
            href="https://www.wimonitor.it/wimonitor/images/pdf/WiMonitorSrl_Consenso_Personali_Rev1_310521.pdf" 
            rel="noopener noreferrer" 
            target="_blank" 
            title="Consenso dati personali"
          >Scarica il modulo consenso dati personali</a><br />
          <a 
            href="https://www.wimonitor.it/wimonitor/images/pdf/WiMonitorSrl_Consenso_Personali_Sensibili_Rev1_310521.pdf" 
            rel="noopener noreferrer" 
            target="_blank" 
            title="Informativa Privacy"
          >Scarica il modulo consenso dati sensibili</a>
        </strong>
      </p>
      <button className="cbutton" onClick={ history.goBack }>Chiudi</button>
    </div>
  );
}