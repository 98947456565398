import React from "react";
import { useTranslation } from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";

export default function HelpButton({message, title = "Information", customClassIconClass, customClassHelpMessageUi, customClassButtonDiv}) {
  const { t } = useTranslation();

  const iconClass = customClassIconClass ?? 'title-help-icon';
  const helpMessageUiClass = customClassHelpMessageUi ?? 'help-message-custom-ui';
  const buttonDivClass = customClassButtonDiv ?? 'help-message-custom-ui-buttons';

  return (
      <span className={iconClass} onClick={() => confirmAlert({
        customUI: ({ onClose }) => {
          return (
              <div className={helpMessageUiClass}>
                <h1>{`${t(title)}`}</h1>
                <p style={{whiteSpace: "pre-wrap"}}>{`${t(message)}`}</p>
                <div className={buttonDivClass}>
                  <button onClick={onClose}>{`${t('OK')}`}</button>
                </div>
              </div>
          );}
      })}
      >
        <FontAwesomeIcon icon={faInfoCircle}/>
      </span>
  );
}
