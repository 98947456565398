import React, {useState, useCallback, Fragment} from 'react';
import InstallationLinkElement from './InstallationLinkElement';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { InstallationsContext } from './App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

export default function LinksEditInterface({type, setType, links, setLinks}) {
    const {t} = useTranslation();

    const {
        selectedInst,
        requestInstallationLinks,
        updateInstallationLinks
    } = React.useContext(InstallationsContext);

    const [installationLinks, setInstallationLinks] = useState(cloneDeep(links) ?? []);

    function verifyLinkNameUniqueness() {
        if(installationLinks.length < 2)
            return true;
        if(installationLinks.length === 2) {
            return installationLinks[0].linkName !== installationLinks[1].linkName;
        }
        for(let x = 0; x < installationLinks.length; ++x) {
            for(let y = x + 1; y < installationLinks.length; ++y) {
                if(installationLinks[x].linkName === installationLinks[y].linkName)
                    return false;
            }
        }
        return true;
    }

    function handleAddNewLink() {
        // setType('create');
        setInstallationLinks( (cur) => {
            const newLinks = [...cur, {"linkName": "-- specifie name --", "description": "-- specify description --", "url": "-- specify url --"}];
            return newLinks;
        });
    }

    function handleUploadNewFile() {
        // setType('create');
        setInstallationLinks( (cur) => {
            const newLinks = [...cur, {"linkName": "-- specifie name --", "description": "-- specify description --", "url": "file:///"}];
            return newLinks;
        });
    }

    const loadInstallationLinks = useCallback(() =>
    {
        return requestInstallationLinks(selectedInst)
            .then((retrievedLinks) => {
                setInstallationLinks(retrievedLinks);
                setLinks(retrievedLinks);
            });
    }, [requestInstallationLinks, selectedInst, setLinks]);

    //Journal Modal
    function closeLinksEditModal() {
        setType('closed');
    }

    const requestInstallationLinksUpdate = useCallback(() => {
        updateInstallationLinks(selectedInst, installationLinks)
            .then(() => {
                return loadInstallationLinks();
            });
    }, [updateInstallationLinks, loadInstallationLinks, installationLinks, selectedInst]);

    return (
        <Modal
            onClose={closeLinksEditModal}
            open={type !== 'closed'}
            styles={{modal: {minWidth: 'min(80%, 900px)', maxHeight: '90vh'}}}
        >
            {
                installationLinks !== null && type === 'edit' && (
                    <div className="edit-links-modal-container">
                        <h1 className="edit-links-title">
                            <span>{t('installationLink.editWindowsTitle')}</span>
                            <span className="add-link-icon-label" onClick={ () => {handleAddNewLink();} }>
                                {t('installationLink.add')} <FontAwesomeIcon className="add-link-icon" icon={faPlusCircle}/>
                            </span>
                            <span className="add-link-icon-label" onClick={ () => {handleUploadNewFile();} }>
                                {t('installationLink.upload')} <FontAwesomeIcon className="add-link-icon" icon={faPlusCircle}/>
                            </span>
                        </h1>
                        <hr/>
                        {(installationLinks.length > 0) && (
                            <div className="installation-link-list">
                                {
                                    installationLinks.map((entry, index) => {
                                        // return <p>{entry.linkName}</p>
                                        return <Fragment key={entry.linkName + "-" + index}>
                                            <InstallationLinkElement
                                                deleteFn={(id) => {
                                                    let newLinks = [];
                                                    for(let a = 0; a < installationLinks.length; ++a) {
                                                        if(a !== id) {
                                                            newLinks.push(installationLinks[a]);
                                                        }
                                                    }
                                                    setInstallationLinks(newLinks);
                                                }}
                                                id={index}
                                                linkData={entry}
                                                setLinkData={(id, linkName, description, url) => {
                                                    setInstallationLinks((cur) => {
                                                        cur[id].linkName = linkName;
                                                        cur[id].description = description;
                                                        cur[id].url = url;
                                                        return cur;
                                                    });
                                                }}
                                                type={entry.url === "file:///" ? "upload" : "edit"}
                                                verifyLinkNameUniqueness={verifyLinkNameUniqueness}
                                            />
                                            <hr/>
                                        </Fragment>
                                    })
                                }
                            </div>
                        )}
                        {(installationLinks.length === 0) && (
                            <p>{t('installationLink.noEntries')}</p>
                        )}
                    </div>
                )
            }
            <div className='guest-details-modify-buttons'>
                <div></div>
                <div>
                    <button
                        className='cbutton cancelBt'
                        onClick={() => {
                            closeLinksEditModal();
                        }}
                        type='button'
                    >
                        {t('cancel')}
                    </button>
                    <button
                        className='cbutton saveBt'
                        onClick={() => {
                            if(!verifyLinkNameUniqueness()) {
                                alert(t('installationLinks.linkNamesNotUnique'));
                                return;
                            }
                            requestInstallationLinksUpdate();
                            closeLinksEditModal();
                        }}
                        type='button'
                    >
                        {t('save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}
