import React, { useState, useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { createPieChartInfo } from '../graph_utils';
import { useTranslation } from 'react-i18next';
import { timestampToLocalString, timestampToDate, dateToTimestamp, null_or_undefined, colourNameToHex } from '../utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
//import { format as formatDate, setHours, setMinutes } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import cloneDeep from 'lodash/cloneDeep';
import { InstallationsContext } from './App';
import BarGraph from './BarGraph';
import ScatterGraph from './ScatterGraph';
import { Puff } from "react-loader-spinner";
import { DEFAULT_COLORS } from '../Contants';
import {ColorLegend} from "./ColorLegend";
import {values} from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

//const en = enUS;
//Used by date-fns/esm format 
//const locales = {it, de, fr, en, enGB, enUS};

//Used by react-datepicker 
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

export default function TileStatistics({ tileId, statType, wholeData, unit }) {
  const {t, i18n} = useTranslation();

  const { 
    selectedInst, 
    requestDeviceData,
  } = React.useContext(InstallationsContext);

  const NUMBER_OF_BARS = 720;

  const needReload = useRef(false);

  const [deviceDetailModal, setDeviceDetailModal] = useState(null);
  const [cannotChangeParams, setCannotChangeParams] = useState(false)

  function renderLoadingSpinner() {
    return (
      <div 
        className="embedded-loading-overlay" 
        style={cannotChangeParams ? null : {display: 'none'}}
      >
        <Puff
          color="#00BFFF"
          height={100}
          visible={cannotChangeParams === true}
          width={100}
          wrapperClass="loading-spinner"
            // timeout={3000} //3 secs
        />
      </div>
    );
  }

  useEffect(() => {
    if(!needReload.current)
      return;

    needReload.current = false;
    setCannotChangeParams(true);
    requestDeviceData(selectedInst, tileId, deviceDetailModal.startDateTime, deviceDetailModal.endDateTime)
    .then((data) => {
      if(data) {
        setDeviceDetailModal((cur) => {
          cur.dataList = data.dataList;
          cur.percentages = data.percentages;
          cur.eventsNumber = data.eventsNumber;
          cur.graphType = data.graphType;
          return cloneDeep(cur);
        })
      }
      setCannotChangeParams(false);
    })
    .catch( (error) => {
      setCannotChangeParams(false);
    })
  }, [deviceDetailModal, requestDeviceData, selectedInst, tileId]);

  function extractLabelMaps(percentages) {
    let colors = new Map();
    let values = new Map();

    if(percentages == null)
      return {values, colors};

    percentages.forEach((percentage) => {
      values.set(percentage.valueCase, {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
      colors.set(colourNameToHex(percentage.color), {valueCase: percentage.valueCase, desc: percentage.desc, descAL: percentage.descAL, color: colourNameToHex(percentage.color)});
    });

    return {values, colors};
  }

  function renderPercentageInformation(dataToRender, eventsNumber) {
    if(typeof dataToRender === 'undefined' || dataToRender.length === 0)
      return null;

    let info = {};
    let percentageDescs = [];
    dataToRender.forEach((percentage) => {
      switch(percentage.valueCase) {
        default:
        case 0:
          info = {...info, normalPerc: percentage.value, normalLabel: percentage.desc, normalColor: percentage.color};
          break;
        case 1:
          info = {...info, activePerc: percentage.value, activeLabel: percentage.desc, activeColor: percentage.color};
          break;
        case 2:
          info = {...info, warningPerc: percentage.value, warningLabel: percentage.desc, warningColor: percentage.color};
          break;
        case 3:
          info = {...info, errorPerc: percentage.value, errorLabel: percentage.desc, errorColor: percentage.color};
          break;
      }      
      if(percentage.value > 0)
        percentageDescs.push({percentage: percentage.value, desc: percentage.desc});
    });

    const { data, options } = createPieChartInfo(info);
    return (
      <div className="row chart">
      <div className="chart-container">
        <div className="chart-container-pie-row">
          <div className="el-eventi">
            <span>{eventsNumber}</span> {`${t('tileStatistics.significativeEvents')}`}
          </div>
          <div className="pie-chart">
            <Pie 
              data={data}
              height={100}
              options={options}
              width={100}
            />
          </div>
        </div>
        {percentageDescs.map( (perc) => {
          return (
            <div key={perc.desc} className="el-eventi">
              {perc.percentage}% {perc.desc}{'\u00A0'}
            </div>
          );
        })}
      </div>
    </div>
    );
  }
  
  function extractEventsFromData(dataList, startOfInterval, endOfInterval) {
    let events = [];
    let firstEvent = null;
    let lastEvent = null;

    dataList.forEach( (dataPoint) => {
      if(dataPoint.eventDesc && 
        dataPoint.timestamp !== startOfInterval && 
        dataPoint.timestamp !== endOfInterval) 
      {
        events.push(dataPoint);
        
        lastEvent = dataPoint;

        if(firstEvent === null)
          firstEvent = dataPoint;
      }
    });

    return {events, firstEvent, lastEvent};
  }

  function timeIntervalPercentageToTime(startInterval, endInterval, fractionPercentageOfInterval) {
    
    const intervalDuration = endInterval - startInterval;
    const fractionDuration = intervalDuration * fractionPercentageOfInterval / 100;
  
    const days = Math.floor(fractionDuration / (60 * 60 * 24)); 
    const hours = Math.floor((fractionDuration % (60 * 60 * 24)) / (60 * 60)); 
    const minutes = Math.floor((fractionDuration % (60 * 60)) / (60)); 
    
    let resulString = "";
  
    if(days) {
      resulString = resulString + days + " " + t('dayAbbrev') + " ";
    }
    if(days || hours) {
      resulString = resulString + hours.toString().padStart(2, '0') + " " + t('hourAbbrev') + " ";
    }
    if(hours || minutes) {
      resulString = resulString + minutes.toString().padStart(2, '0') + " " + t('minAbbrev') + " ";
    }

    if(!days && !hours && !minutes) {
      resulString = resulString + "0 m";
    }
  
    return resulString;
  }
  
  function renderEventStatistics(type, data, unit, locale) {    
    if(typeof data === 'undefined' || data === null)
      return (`${t('tileStatistics.loadingMessage')}`);

    const {/*events,*/ firstEvent, lastEvent} = extractEventsFromData(data.dataList);

    switch(type) {
      default:
      case 0:
        return null;
  
      case 1:
        let colorMap = []
        // console.log(data.percentages)
        for (let value of data.percentages) {
          let existing = false
          for (const cc of colorMap) {
            if(cc.text === value.desc) {
              existing = true;
              break;
            }
          }
          if(existing)
            continue;
          colorMap.push({
            color: value.color,
            text: value.desc,
            value: value.valueCase
          });
        }
        let unusedLegend = [...colorMap]
        for(const val of data.dataList) {
          let cc = 0
          for(let legend of unusedLegend) {
            if(legend.value === parseInt(val.value)) {
              unusedLegend.splice(cc, 1)
              break;
            }
            cc++
          }
        }
        for(const unused of unusedLegend) {
          let cc = 0
          for(const color of colorMap) {
            if(color.value === unused.value) {
              colorMap.splice(cc, 1)
              break;
            }
            cc++
          }
        }

        return (
          <>
            <div className="statistics-open-full">
              <FontAwesomeIcon icon={faExternalLinkAlt} onClick={() => {setDeviceDetailModal(cloneDeep(data))}}/>
            </div>
            <ColorLegend
                colorsMap={colorMap}
                customClassName={"color-legend single-bar"}
            />
            <BarGraph
              dataToRender={data.dataList}
              displayXScale={true}
              end={data.endDateTime}
              graphClassName="bar-chart" 
              hideMinutes={true} 
              locale={i18n.language}
              numberOfBars={NUMBER_OF_BARS}
              start={data.startDateTime}
              valueMaps={extractLabelMaps(data.percentages)}
            />

            <div className="info-icone row">
              <div className="start-interval">
                <span>{timestampToLocalString(data.startDateTime, i18n.language, 'short', 'short')}</span> <FontAwesomeIcon icon={faStepBackward} />
              </div>
              <div className="end-interval">
                <FontAwesomeIcon icon={faStepForward} /> <span>{timestampToLocalString(data.endDateTime, i18n.language, 'short', 'short')}</span>
              </div>
            </div>

            {renderPercentageInformation(data.percentages, data.eventsNumber)}

            <div className="row">
              <div className="statistiche2">
                <table className="descrizione-eventi" style={{borderCollapse: "collapse"}}>
                  <tbody>
                    {data.percentages.length >= 2 && (
                    <tr>
                      <td>{data.percentages[0].desc} {timeIntervalPercentageToTime(data.startDateTime, data.endDateTime, data.percentages[0].value)}</td>
                      <td>{data.percentages[1].desc} {timeIntervalPercentageToTime(data.startDateTime, data.endDateTime, data.percentages[1].value)}</td>
                    </tr>)}
                    <tr>
                      {firstEvent && (<td>{`${t('tileStatistics.firstEvent')} ${firstEvent.eventDesc} ${timestampToLocalString(firstEvent.timestamp, i18n.language, 'short', 'short')}`}</td>)}
                      {lastEvent && (<td>{`${t('tileStatistics.lastEvent')} ${lastEvent.eventDesc} ${timestampToLocalString(lastEvent.timestamp, i18n.language, 'short', 'short')}`}</td>)}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="statistics-open-full">
              <FontAwesomeIcon icon={faExternalLinkAlt} onClick={() => {setDeviceDetailModal(cloneDeep(data))}}/>
            </div>
            <ScatterGraph
              dataToRender={data.dataList}
              decimal={data.decimal}
              graphClassName="line-chart"
              lineColor={DEFAULT_COLORS.LINE_GRAPH_COLOR} 
              locale={i18n.language} 
              unit={unit}
            />
  
            <div className="info-icone row">
              <div className="start-interval">
                <span>{timestampToLocalString(data.startDateTime, i18n.language, 'short', 'short')}</span> <FontAwesomeIcon icon={faStepBackward} />
              </div>
              <div className="end-interval">
                <FontAwesomeIcon icon={faStepForward} /> <span>{timestampToLocalString(data.endDateTime, i18n.language, 'short', 'short')}</span>
              </div>
            </div>
          </>
        );

      case 8:
        return (
            <>
              <div className="statistics-open-full">
                <FontAwesomeIcon icon={faExternalLinkAlt} onClick={() => {
                  setDeviceDetailModal(cloneDeep(data))
                }}/>
              </div>
              {generateMultiBarGraph(data, unit, locale, false)}
              <div className="info-icone row">
                <div className="start-interval">
                  <span>{timestampToLocalString(data.startDateTime, i18n.language, 'short', 'short')}</span> <FontAwesomeIcon
                    icon={faStepBackward}/>
                </div>
                <div className="end-interval">
                  <FontAwesomeIcon
                      icon={faStepForward}/> <span>{timestampToLocalString(data.endDateTime, i18n.language, 'short', 'short')}</span>
                </div>
              </div>
            </>
        );
    }
  }

  function generateMultiBarGraph(data, unit, locale, isModal) {
    if(data == null)
      return;
    const barData = divideDataForMultiBar(data);
    let bars = [];
    for (const [key, value] of barData.categorizedDataMap) {
      if(value.dataPoints.length === 0)
        continue;
      bars.push(
        <React.Fragment key={value.value}>
          <p className={'bar-chart-title'}>{value.desc}</p>
          <BarGraph
              dataToRender={value.dataPoints}
              displayXScale={isModal}
              end={data.endDateTime}
              graphClassName={isModal ? "bar-chart-full-width" : "bar-chart"}
              hideMinutes={false}
              locale={locale}
              numberOfBars={NUMBER_OF_BARS}
              start={data.startDateTime}
              tooltipType={'full'}
              valueMaps={{"values": barData.values, "colors": barData.colors}}
          />
        </React.Fragment>
      )
    }

    let colorMap = []
    for (let [key, value] of barData.colors) {
      let existing = false
      for (const cc of colorMap) {
        if(cc.text === value.desc) {
          existing = true;
          break;
        }
      }
      if(existing)
        continue;
      colorMap.push({
        color: value.color,
        text: value.desc,
        value: value.valueCase
      });
    }
    let unusedLegend = [...colorMap]
    for(const val of data.dataList) {
      let cc = 0
      for(let legend of unusedLegend) {
        if(data.valueCaseSplit && parseInt(data.valueCaseSplit) > 0) {
          if(legend.value === (parseInt(val.value) >> data.valueCaseSplit)) {
            unusedLegend.splice(cc, 1)
            break;
          }
        } else {
          if(legend.value === parseInt(val.value)) {
            unusedLegend.splice(cc, 1)
            break;
          }
        }
        cc++
      }
    }
    for(const unused of unusedLegend) {
      let cc = 0
      for(const color of colorMap) {
        if(color.value === unused.value) {
          colorMap.splice(cc, 1)
          break;
        }
        cc++
      }
    }
    return (
      <>
        <ColorLegend
            colorsMap={colorMap}
            customClassName={isModal ? "modal-color-legend" : "color-legend"}
        />
        {bars.map((bar, idx)=> {
          return bar;
        })}
      </>
    )
  }

  function divideDataForMultiBar(data)
  {
    let values = new Map;
    let colors = new Map;

    let categorizedDataMap = new Map;
    data.valueMap.map((value, idx) => {
      categorizedDataMap.set(value.value, {"value" : value.value, "desc": value.desc, "descAL": value.descAL, "dataPoints" : []});
    });

    data.valueCaseMap.map((value, idx) => {
      values.set(value.value, {valueCase: value.value, desc: value.desc, descAL: value.descAL, color: colourNameToHex(value.color)});
      colors.set(colourNameToHex(value.color), {valueCase: value.value, desc: value.desc, descAL: value.descAL, color: colourNameToHex(value.color)});
    });

    if(data.dataList == null || data.dataList.length === 0)
      return {categorizedDataMap, values, colors};
    const firstEntryTimestamp = data.dataList[0].timestamp;
    for(let [key, value] of categorizedDataMap) {
      value.dataPoints.push({"timestamp": firstEntryTimestamp, "value": -1});
    }
    let lastCategory = -1;
    if(data.valueCaseSplit && parseInt(data.valueCaseSplit) > 0) {
      let mask = Math.pow(2, data.valueCaseSplit) - 1;
      data.dataList.map((entry) => {
        let cleanedEntry = {...entry};
        cleanedEntry.value = parseInt(entry.value) >> data.valueCaseSplit;
        const category = parseInt(entry.value) & mask;
        if(lastCategory !== -1 && category !== lastCategory) {
          let closingEntry = {...entry};
          closingEntry.value = -1;
          categorizedDataMap.get(lastCategory).dataPoints.push(closingEntry);
        }
        categorizedDataMap.get(category).dataPoints.push(cleanedEntry);
        lastCategory = category;
      });
    } else {
      data.dataList.map((entry) => {
        categorizedDataMap.get(parseInt(entry.value)).dataPoints.push({...entry})
      });
    }
    const lastEntryTimestamp = data.dataList[data.dataList.length-1].timestamp;
    for(const [key, value] of categorizedDataMap) {
      if(value.dataPoints.length === 1) {
        value.dataPoints = [];
        continue;
      }
      value.dataPoints.push({"timestamp": lastEntryTimestamp, "value": -1});
    }
    return {categorizedDataMap, values, colors};
  }

  function closeDeviceDetailModal() {
    setDeviceDetailModal(null);
  }

  function renderModal() {
    if (null_or_undefined(deviceDetailModal))
      return null;

    const {/*events,*/ firstEvent, lastEvent} = extractEventsFromData(deviceDetailModal.dataList);
    switch ((deviceDetailModal?.graphType != null ? deviceDetailModal?.graphType : statType)) {
      default:
      case 0:
        return null;

      case 1:
        let colorMap = []
        // console.log(deviceDetailModal.percentages)
        for (let value of deviceDetailModal.percentages) {
          let existing = false
          for (const cc of colorMap) {
            if(cc.text === value.desc) {
              existing = true;
              break;
            }
          }
          if(existing)
            continue;
          colorMap.push({
            color: value.color,
            text: value.desc,
            value: value.valueCase
          });
        }
        let unusedLegend = [...colorMap]
        for(const val of deviceDetailModal.dataList) {
          let cc = 0
          for(let legend of unusedLegend) {
            if(legend.value === parseInt(val.value)) {
              unusedLegend.splice(cc, 1)
              break;
            }
            cc++
          }
        }
        for(const unused of unusedLegend) {
          let cc = 0
          for(const color of colorMap) {
            if(color.value === unused.value) {
              colorMap.splice(cc, 1)
              break;
            }
            cc++
          }
        }

        return (
            <Modal
                classNames={{
                  // overlay: 'custom-overlay',
                  modal: 'full-width-tile-stats',
                }}
                onClose={closeDeviceDetailModal}
                open={true}
                styles={{modal: {minWidth: '98%'}}}
                // center
            >
              {renderLoadingSpinner()}
              {
                (
                  <>
                    <h1>{t('tileStatistics.detailTitle')}</h1>
                    <hr/>
                    {/* <div className="bar-chart-full-width">
                    {renderBar(deviceDetailModal.dataList, deviceDetailModal.startDateTime, deviceDetailModal.endDateTime)}
                  </div> */}
                    <ColorLegend
                        colorsMap={colorMap}
                        customClassName={"modal-color-legend single-bar"}
                    />
                    <BarGraph
                        dataToRender={deviceDetailModal.dataList}
                        displayXScale={true}
                        end={deviceDetailModal.endDateTime}
                        graphClassName="bar-chart-full-width"
                        hideMinutes={false}
                        locale={i18n.language}
                        numberOfBars={NUMBER_OF_BARS}
                        start={deviceDetailModal.startDateTime}
                        valueMaps={extractLabelMaps(deviceDetailModal.percentages)}
                    />

                    <div className="interval-selection full-with-statistics">
                      <div className="interval-selection-start">
                        <DatePicker
                            dateFormat="Pp"
                            disabled={cannotChangeParams}
                            endDate={timestampToDate(deviceDetailModal.endDateTime)}
                            locale={i18n.language}
                            maxDate={timestampToDate(deviceDetailModal.endDateTime)}
                            onChange={(date) => {
                              needReload.current = true;
                              setDeviceDetailModal((cur) => {
                                cur.startDateTime = dateToTimestamp(date);
                                if (cur.startDateTime >= deviceDetailModal.endDateTime) {
                                  cur.startDateTime = deviceDetailModal.endDateTime - 3600;
                                }
                                return cloneDeep(cur);
                              })
                            }}
                            popperPlacement="right"
                            selected={timestampToDate(deviceDetailModal.startDateTime)}
                            showDisabledMonthNavigation
                            showMonthDropdown
                            showTimeSelect
                            showYearDropdown
                            startDate={timestampToDate(deviceDetailModal.startDateTime)}
                            timeIntervals={60}
                            wrapperClassName="start-filter-date-time"
                        />
                      </div>
                      <div className="interval-selection-start-icon">
                        <FontAwesomeIcon icon={faStepBackward}/>
                      </div>
                      <div className="interval-selection-end-icon">
                        <FontAwesomeIcon icon={faStepForward}/>
                      </div>
                      <div className="interval-selection-end">
                        <DatePicker
                            dateFormat="Pp"
                            disabled={cannotChangeParams}
                            endDate={timestampToDate(deviceDetailModal.endDateTime)}
                            locale={i18n.language}
                            maxDate={new Date()}
                            minDate={timestampToDate(deviceDetailModal.startDateTime)}
                            onChange={(date) => {
                              needReload.current = true;
                              setDeviceDetailModal((cur) => {
                                cur.endDateTime = dateToTimestamp(date);
                                if (cur.endDateTime <= deviceDetailModal.startDateTime) {
                                  cur.endDateTime = deviceDetailModal.startDateTime + 3600;
                                }
                                return cloneDeep(cur);
                              })
                            }}
                            popperPlacement="left"
                            selected={timestampToDate(deviceDetailModal.endDateTime)}
                            showDisabledMonthNavigation
                            showMonthDropdown
                            showTimeSelect
                            showYearDropdown
                            startDate={timestampToDate(deviceDetailModal.startDateTime)}
                            timeIntervals={60}
                            wrapperClassName="end-filter-date-time"
                        />
                      </div>
                    </div>
                    {/* <div className="info-icone row">
                    <div className="start-interval">
                      <span>{timestampToLocalString(deviceDetailModal.startDateTime, i18n.language, 'short', 'short')}</span><FontAwesomeIcon icon={faStepBackward} />
                    </div>
                    <div className="end-interval">
                      <FontAwesomeIcon icon={faStepForward} /><span>{timestampToLocalString(deviceDetailModal.endDateTime, i18n.language, 'short', 'short')}</span>
                    </div>
                  </div> */}

                    {renderPercentageInformation(deviceDetailModal.percentages, deviceDetailModal.eventsNumber)}

                    <div className="row">
                      <div className="statistiche2">
                        <table className="descrizione-eventi" style={{borderCollapse: "collapse"}}>
                          <tbody>
                          {deviceDetailModal.percentages.length >= 2 && (
                              <tr>
                                <td>{deviceDetailModal.percentages[0].desc} {timeIntervalPercentageToTime(deviceDetailModal.startDateTime, deviceDetailModal.endDateTime, deviceDetailModal.percentages[0].value)}</td>
                                <td>{deviceDetailModal.percentages[1].desc} {timeIntervalPercentageToTime(deviceDetailModal.startDateTime, deviceDetailModal.endDateTime, deviceDetailModal.percentages[1].value)}</td>
                              </tr>)}
                          <tr>
                            {firstEvent && (
                                <td>{`${t('tileStatistics.firstEvent')} ${firstEvent.eventDesc} ${timestampToLocalString(firstEvent.timestamp, i18n.language, 'short', 'short')}`}</td>)}
                            {lastEvent && (
                                <td>{`${t('tileStatistics.lastEvent')} ${lastEvent.eventDesc} ${timestampToLocalString(lastEvent.timestamp, i18n.language, 'short', 'short')}`}</td>)}
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
              )
            }
          </Modal>
        );
      case 2:
        return (
          <Modal
            classNames={{
              // overlay: 'custom-overlay',
              modal: 'full-width-tile-stats',
            }}
            onClose={closeDeviceDetailModal}
            open={true}
            styles={{modal: {minWidth: '98%'}}}
            // center
          >
            {renderLoadingSpinner()}
            {
              (
                  <>
                    <h1>{t('tileStatistics.detailTitle')}</h1>
                    <hr/>
                    {/* <div className="line-chart-full-width">
                    {renderLine(deviceDetailModal.dataList, deviceDetailModal.startDateTime, deviceDetailModal.endDateTime, unit)}
                  </div> */}
                    <ScatterGraph
                        dataToRender={deviceDetailModal.dataList}
                        decimal={deviceDetailModal.decimal}
                        graphClassName="line-chart-full-width"
                        lineColor={DEFAULT_COLORS.LINE_GRAPH_COLOR}
                        locale={i18n.language}
                        unit={unit}
                    />

                    <div className="interval-selection full-with-statistics">
                      <div className="interval-selection-start">
                        <DatePicker
                            dateFormat="Pp"
                            endDate={timestampToDate(deviceDetailModal.endDateTime)}
                            locale={i18n.language}
                            maxDate={new Date()}
                            onChange={(date) => {
                              needReload.current = true;
                              setDeviceDetailModal((cur) => {
                                cur.startDateTime = dateToTimestamp(date);
                                return cloneDeep(cur);
                              })
                            }}
                            popperPlacement="top-start"
                            selected={timestampToDate(deviceDetailModal.startDateTime)}
                            showDisabledMonthNavigation
                            showMonthDropdown
                            showTimeSelect
                            showYearDropdown
                            startDate={timestampToDate(deviceDetailModal.startDateTime)}
                            timeIntervals={60}
                            wrapperClassName="start-filter-date-time"
                        />
                      </div>
                      <div className="interval-selection-start-icon">
                        <FontAwesomeIcon icon={faStepBackward}/>
                      </div>
                      <div className="interval-selection-end-icon">
                        <FontAwesomeIcon icon={faStepForward}/>
                      </div>
                      <div className="interval-selection-end">
                        <DatePicker
                            dateFormat="Pp"
                            endDate={timestampToDate(deviceDetailModal.endDateTime)}
                            locale={i18n.language}
                            maxDate={new Date()}
                            onChange={(date) => {
                              needReload.current = true;
                              setDeviceDetailModal((cur) => {
                                cur.endDateTime = dateToTimestamp(date);
                                return cloneDeep(cur);
                              })
                            }}
                            popperPlacement="top-end"
                            selected={timestampToDate(deviceDetailModal.endDateTime)}
                            showDisabledMonthNavigation
                            showMonthDropdown
                            showTimeSelect
                            showYearDropdown
                            startDate={timestampToDate(deviceDetailModal.startDateTime)}
                            timeIntervals={60}
                            wrapperClassName="end-filter-date-time"
                        />
                      </div>
                    </div>

                    {/* <div className="info-icone row">
                    <div className="start-interval">
                      <span>{timestampToLocalString(deviceDetailModal.startDateTime, i18n.language, 'short', 'short')}</span><FontAwesomeIcon icon={faStepBackward} />
                    </div>
                    <div className="end-interval">
                      <FontAwesomeIcon icon={faStepForward} /><span>{timestampToLocalString(deviceDetailModal.endDateTime, i18n.language, 'short', 'short')}</span>
                    </div>
                  </div> */}
                  </>
              )
            }
          </Modal>
        );
      case 8:
        return (
            <Modal
                classNames={{
                  // overlay: 'custom-overlay',
                  modal: 'full-width-tile-stats',
                }}
                onClose={closeDeviceDetailModal}
                open={true}
                styles={{modal: {minWidth: '98%'}}}
                // center
            >
              {renderLoadingSpinner()}
              {
                  (
                      <>
                        <h1>{t('tileStatistics.detailTitle')}</h1>
                        <hr/>
                        {generateMultiBarGraph(deviceDetailModal, unit, i18n.language, true)}

                        <div className="interval-selection full-with-statistics">
                          <div className="interval-selection-start">
                            <DatePicker
                                dateFormat="Pp"
                                disabled={cannotChangeParams}
                                endDate={timestampToDate(deviceDetailModal.endDateTime)}
                                locale={i18n.language}
                                maxDate={timestampToDate(deviceDetailModal.endDateTime)}
                                onChange={(date) => {
                                  needReload.current = true;
                                  setDeviceDetailModal((cur) => {
                                    cur.startDateTime = dateToTimestamp(date);
                                    if (cur.startDateTime >= deviceDetailModal.endDateTime) {
                                      cur.startDateTime = deviceDetailModal.endDateTime - 3600;
                                    }
                                    return cloneDeep(cur);
                                  })
                                }}
                                popperPlacement="right"
                                selected={timestampToDate(deviceDetailModal.startDateTime)}
                                showDisabledMonthNavigation
                                showMonthDropdown
                                showTimeSelect
                                showYearDropdown
                                startDate={timestampToDate(deviceDetailModal.startDateTime)}
                                timeIntervals={60}
                                wrapperClassName="start-filter-date-time"
                            />
                          </div>
                          <div className="interval-selection-start-icon">
                            <FontAwesomeIcon icon={faStepBackward}/>
                          </div>
                          <div className="interval-selection-end-icon">
                            <FontAwesomeIcon icon={faStepForward}/>
                          </div>
                          <div className="interval-selection-end">
                            <DatePicker
                                dateFormat="Pp"
                                disabled={cannotChangeParams}
                                endDate={timestampToDate(deviceDetailModal.endDateTime)}
                                locale={i18n.language}
                                maxDate={new Date()}
                                minDate={timestampToDate(deviceDetailModal.startDateTime)}
                                onChange={(date) => {
                                  needReload.current = true;
                                  setDeviceDetailModal((cur) => {
                                    cur.endDateTime = dateToTimestamp(date);
                                    if (cur.endDateTime <= deviceDetailModal.startDateTime) {
                                      cur.endDateTime = deviceDetailModal.startDateTime + 3600;
                                    }
                                    return cloneDeep(cur);
                                  })
                                }}
                                popperPlacement="left"
                                selected={timestampToDate(deviceDetailModal.endDateTime)}
                                showDisabledMonthNavigation
                                showMonthDropdown
                                showTimeSelect
                                showYearDropdown
                                startDate={timestampToDate(deviceDetailModal.startDateTime)}
                                timeIntervals={60}
                                wrapperClassName="end-filter-date-time"
                            />
                          </div>
                        </div>
                      </>
                  )
              }
            </Modal>
        );
    }
  }
  
  return (
    <>
      <div className="corpo-notifica" id={`notifica_${tileId}`}>
        {renderModal()}
        <div className="dettaglio-notifica row">
          <div className="dettaglio-notifica-container">
            {renderEventStatistics((wholeData?.graphType != null ? wholeData?.graphType : statType), wholeData, unit, i18n.language)}
          </div> 
        </div> 
      </div>
    </>
  )
}
