import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderSection from "./HeaderSection";
import { InstallationsContext, MqttContext } from "./App";
import { SITE_LINKS } from "../Contants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faCaretLeft,
  faCaretRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CaseTableRow from "./CaseTableRow";
import Guests from "./Guests";
import InstallationDesc from "./InstallationDesc";
import Select from "react-select";
import {parseTimeDuration, timestampToDate} from "../utilities";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import StaticBanner from "./StaticBanner";
import ContactPersons from "./ContactPersons";
import FooterSection from "./FooterSection";
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api"; // Import css

export default withRouter(function AlertDetailPage({
  history,
  currRoute,
  allowModify,
}) {
  const { t , i18n } = useTranslation();
  let mainContentRef = React.createRef();
  const {
    selectedInst,
    installations,
    loadAllInstallationData,
    installationGuests,
    installationContacts,
    // requestDeviceUpdate,
    changeDeviceValue,
    loadInstallationCases,
    loadInstallationCasesNumber,
    addInstallationNewCase,
    addInstallationCaseEntry,
  } = React.useContext(InstallationsContext);

  const { mqttStatus } = React.useContext(MqttContext);

  const [editingAlert, setEditingAlert] = useState(null);
  const [inhibitionTimeout, setInhibitionTimeout] = useState(600);
  const [cases, setCases] = useState([]);
  const [showClosedCases, setShowClosedCases] = useState(false);
  const [latestCasesFirst, setLatestCasesFirst] = useState(true);
  const [orderByLastCaseUpdate, setOrderByLastCaseUpdate] = useState(false);
  const [offsetCase, setOffsetCase] = useState(0);
  const [limitCase, setLimitCase] = useState(10);
  const [casesNumber, setCasesNumber] = useState(0);
  const [deviceInfos, setDeviceInfos] = useState(new Map());
  const [cannotChangeParams, setCannotChangeParams] = useState(false);

  const casesLoaded = useRef(false);

  const currInstAlerts = installations?.[selectedInst]?.alerts ?? null;

  const ALERT_MODAL_TYPE_NEW = "new";
  const ALERT_MODAL_TYPE_INHIBIT = "inhibit";
  const ALERT_MODAL_TYPE_CLOSE = "close";
  const ALERT_MODAL_TYPE_REOPEN = "reopen";
  const ALERT_MODAL_TYPE_FROM_JOURNAL = "fromJournal";

  const selectedInstallationData =
    selectedInst && installations ? installations[selectedInst] : null;

  function decodeTimeout(seconds, showSeconds, showZeros, extendedTimenames) {
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds - days * (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor(
      (seconds - days * (60 * 60 * 24) - hours * (60 * 60)) / 60
    );
    const secs =
      seconds - days * (60 * 60 * 24) - hours * (60 * 60) - minutes * 60;

    let res = "";
    if (days > 0) {
      if (extendedTimenames) {
        res = `${days} ${days > 1 ? t("days") : t("day")} `;
      } else {
        res = `${days}${t("dayAbbrev")} `;
      }
    }
    if (days > 0 || hours > 0) {
      if (showZeros || hours > 0) {
        if (extendedTimenames) {
          res = res + `${hours} ${hours > 1 ? t("hours") : t("hour")} `;
        } else {
          res = res + `${hours}${t("hourAbbrev")} `;
        }
      }
    }
    if (days > 0 || hours > 0 || minutes > 0) {
      if (showZeros || minutes > 0) {
        if (extendedTimenames) {
          res = res + `${minutes} ${minutes > 1 ? t("minutes") : t("minute")} `;
        } else {
          res = res + `${minutes}${t("minAbbrev")} `;
        }
      }
    }
    if (showSeconds) {
      if (showZeros || secs > 0) {
        if (extendedTimenames) {
          res = res + `${secs} ${secs > 1 ? t("seconds") : t("second")} `;
        } else {
          res = res + `${secs}${t("secAbbrev")} `;
        }
      }
    }
    return res.trim();
  }

  const loadCases = useCallback(() => {
    return loadInstallationCases(
      selectedInst,
      showClosedCases,
      latestCasesFirst,
      orderByLastCaseUpdate,
      offsetCase,
      limitCase,
      true
    )
      .then((loadedCases) => {
        setCases(loadedCases);
        casesLoaded.current = true;
        setCannotChangeParams(false);
        if (offsetCase === 0) {
          if (loadedCases?.length === limitCase) {
            return loadInstallationCasesNumber(
              selectedInst,
              showClosedCases,
              latestCasesFirst,
              orderByLastCaseUpdate,
              offsetCase,
              limitCase,
              true
            );
          } else {
            return loadedCases?.length;
          }
        } else {
          return null;
        }
      })
      .then((number) => {
        if (number !== null) {
          setCasesNumber(number);
        }
      });
  }, [
    latestCasesFirst,
    limitCase,
    loadInstallationCases,
    loadInstallationCasesNumber,
    offsetCase,
    orderByLastCaseUpdate,
    selectedInst,
    showClosedCases,
  ]);

  useEffect(() => {
    if (selectedInst == null) {
      return;
    }

    loadAllInstallationData(selectedInst, true, true, false).then((data) => {
      setDeviceInfos(data);
    });
  }, [loadAllInstallationData, selectedInst]);

  useEffect(() => {
    if (selectedInst == null) {
      if (deviceInfos?.size > 0) {
        setDeviceInfos(new Map());
      }
      return;
    }

    //loadCases();
  }, [selectedInst, deviceInfos /*, loadCases*/]);

  function handleLimitChange(option) {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setLimitCase(option.value);
    setOffsetCase(0);
  }

  function handleToggleClosedCases() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setOffsetCase(0);
    setShowClosedCases(cur => !cur);
  }

  function handleToggleLatestFirst() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setLatestCasesFirst(cur => !cur);
  }

  function handleToggleOrderByLastUpdate() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setOrderByLastCaseUpdate(cur => !cur);
  }

  function handleFirstPage() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setOffsetCase(0);
  }

  function handlePrevPage() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setOffsetCase(cur => cur - limitCase);
  }

  function handleNextPage() {
    casesLoaded.current = false;
    setCannotChangeParams(true);
    setOffsetCase(cur => cur + limitCase);
  }

  // const getDeviceInfo = useCallback((deviceId) => {
  //   if(deviceId == null)
  //     return null;

  //   let devInfo = requestDeviceUpdate(selectedInst, deviceId)
  //   .then(deviceInfo => {
  //     if(deviceInfo != null) {
  //       setDeviceInfos(cur => {
  //         cur.set(deviceId, deviceInfo);
  //         return new Map(cur);
  //       });
  //       return deviceInfo;
  //     }
  //   });

  //   //Placeholder with the promise to avoid requesting 2 times the same
  //   setDeviceInfos(cur => {
  //     cur.set(deviceId, devInfo);
  //     return new Map(cur);
  //   });

  // }, [requestDeviceUpdate, selectedInst]);

  function closeAlertModal() {
    setEditingAlert(null);
  }

  function addAlert() {
    addInstallationNewCase(selectedInst, editingAlert)
      .then(() => {
        loadCases();
        setEditingAlert(null);
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  function inhibitAlert() {
    const fullDescription = `${t("alerts.inhibitedFor")} ${decodeTimeout(
      inhibitionTimeout,
      false,
      false,
      true
    )}: ${editingAlert.entryDescription}`;
    addInstallationCaseEntry(selectedInst, editingAlert.id, {
      type: 2,
      description: fullDescription,
    })
      .then(() => {
        return loadCases();
      })
      .then(() => {
        if (editingAlert.lastState !== 4 && editingAlert.deviceId != null)
          return changeDeviceValue(
            selectedInst,
            editingAlert.deviceId,
            -1 * inhibitionTimeout,
            true
          );
        return 0;
      })
      .then(() => {
        setEditingAlert(null);
      })
      .catch((err) => {
        alert(err);
      });
  }

  useEffect(() => {
    if (currInstAlerts !== null) {
      loadCases();
    }
  }, [currInstAlerts, loadCases]);

  function closeAlert() {
    addInstallationCaseEntry(selectedInst, editingAlert.id, {
      type: 3,
      description: editingAlert.entryDescription,
    })
      .then(() => {
        return loadCases();
      })
      .then(() => {
        if (editingAlert.lastState !== 4 && editingAlert.deviceId != null)
          return changeDeviceValue(
            selectedInst,
            editingAlert.deviceId,
            0,
            true
          );
        return 0;
      })
      .then(() => {
        setEditingAlert(null);
      })
      .catch((err) => {
        alert(err);
      });
  }

  function reopenAlert() {
    addInstallationCaseEntry(selectedInst, editingAlert.id, {
      type: 5,
      description: editingAlert.entryDescription,
    })
      .then(() => {
        loadCases();
        setEditingAlert(null);
      })
      .catch((err) => {
        alert(err);
      });
  }

  function showAlertModal(alert, type) {
    switch (type) {
      case ALERT_MODAL_TYPE_NEW:
        setEditingAlert({
          type: 4,
          title: "",
          description: "",
          deviceId: null,
          entryType: 1,
          entryDescription: "",
          invalidMotive: false,
        });
        break;
      case ALERT_MODAL_TYPE_INHIBIT:
        setEditingAlert({ ...alert, entryType: 2 });
        break;
      case ALERT_MODAL_TYPE_CLOSE:
        setEditingAlert({ ...alert, entryType: 3 });
        break;
      case ALERT_MODAL_TYPE_REOPEN:
        confirmAlert({
          title: `${t("alerts.reopenConfirmRequest")}`,
          buttons: [
            {
              label: `${t("Yes")}`,
              onClick: () => {
                setEditingAlert({ ...alert, entryType: 5 });
              },
            },
            {
              label: `${t("No")}`,
            },
          ],
        });
        break;
      case ALERT_MODAL_TYPE_FROM_JOURNAL:
        confirmAlert({
          title: `${t("alerts.transformJournalConfirmRequest")}`,
          buttons: [
            {
              label: `${t("Yes")}`,
              onClick: () => {
                setEditingAlert({ ...alert, entryType: 5 });
              },
            },
            {
              label: `${t("No")}`,
            },
          ],
        });
        break;

      default:
        console.log("Internal error");
    }
  }

  return (
    <>
      <HeaderSection
        currRoute={currRoute}
        mainContentRef={mainContentRef}
        mqttStatus={mqttStatus}
        selectedInst={selectedInst}
      />
      <Modal
        classNames={{
          // overlay: 'customOverlay',
          modal: "fullWidthModal",
        }}
        onClose={closeAlertModal}
        open={editingAlert !== null}
      >
        {editingAlert && (
          <div className="modal-dialog-container" style={{ gridRowGap: "1em" }}>
            <h3 className="modal-dialog-title modal-dialog-full-width">
              {editingAlert.type === 4 &&
                editingAlert.entryType === 1 &&
                `${t("alerts.newRequestTitle")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.requestInhibition")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.warningInhibition")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.warningInhibition")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.alarmInhibition")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.requestClosure")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.warningClosure")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.warningClosure")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.alarmClosure")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.requestReopen")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.warningReopen")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.warningReopen")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.alarmReopen")}`}
              {editingAlert.type === 5 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.requestReopen")}`}
              <hr />
            </h3>
            {editingAlert.type === 4 && editingAlert.entryType === 1 && (
              <>
                <div className="modal-dialog-full-width">
                  <label className="input-label">{`${t(
                    "alerts.newAlertTile"
                  )}`}</label>
                  <input
                    className="modal-dialog-input"
                    defaultValue={editingAlert.title}
                    id="title"
                    name="title"
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditingAlert((cur) => {
                        cur.title = value;
                        return { ...cur };
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="modal-dialog-full-width">
                  <label className="input-label">{`${t(
                    "alerts.newAlertDescription"
                  )}`}</label>
                  <input
                    className="modal-dialog-input"
                    defaultValue={editingAlert.description}
                    id="description"
                    name="description"
                    onChange={(e) => {
                      const value = e.target.value;
                      setEditingAlert((cur) => {
                        cur.description = value;
                        return { ...cur };
                      });
                    }}
                    type="text"
                  />
                </div>
              </>
            )}
            {editingAlert.entryType === 2 && (
              <div className="modal-info">
                <label className="input-label">
                  {editingAlert.type === 4 &&
                    editingAlert.entryType === 2 &&
                    `${t("alerts.inhibitRequestFor")}`}
                  {editingAlert.type === 3 &&
                    editingAlert.entryType === 2 &&
                    `${t("alerts.inhibitWarningFor")}`}
                  {editingAlert.type === 2 &&
                    editingAlert.entryType === 2 &&
                    `${t("alerts.inhibitWarningFor")}`}
                  {editingAlert.type === 1 &&
                    editingAlert.entryType === 2 &&
                    `${t("alerts.inhibitAlarmFor")}`}
                </label>
                <Select
                  defaultValue={{
                    value: inhibitionTimeout,
                    label: decodeTimeout(inhibitionTimeout, false, false, true),
                  }}
                  maxMenuHeight={140}
                  name="inhibitionTime"
                  onChange={(option) => {
                    const value = option.value;
                    setInhibitionTimeout(value);
                  }}
                  options={[
                    {
                      value: parseTimeDuration("00:05:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:05:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:10:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:10:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:15:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:15:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:20:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:20:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:25:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:25:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:30:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:30:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("00:45:00"),
                      label: decodeTimeout(
                        parseTimeDuration("00:45:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("01:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("01:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("01:30:00"),
                      label: decodeTimeout(
                        parseTimeDuration("01:30:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("02:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("02:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("03:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("03:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("04:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("04:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("05:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("05:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("06:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("06:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("09:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("09:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("12:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("12:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("24:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("24:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("48:00:00"),
                      label: decodeTimeout(
                        parseTimeDuration("48:00:00"),
                        false,
                        false,
                        true
                      ),
                    },
                    {
                      value: parseTimeDuration("168:00:00"),
                      label: decodeTimeout(
                          parseTimeDuration("168:00:00"),
                          false,
                          false,
                          true
                      ),
                    },
                    {
                      value: parseTimeDuration("360:00:00"),
                      label: decodeTimeout(
                          parseTimeDuration("360:00:00"),
                          false,
                          false,
                          true
                      ),
                    },
                    {
                      value: parseTimeDuration("720:00:00"),
                      label: decodeTimeout(
                          parseTimeDuration("720:00:00"),
                          false,
                          false,
                          true
                      ),
                    },
                  ]}
                />
              </div>
            )}
            <div
              className={`modal-dialog-full-width ${
                editingAlert.invalidMotive ? "invalid-text-input" : ""
              }`}
            >
              <label className="input-label">
                {editingAlert.type === 4 &&
                  editingAlert.entryType === 1 &&
                  `${t("alerts.newAlertMotivation")}`}
                {editingAlert.type === 4 &&
                  editingAlert.entryType === 2 &&
                  `${t("alerts.alertInhibitionMotive")}`}
                {editingAlert.type === 3 &&
                  editingAlert.entryType === 2 &&
                  `${t("alerts.alertInhibitionMotive")}`}
                {editingAlert.type === 2 &&
                  editingAlert.entryType === 2 &&
                  `${t("alerts.alertInhibitionMotive")}`}
                {editingAlert.type === 1 &&
                  editingAlert.entryType === 2 &&
                  `${t("alerts.alertInhibitionMotive")}`}
                {editingAlert.type === 4 &&
                  editingAlert.entryType === 3 &&
                  `${t("alerts.alertClosureMotive")}`}
                {editingAlert.type === 3 &&
                  editingAlert.entryType === 3 &&
                  `${t("alerts.alertClosureMotive")}`}
                {editingAlert.type === 2 &&
                  editingAlert.entryType === 3 &&
                  `${t("alerts.alertClosureMotive")}`}
                {editingAlert.type === 1 &&
                  editingAlert.entryType === 3 &&
                  `${t("alerts.alertClosureMotive")}`}
                {editingAlert.type === 4 &&
                  editingAlert.entryType === 5 &&
                  `${t("alerts.reopeningMotive")}`}
                {editingAlert.type === 3 &&
                  editingAlert.entryType === 5 &&
                  `${t("alerts.reopeningMotive")}`}
                {editingAlert.type === 2 &&
                  editingAlert.entryType === 5 &&
                  `${t("alerts.reopeningMotive")}`}
                {editingAlert.type === 1 &&
                  editingAlert.entryType === 5 &&
                  `${t("alerts.reopeningMotive")}`}
                {editingAlert.type === 5 &&
                  editingAlert.entryType === 5 &&
                  `${t("alerts.reopeningMotive")}`}
              </label>
              <input
                className="modal-dialog-input"
                defaultValue={editingAlert.entryDescription}
                id="description"
                name="description"
                onChange={(e) => {
                  const value = e.target.value;
                  setEditingAlert((cur) => {
                    cur.entryDescription = value;
                    return { ...cur };
                  });
                }}
                type="text"
              />
              {editingAlert.invalidMotive && (
                <p className="invalid-text-motive">{`${t(
                  "alerts.invalidTextMotive"
                )}`}</p>
              )}
            </div>
            <button
              className="cbutton reminder-dialog-action-button cancel-button"
              onClick={() => {
                closeAlertModal();
              }}
              type="button"
            >
              {`${t("CANCEL")}`}
            </button>
            <button
              className="cbutton reminder-dialog-action-button ok-button"
              onClick={() => {
                if (
                  editingAlert.entryDescription == null ||
                  editingAlert.entryDescription.length === 0
                ) {
                  setEditingAlert((cur) => {
                    cur.invalidMotive = true;
                    return { ...cur };
                  });
                  return;
                }

                switch (editingAlert.type) {
                  case 1:
                  case 2:
                  case 3:
                  default:
                    if (editingAlert.entryType === 3) closeAlert();
                    else if (editingAlert.entryType === 2) inhibitAlert();
                    else if (editingAlert.entryType === 5) reopenAlert();
                    break;

                  case 4:
                    if (editingAlert.entryType === 1) addAlert();
                    else if (editingAlert.entryType === 2) inhibitAlert();
                    else if (editingAlert.entryType === 3) closeAlert();
                    else if (editingAlert.entryType === 5) reopenAlert();
                    break;

                  case 5:
                    if (editingAlert.entryType === 5) reopenAlert();
                    break;
                }
              }}
              type="button"
            >
              {editingAlert.type === 4 &&
                editingAlert.entryType === 1 &&
                `${t("ADD")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.closeRequest")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.closeWarning")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.closeWarning")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 3 &&
                `${t("alerts.closeAlarm")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.inhibitRequest")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.inhibitWarning")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.inhibitWarning")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 2 &&
                `${t("alerts.inhibitAlarm")}`}
              {editingAlert.type === 4 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.reopenRequest")}`}
              {editingAlert.type === 3 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.reopenWarning")}`}
              {editingAlert.type === 2 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.reopenWarning")}`}
              {editingAlert.type === 1 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.reopenAlarm")}`}
              {editingAlert.type === 5 &&
                editingAlert.entryType === 5 &&
                `${t("alerts.reopenRequest")}`}
            </button>
          </div>
        )}
      </Modal>
      <section className="main-content" ref={mainContentRef} style={{ lineHeight: 1 }}>
        {selectedInstallationData?.status === INSTALLATION_ALERT_STATUS_DISCONNECTED && (
            <StaticBanner
                backgroundColor='#d9534f'
                color='white'
                icon='exclamation-triangle'
                message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(
                    selectedInstallationData?.lastStatusUpdate
                ).toLocaleString(i18n.language)}`}
            />
        )}
        <InstallationDesc
          canManageAlerts={false}
          canViewAlerts={false}
          installationData={selectedInstallationData}
          persons={selectedInstallationData?.contactPerson}
        />
        <Guests guests={installationGuests} standalone={false} />
        <ContactPersons defaultOpen={true} persons={installationContacts} standalone={true}/>
        {selectedInst !== null && (
          <div className="main-inner-div">
            <h1 className="alerts-header">
              <button
                className="cbutton back-to-status-bt"
                onClick={() => {
                  history.push(SITE_LINKS.STATUS);
                }}
              >{`${t("BACK")}`}</button>
              {t("alertPageDetail.alertsDetailTitle")}
              {allowModify && (
                <span
                  className="add-alert-icon"
                  onClick={() => {
                    showAlertModal(null, ALERT_MODAL_TYPE_NEW);
                  }}
                >
                  {`${t("alerts.newRequest")}`}{" "}
                  <FontAwesomeIcon icon={faPlusCircle} />
                </span>
              )}
            </h1>
            <hr />
            <div className="cases-navigation">
              <div>
                <label
                  className="checkbox-label show-closed-cases-checkbox"
                  htmlFor="show-closed-cases-checkbox"
                >
                  <input
                    defaultChecked={showClosedCases}
                    disabled={cannotChangeParams}
                    id="show-closed-cases-checkbox"
                    name="show-closed-cases-checkbox"
                    onChange={handleToggleClosedCases}
                    type="checkbox"
                  />
                  {t("alerts.showClosed")}
                  <span className="checkmark" />
                </label>
              </div>
              <div>
                <label
                  className="checkbox-label show-latest-cases-checkbox"
                  htmlFor="show-latest-cases-checkbox"
                >
                  <input
                    defaultChecked={latestCasesFirst}
                    disabled={cannotChangeParams}
                    id="show-latest-cases-checkbox"
                    name="show-latest-cases-checkbox"
                    onChange={handleToggleLatestFirst}
                    type="checkbox"
                  />
                  {t("alerts.showLatestCasesFirst")}
                  <span className="checkmark" />
                </label>
              </div>
              <div style={{ minWidth: "15em" }}>
                <label
                  className="checkbox-label order-cases-update-checkbox"
                  htmlFor="order-cases-update-checkbox"
                >
                  <input
                    defaultChecked={orderByLastCaseUpdate}
                    disabled={cannotChangeParams}
                    id="order-cases-update-checkbox"
                    name="order-cases-update-checkbox"
                    onChange={handleToggleOrderByLastUpdate}
                    type="checkbox"
                  />
                  {t("alerts.orderByLasUpdate")}
                  <span className="checkmark" />
                </label>
              </div>
              <div>
                {offsetCase >= limitCase && (
                  <button
                    className="cbutton prev-page-bt"
                    disabled={cannotChangeParams}
                    onClick={handleFirstPage}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                )}
                {offsetCase >= limitCase && (
                  <button
                    className="cbutton prev-page-bt"
                    disabled={cannotChangeParams}
                    onClick={handlePrevPage}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button>
                )}
                {
                  <span className="cases-page-position">
                    {`${casesNumber === 0 ? "0" : offsetCase + 1}-${
                      offsetCase + limitCase < casesNumber
                        ? offsetCase + limitCase
                        : casesNumber
                    } ${t("of")} ${casesNumber}`}
                  </span>
                }
                {offsetCase + limitCase < casesNumber && (
                  <button
                    className="cbutton next-page-bt"
                    disabled={cannotChangeParams}
                    onClick={handleNextPage}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                  </button>
                )}
              </div>
              <div>
                <label className="select-label show-cases-limit">
                  <div className="select-control show-cases-limit">
                    <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      isDisabled={cannotChangeParams}
                      isSearchable={false}
                      name="page-elements-limit"
                      onChange={handleLimitChange}
                      options={[
                        { value: 10, label: "10" },
                        { value: 20, label: "20" },
                        { value: 30, label: "30" },
                        { value: 50, label: "50" },
                      ]}
                      value={{ value: limitCase, label: `${limitCase}` }}
                    />
                  </div>
                  <span className="page-size-suffix">{`${t("perPage")}`}</span>
                </label>
              </div>
            </div>
            <div className="content-wrap">
              <div className="content-panel">
                {cases?.map?.((caseInfo) => {
                  return (
                    <CaseTableRow
                      key={caseInfo.id}
                      caseInfo={caseInfo}
                      deviceInfo={
                        deviceInfos.has(caseInfo?.deviceId)
                          ? deviceInfos.get(caseInfo?.deviceId)
                          : null
                      }
                      deviceInfos={deviceInfos}
                      inhibitHandler={(caseInfo) => {
                        showAlertModal(caseInfo, ALERT_MODAL_TYPE_INHIBIT);
                      }}
                      installationOffline={selectedInstallationData?.status === INSTALLATION_ALERT_STATUS_DISCONNECTED}
                      openJournalHandler={(caseInfo) => {
                        showAlertModal(caseInfo, ALERT_MODAL_TYPE_FROM_JOURNAL);
                      }}
                      reopenHandler={(caseInfo) => {
                        showAlertModal(caseInfo, ALERT_MODAL_TYPE_REOPEN);
                      }}
                      takeHandler={(caseInfo) => {
                        showAlertModal(caseInfo, ALERT_MODAL_TYPE_CLOSE);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>
      <FooterSection />
    </>
  );
});
