import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { SITE_LINKS } from '../Contants';

export default function Alerts({installationStatus, alerts, canManageAlerts}) {
  const {t, i18n} = useTranslation();

  if((typeof alerts === 'undefined') || (alerts && alerts.length === 0)) {
    return null;
  }

  function decodeIconContainerClass(alert) 
  {
//    console.log("DecodeIconAlert: ", alert);
    switch(alert.type) {
      case 0:
        return "gray";

      case 1:
        return "yellow";
      
      default:
      case 2:
      case 3:
        return "red";
    }
  }

  function decodeHeaderClass(status)
  {
//    console.log("decodeHeaderClass: ", status);
    switch(status) {
      case 0:
        return "gray";

      case 1:
        return "yellow";
      
      default:
      case 2:
      case 3:
        return "red";
    }
  }

  function extractFileName(pngPath) {
    if(pngPath == null)
      return null;
    const lastSlashPos = pngPath.lastIndexOf('/');
    const dotPost = pngPath.lastIndexOf('.');
    return pngPath.slice(lastSlashPos + 1, dotPost);
  }

  return (
    <div className="alarms-div" id="elenco-allarmi">
      <h4 className={`allarmi ${decodeHeaderClass(installationStatus)}`}>{t("alerts.title")}</h4>
      <div className="content-panel">
        <>
          {alerts.map( (alert) => {
//            console.log("alert: ", alert);
            let date = new Date();
            date.setTime(alert.date * 1000);
            return (
              <div key={alert.deviceId} className="allarme" id={`alarm_${alert.deviceId}`}>
                <Link to={canManageAlerts ? SITE_LINKS.ALERT_DETAIL : '#'}>
                  <div className={`icon-container ${decodeIconContainerClass(alert)}`}>
                    <i className={`icon ${extractFileName(alert.iconUrl)}`}></i>
                  </div>
                  <div className={`testo-allarme  ${decodeIconContainerClass(alert)}`}>
                    <div className="titolo">{alert.title}</div>
                    <div className="descrizione">{alert.message}</div>
                    <div className="datetime">{date.toLocaleString(i18n.language)}</div>
                  </div>
                </Link>
              </div>
            );})
          }
        </>
      </div>
    </div>      
  );
}
