import React from 'react'
//import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { createEventChartInfo, generateEventBarArray } from '../graph_utils';
import { useTranslation } from 'react-i18next';
// import { timestampToLocalString } from '../utilities';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarGraph({graphClassName, dataToRender, start, end, valueMaps, locale, hideMinutes, numberOfBars, displayXScale, tooltipType = 'full'}) {
  const {t/*, i18n*/} = useTranslation();
  if(typeof dataToRender === 'undefined' || dataToRender.length === 0)
    return null;

  const eventsBarData = generateEventBarArray(dataToRender, start, end, numberOfBars, valueMaps.values, locale, hideMinutes);

  const {data, options} = createEventChartInfo(eventsBarData, valueMaps.colors, t, locale, numberOfBars, tooltipType, displayXScale);

  if(!displayXScale)
    graphClassName += ' no-x-scale';

  return (
    <>
      <div className={graphClassName}>
        <Bar
          data={data}
          options={options}
        />
      </div>
    </>
  );
}
