import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  /*faBlind, */ faMale,
  faFemale,
  faUser,
  faArrowCircleDown,
  faArrowCircleUp,
  faPlusCircle,
  faTrashAlt,
  faPhone,
  faMobileAlt,
  faPhoneVolume
} from '@fortawesome/free-solid-svg-icons';
import ActiveTile from './ActiveTile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ResourceLink from './ResourceLink';
import { CSSTransition } from 'react-transition-group';
import { getAge, null_or_undefined } from '../utilities';
import { timestampToDate, dateToTimestamp } from '../utilities';
import { format as formatDate } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import Select from 'react-select';
import {GenericContext, InstallationsContext} from './App';
import { not_null_or_undefined } from '../utilities';
import cloneDeep from 'lodash/cloneDeep';
import { confirmAlert } from 'react-confirm-alert';

const EmptyGuest = {
  id: 0,
  name: '',
  surname: '',
  sex: null,
  fiscalIdentifier: null,
  fiscalIdentifierTypeId: 0,
  fiscalIdentifierType: null,
  phoneNumber1Number: "",
  phoneNumber1Desc: null,
  phoneNumber1Type: null,
  phoneNumber2Number: "",
  phoneNumber2Desc: null,
  phoneNumber2Type: null,
  phoneNumber3Number: "",
  phoneNumber3Desc: null,
  phoneNumber3Type: null,
  phoneNumber4Number: "",
  phoneNumber4Desc: null,
  phoneNumber4Type: null,
  phoneNumber5Number: "",
  phoneNumber5Desc: null,
  phoneNumber5Type: null,
  links: [],
};

//Used by date-fns/esm format
const locales = { it, de, fr, enGB, enUS };

//Used by react-datepicker
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

export default function Guests({ guests, setGuests, standalone, allowModify }) {
  const { t, i18n } = useTranslation();
  const { selectedInst, requestFiscalIdentifierTypes, deleteInstGuest, updateInstGuest, createInstGuest } =
    React.useContext(InstallationsContext);
  const { lastError } = React.useContext(GenericContext);
  const [show, setShow] = useState(false);
  const [guestModal, setGuestModal] = useState(null);
  const [modalType, setModalType] = useState('show');
  const [fiscalIdentifierTypes, setFiscalIdentifierTypes] = useState(null);

  const SEX_OPTIONS = [
    { value: '-', label: '-' },
    { value: 'M', label: t('guests.male') },
    { value: 'F', label: t('guests.female') },
  ];

  const PHONE_NUMBER_TYPE_OPTIONS = [
    { value: 'land', label: t('guests.landline') },
    { value: 'cell', label: t('guests.cellular') },
    { value: 'viva', label: t('guests.vivaVoice') },
  ];

  const nodeRef = React.useRef(null)

  const handleAlert = (message) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: `${t('contactPerson.errorTitle')}`,
        message: message,
        buttons: [
          {
            label: t('OK'),
            onClick: resolve
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      });
    });
  };

  const handleConfirmation = (message) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: `${t('guests.confirmTitle')}`,
        message: message,
        buttons: [
          {
            label: t('No'),
            onClick: reject
          },
          {
            label: t('Yes'),
            onClick: resolve
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      });
    });
  };

  async function requestGuestAddition(instSerial, newGuest) {
    const createdGuest = await createInstGuest(instSerial, convertGuestInfoToGuestAPI(newGuest));
    if(createdGuest.lastErrorMessage != null) {
      alert(createdGuest.lastErrorMessage)
      return;
    }
    const newGuests = cloneDeep(guests);
    newGuests.set(createdGuest.id, createdGuest);
    setGuests(newGuests);
  }

  function cleanupPhoneNumber(number) {
    if(number == null || number.length === 0)
      return number;
    let cleanNumber = "";
    if(number.substring(0,1) === "+")
      cleanNumber = "00" + number.substring(1).replace(/[\s\-()]/g, '');
    else
      cleanNumber = number.replace(/[\s\-()]/g, '');
    return cleanNumber;
  }

  function convertSexValToSex(sex) {
    if (sex === null) return null;
    const sexFound = SEX_OPTIONS.find(el => el.value === sex);
    if (sexFound) return sexFound;

    return null;
  }

  function convertPhoneNumberTypeValToType(type) {
    if (type === null) return PHONE_NUMBER_TYPE_OPTIONS[0];
    const typeFound = PHONE_NUMBER_TYPE_OPTIONS.find(el => el.value === type);
    if (typeFound) return typeFound;

    return null;
  }

  function convertFITypeIdToFI(fiscalIdentifierId) {
    if (fiscalIdentifierId === null) return null;
    if (!Array.isArray(fiscalIdentifierTypes)) return null;

    const fiscalIdentifierType = fiscalIdentifierTypes.find(el => el.value.id === fiscalIdentifierId);
    if (fiscalIdentifierType) return fiscalIdentifierType;

    return null;
  }

  function toggleShow() {
    if (!standalone) return;
    setShow((show) => {
      return !show;
    });
    setModalType('show');
  }

  function guestOnClickHandler(guest) {
    setGuestModal(cloneDeep(guest));
  }

  function closeGuestModal() {
    setGuestModal(null);
    setModalType('show');
  }

  function getSexLabel(sex) {
    if (sex === 'M') {
      return t('guests.male');
    } else if (sex === 'F') {
      return t('guests.female');
    } else {
      return t('guests.unknown');
    }
  }

  async function handleAddNewGuest(e) {
    e.stopPropagation();
    setModalType('create');
    setGuestModal({ ...EmptyGuest });
    await handleGuestEditDataRequests();
  }

  function convertGuestInfoToGuestAPI(guestInfo)
  {
    let guestForApi = {
      "id": guestInfo.id,
      "name": guestInfo.name,
      "surname": guestInfo.surname,
      "sex": guestInfo.sex,
      "birthDate": guestInfo.birthDate,
      "summary": guestInfo.summary,
      "note": guestInfo.note,
      "fiscalIdentifierTypeId": guestInfo.fiscalIdentifierTypeId,
      "fiscalIdentifier": guestInfo.fiscalIdentifierTypeId > 0 ? guestInfo.fiscalIdentifier : "",
      "links": cloneDeep(guestInfo.links)
    }

    if(guestInfo.phoneNumber1Number != null && guestInfo.phoneNumber1Number.length !== 0) {
      guestForApi.phone1Info = {
        "number": guestInfo.phoneNumber1Number,
        "type": guestInfo.phoneNumber1Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": guestInfo.phoneNumber1Desc ?? "",
      }
    } else {
      guestForApi.phone1Info = {
        "number": ""
      }
    }

    if(guestInfo.phoneNumber2Number != null && guestInfo.phoneNumber2Number.length !== 0) {
      guestForApi.phone2Info = {
        "number": guestInfo.phoneNumber2Number,
        "type": guestInfo.phoneNumber2Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": guestInfo.phoneNumber2Desc ?? "",
      }
    } else {
      guestForApi.phone2Info = {
        "number": ""
      }
    }

    if(guestInfo.phoneNumber3Number != null && guestInfo.phoneNumber3Number.length !== 0) {
      guestForApi.phone3Info = {
        "number": guestInfo.phoneNumber3Number,
        "type": guestInfo.phoneNumber3Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": guestInfo.phoneNumber3Desc ?? "",
      }
    } else {
      guestForApi.phone3Info = {
        "number": ""
      }
    }

    if(guestInfo.phoneNumber4Number != null && guestInfo.phoneNumber4Number.length !== 0) {
      guestForApi.phone4Info = {
        "number": guestInfo.phoneNumber4Number,
        "type": guestInfo.phoneNumber4Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": guestInfo.phoneNumber4Desc ?? "",
      }
    } else {
      guestForApi.phone4Info = {
        "number": ""
      }
    }

    if(guestInfo.phoneNumber5Number != null && guestInfo.phoneNumber5Number.length !== 0) {
      guestForApi.phone5Info = {
        "number": guestInfo.phoneNumber5Number,
        "type": guestInfo.phoneNumber5Type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value,
        "desc": guestInfo.phoneNumber5Desc ?? "",
      }
    } else {
      guestForApi.phone5Info = {
        "number": ""
      }
    }

    return guestForApi;
  }

  function convertGuestApiInfoToGuestInfo(guestApi)
  {
    let guestInfo = {
      "id": guestApi.id,
      "name": guestApi.name,
      "surname": guestApi.surname,
      "sex": guestApi.sex,
      "birthDate": guestApi.birthDate,
      "summary": guestApi.summary,
      "note": guestApi.note,
      "fiscalIdentifier": guestApi.fiscalIdentifier,
      "fiscalIdentifierType": guestApi.fiscalIdentifierType,
      "fiscalIdentifierTypeId": guestApi.fiscalIdentifierType?.id,
      "links": cloneDeep(guestApi.links)
    }

    if(guestApi.phone1Info != null) {
      guestInfo.phoneNumber1Number = guestApi.phone1Info.number;
      guestInfo.phoneNumber1Type = guestApi.phone1Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      guestInfo.phoneNumber1Desc = guestApi.phone1Info.desc ?? "";
    }

    if(guestApi.phone2Info != null) {
      guestInfo.phoneNumber2Number = guestApi.phone2Info.number;
      guestInfo.phoneNumber2Type = guestApi.phone2Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      guestInfo.phoneNumber2Desc = guestApi.phone2Info.desc ?? "";
    }

    if(guestApi.phone3Info != null) {
      guestInfo.phoneNumber3Number = guestApi.phone3Info.number;
      guestInfo.phoneNumber3Type = guestApi.phone3Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      guestInfo.phoneNumber3Desc = guestApi.phone3Info.desc ?? "";
    }

    if(guestApi.phone4Info != null) {
      guestInfo.phoneNumber4Number = guestApi.phone4Info.number;
      guestInfo.phoneNumber4Type = guestApi.phone4Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      guestInfo.phoneNumber4Desc = guestApi.phone4Info.desc ?? "";
    }

    if(guestApi.phone5Info != null) {
      guestInfo.phoneNumber5Number = guestApi.phone5Info.number;
      guestInfo.phoneNumber5Type = guestApi.phone5Info.type ?? PHONE_NUMBER_TYPE_OPTIONS[0].value;
      guestInfo.phoneNumber5Desc = guestApi.phone5Info.desc ?? "";
    }
    return guestInfo;
  }

  async function handleGuestEditDataRequests() {
    if (fiscalIdentifierTypes === null) {
      setFiscalIdentifierTypes('loading'); //Avoid multiple unnecessary calls
      const fiscalIdentifierTypesRead = await requestFiscalIdentifierTypes();
      if (not_null_or_undefined(fiscalIdentifierTypesRead)) {
        let mapped = fiscalIdentifierTypesRead.map((fiscalIdentifiersType) => {
          return { value: fiscalIdentifiersType, label: `${fiscalIdentifiersType.name}` };
        });
        mapped.unshift({ value: { id: 0, name: '-', checkRegex: '.*' }, label: '-' });
        setFiscalIdentifierTypes(mapped);
      }
    }
  }

  async function deleteGuest(guestId) {
    confirmAlert({
      title: `${t('guests.confirmDeletion')}`,
      buttons: [
        {
          label: `${t('Yes')}`,
          onClick: async () => {
            try {
              await deleteInstGuest(selectedInst, guestId);
              const newGuests = cloneDeep(guests);
              newGuests.delete(guestId);
              setGuests(newGuests);
            } catch (ex) {
              alert('Error: ' + ex.message());
            }
          },
        },
        {
          label: `${t('No')}`,
        },
      ],
    });
  }

  async function saveChangesToGuest(guestInfo) {
    const updated = await updateInstGuest(selectedInst, guestInfo.id, convertGuestInfoToGuestAPI(guestInfo));
    if(updated.lastErrorMessage != null) {
      alert(updated.lastErrorMessage)
      return;
    }
    const newGuests = cloneDeep(guests);
    newGuests.forEach((guest, idx, guests) => {
      if (guest.id === guestInfo.id) {
        guests.set(idx, updated);
      }
    });
    setGuests(newGuests);
  }

  function renderModal() {
    return (
      <Modal
        classNames={{
          // overlay: 'customOverlay',
          modal: 'fullWidthModal',
        }}
        onClose={closeGuestModal}
        open={guestModal !== null}
      >
        {guestModal !== null && modalType === 'show' && (
          <>
            <h1>{t('guests.guestDetailTitle')}</h1>
            <hr />
            <p>
              <b>{`${t('guests.name')}:`}</b> {`${guestModal.name}`}
            </p>
            <p>
              <b>{`${t('guests.surname')}:`}</b> {`${guestModal.surname}`}
            </p>
            {typeof guestModal.sex !== 'undefined' && (
              <p>
                <b>{`${t('guests.sex')}:`}</b> {`${getSexLabel(guestModal.sex)}`}
              </p>
            )}
            {typeof guestModal.birthDate !== 'undefined' && (
              <p>
                <b>{`${t('guests.birthDate')}:`}</b>{' '}
                {`${formatDate(timestampToDate(guestModal.birthDate), 'P', { locale: locales[i18n.language] })}`}
              </p>
            )}
            {typeof guestModal.fiscalIdentifier !== 'undefined' && guestModal.fiscalIdentifierType?.id != null && (
              <p>
                <b>{`${guestModal.fiscalIdentifierType.name}:`}</b> {`${guestModal.fiscalIdentifier}`}
              </p>
            )}
            {typeof guestModal.summary !== 'undefined' && (
              <p>
                <b>{`${t('guests.summary')}:`}</b> {`${guestModal.summary}`}
              </p>
            )}
            {typeof guestModal.note !== 'undefined' && (
              <p>
                <b>{`${t('guests.note')}:`}</b> {`${guestModal.note ?? '-'}`}
              </p>
            )}
            {typeof guestModal.phone1Info !== 'undefined' && (
              <p>
                {guestModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guestModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guestModal.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link' href={`${guestModal.phone1Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guestModal.phone1Info.number)}`}>{`${guestModal.phone1Info.number}`}</a> {`${guestModal.phone1Info.desc}`}
              </p>
            )}
            {typeof guestModal.phone2Info !== 'undefined' && (
              <p>
                {guestModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guestModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guestModal.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link' href={`${guestModal.phone2Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guestModal.phone2Info.number)}`}>{`${guestModal.phone2Info.number}`}</a> {`${guestModal.phone2Info.desc}`}
              </p>
            )}
            {typeof guestModal.phone3Info !== 'undefined' && (
              <p>
                {guestModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guestModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guestModal.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link' href={`${guestModal.phone3Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guestModal.phone3Info.number)}`}>{`${guestModal.phone3Info.number}`}</a> {`${guestModal.phone3Info.desc}`}
              </p>
            )}
            {typeof guestModal.phone4Info !== 'undefined' && (
              <p>
                {guestModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guestModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guestModal.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link' href={`${guestModal.phone4Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guestModal.phone4Info.number)}`}>{`${guestModal.phone4Info.number}`}</a> {`${guestModal.phone4Info.desc}`}
              </p>
            )}
            {typeof guestModal.phone5Info !== 'undefined' && (
              <p>
                {guestModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                {guestModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                {guestModal.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                    <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                <a className='phone-link' href={`${guestModal.phone5Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guestModal.phone5Info.number)}`}>{`${guestModal.phone5Info.number}`}</a> {`${guestModal.phone5Info.desc}`}
              </p>
            )}
            {guestModal.links && (
              <div className='guest-links-section'>
                {guestModal.links.map((link) => {
                  return <ResourceLink key={link.url} className='installation-desc-link' linkState={link} />;
                })}
              </div>
            )}
            {allowModify && (
              <div className='guest-details-modify-buttons'>
                <button
                  className='cbutton'
                  onClick={() => {
                    setModalType('edit');
                    setGuestModal((curGuestModal) => {
                      return convertGuestApiInfoToGuestInfo(curGuestModal);
                    });
                    handleGuestEditDataRequests();
                  }}
                  type='button'
                >
                  {t('edit')}
                </button>
              </div>
            )}
          </>
        )}
        {guestModal !== null && modalType === 'edit' && (
          <>
            <h1>{t('guests.guestEditDetailTitle')}</h1>
            <hr />
            <form className='guest-details-modify-form'>
              <div className='guest-form-row'>
                <label htmlFor='guestName'>
                  <b>{`${t('guests.name')}:`}</b>
                  <input
                      defaultValue={`${guestModal.name ?? ''}`}
                      name='guestName'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.name = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='guestSurname'>
                  <b>{`${t('guests.surname')}:`}</b>
                  <input
                      defaultValue={`${guestModal.surname ?? ''}`}
                      name='guestSurname'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.surname = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber1Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-1-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber1Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber1'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber1Number ?? ''}`}
                      name='guestPhoneNumber1'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber1Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber1Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber1Desc ?? ''}`}
                      name='guestPhoneNumber1Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber1Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber2Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-2-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber2Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber2'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber2Number ?? ''}`}
                      name='guestPhoneNumber2'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber2Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber2Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber2Desc ?? ''}`}
                      name='guestPhoneNumber2Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber2Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber3Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-3-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber3Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber3'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber3Number ?? ''}`}
                      name='guestPhoneNumber3'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber3Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber3Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber3Desc ?? ''}`}
                      name='guestPhoneNumber3Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber3Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber4Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-4-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber4Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber4'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber4Number ?? ''}`}
                      name='guestPhoneNumber4'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber4Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber4Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber4Desc ?? ''}`}
                      name='guestPhoneNumber4Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber4Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber5Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-5-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber5Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber5'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber5Number ?? ''}`}
                      name='guestPhoneNumber5'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber5Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber5Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber5Desc ?? ''}`}
                      name='guestPhoneNumber5Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber5Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='sex'>
                  <b>{`${t('guests.sex')}:`}</b>
                  <Select
                      className='dropdown-select sex-select'
                      classNamePrefix='select'
                      defaultValue={convertSexValToSex(guestModal.sex)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='sex'
                      onChange={(option) => {
                        setGuestModal((guestModalCur) => {
                          guestModalCur.sex = option.value;
                          return {...guestModalCur};
                        });
                      }}
                      options={SEX_OPTIONS}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                </label>
                <b>{`${t('guests.birthDate')}:`}</b>
                <DatePicker
                    dateFormat='P'
                    locale={i18n.language}
                    onChange={(date) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.birthDate = dateToTimestamp(date);
                        return {...guestModalCur};
                      });
                    }}
                    popperPlacement='right'
                    selected={timestampToDate(guestModal.birthDate)}
                    wrapperClassName={"birthday-picker"}
                />
              </div>
              <div className='guest-form-row'>
                <label htmlFor='fiscalIdentifier'>
                  <Select
                      className='dropdown-select-fi'
                      classNamePrefix='select'
                      defaultValue={convertFITypeIdToFI(guestModal.fiscalIdentifierTypeId)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={false}
                      menuPlacement='top'
                      name='fiscalIdentifierName'
                      onChange={(option) => {
                        setGuestModal((guestModalCur) => {
                          guestModalCur.fiscalIdentifierTypeId = option.value.id;
                          // console.log('guestModalCur.fiscalIdentifierTypeId = ' + guestModalCur.fiscalIdentifierTypeId);
                          return {...guestModalCur};
                        });
                      }}
                      options={fiscalIdentifierTypes}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <input
                      className='fiscal-identifier'
                      defaultValue={`${guestModal.fiscalIdentifierTypeId === 0 ? '' : guestModal.fiscalIdentifier ?? ''}`}
                      name='fiscalIdentifier'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.fiscalIdentifier = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      readOnly={
                          null_or_undefined(guestModal.fiscalIdentifierTypeId ?? guestModal.fiscalIdentifierType?.id) ||
                          (guestModal.fiscalIdentifierTypeId ?? guestModal.fiscalIdentifierType.id) === 0
                      }
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='guestSummary'>
                  <b>{`${t('guests.summary')}:`}</b>
                  <input
                      defaultValue={`${guestModal.summary ?? ''}`}
                      name='guestSummary'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.summary = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='guestNote'>
                  <b>{`${t('guests.note')}:`}</b>
                  <textarea
                      defaultValue={`${guestModal.note ?? ''}`}
                      name='guestNote'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.note = newValue;
                          return {...guestModalCur};
                        });
                      }}
                  />
                </label>
              </div>
              <div className='guest-links-section'>
                <h3>{`${t('guests.links')}:`}</h3>
                <div className='guest-links-list'>
                  {guestModal.links?.map((link, idx) => {
                    return (
                        <div key={idx+'_'+link.url} className='guest-link-raw'>
                          <ResourceLink
                              key={idx}
                              className='guest-form-row'
                              descriptionEditLabel={t('guests.linkDescription')}
                              edit={true}
                              editDivClassName='guest-form-link-edit'
                              linkState={link}
                              nameEditLabel={t('guests.linkName')}
                              setLinkState={(newLink) => {
                                setGuestModal((curGuestModal) => {
                                  curGuestModal.links[idx] = newLink;
                                  return {...curGuestModal};
                                });
                              }}
                              urlEditLabel={t('guests.linkUrl')}
                          />
                          <div key={link.url + 'delete'} className='guest-delete-button' data-idx={idx}>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                onClick={(e) => {
                                  setGuestModal((curGuestModal) => {
                                    const newLinks = curGuestModal.links.filter((link, index) => {
                                      return index !== idx;
                                    });
                                    curGuestModal.links = newLinks;
                                    return {...curGuestModal};
                                  });
                                }}
                            />
                          </div>
                        </div>
                    );
                  })}
                </div>
                <button
                    className='cbutton'
                    onClick={() => {
                      if (null_or_undefined(guestModal.links)) {
                        guestModal.links = [
                          {
                            linkName: '',
                            description: '',
                            url: 'newLink1',
                          },
                        ];
                      } else {
                        guestModal.links.push({
                          linkName: '',
                          description: '',
                          url: 'newLink' + guestModal.links.length,
                        });
                      }
                      setGuestModal((curGuestModal) => {
                        return {...curGuestModal};
                      });
                    }}
                    type='button'
                >
                  {t('guests.addLink')}
                </button>
              </div>
              <div className='guest-details-modify-buttons'>
                <button
                    className='cbutton deleteBt'
                    onClick={() => {
                      deleteGuest(guestModal.id);
                      closeGuestModal();
                    }}
                    type='button'
                >
                  {t('delete')}
                </button>
                <div>
                  <button
                      className='cbutton cancelBt'
                      onClick={() => {
                        closeGuestModal();
                      }}
                      type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button
                      className='cbutton saveBt'
                      onClick={async () => {
                        try {
                          if (guestModal.name.length === 0 || guestModal.surname.length === 0) {
                            await handleAlert(t('contactPerson.invalidName'));
                            return;
                          }

                          if ((guestModal.phoneNumber1Number == null || guestModal.phoneNumber1Number?.length === 0) &&
                              (guestModal.phoneNumber2Number == null || guestModal.phoneNumber2Number?.length === 0) &&
                              (guestModal.phoneNumber3Number == null || guestModal.phoneNumber3Number?.length === 0) &&
                              (guestModal.phoneNumber4Number == null || guestModal.phoneNumber4Number?.length === 0) &&
                              (guestModal.phoneNumber5Number == null || guestModal.phoneNumber5Number?.length === 0)) {
                            await handleConfirmation(t('contactPerson.warningMissingPhoneNumber1'));
                          }

                          await saveChangesToGuest(guestModal);
                          closeGuestModal();
                        } catch (error) {
                          // console.log(error)
                          // return to allow for error correction
                        }
                      }}
                      type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
        {guestModal !== null && modalType === 'create' && (
            <>
              <h1>{t('guests.guestNewDetailTitle')}</h1>
              <hr/>
              <form className='guest-details-modify-form'>
                <div className='guest-form-row'>
                  <label htmlFor='guestName'>
                    <b>{`${t('guests.name')}:`}</b>
                    <input
                        defaultValue={`${guestModal.name ?? ''}`}
                        name='guestName'
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setGuestModal((guestModalCur) => {
                            guestModalCur.name = newValue;
                            return {...guestModalCur};
                          });
                        }}
                        type='text'
                    />
                  </label>
                </div>
                <div className='guest-form-row'>
                  <label htmlFor='guestSurname'>
                    <b>{`${t('guests.surname')}:`}</b>
                    <input
                        defaultValue={`${guestModal.surname ?? ''}`}
                        name='guestSurname'
                        onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.surname = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber1Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-1-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber1Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber1'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber1Number ?? ''}`}
                      name='guestPhoneNumber1'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber1Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber1Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber1Desc ?? ''}`}
                      name='guestPhoneNumber1Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber1Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber2Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-2-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber2Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber2'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber2Number ?? ''}`}
                      name='guestPhoneNumber2'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber2Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber2Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber2Desc ?? ''}`}
                      name='guestPhoneNumber2Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber2Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber3Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-3-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber3Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber3'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber3Number ?? ''}`}
                      name='guestPhoneNumber3'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber3Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber3Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber3Desc ?? ''}`}
                      name='guestPhoneNumber3Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber3Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber4Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-4-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber4Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber4'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber4Number ?? ''}`}
                      name='guestPhoneNumber4'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber4Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber4Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber4Desc ?? ''}`}
                      name='guestPhoneNumber4Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber4Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <Select
                    className='dropdown-select phone-type'
                    classNamePrefix='select'
                    defaultValue={convertPhoneNumberTypeValToType(guestModal.phoneNumber5Type)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='phone-number-5-type'
                    onChange={(option) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.phoneNumber5Type = option.value;
                        return {...guestModalCur};
                      });
                    }}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={{
                      singleValue: (styles, {data}) => {
                        return {...styles, color: '#797979'};
                      },
                    }}
                />
                <label htmlFor='guestPhoneNumber5'>
                  <b>{`${t('guests.phoneNumber')}:`}</b>
                  <input
                      className={"phone-number"}
                      defaultValue={`${guestModal.phoneNumber5Number ?? ''}`}
                      name='guestPhoneNumber5'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber5Number = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
                <label htmlFor='guestPhoneNumber5Desc'>
                  <b>{`${t('guests.phoneNumberInfo')}:`}</b>
                  <input
                      className={"phone-desc"}
                      defaultValue={`${guestModal.phoneNumber5Desc ?? ''}`}
                      name='guestPhoneNumber5Desc'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.phoneNumber5Desc = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='sex'>
                  <b>{`${t('guests.sex')}:`}</b>
                  <Select
                      className='dropdown-select'
                      classNamePrefix='select'
                      defaultValue={convertSexValToSex(guestModal.sex)}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='sex'
                      onChange={(option) => {
                        setGuestModal((guestModalCur) => {
                          guestModalCur.sex = option.value;
                          return {...guestModalCur};
                        });
                      }}
                      options={SEX_OPTIONS}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <b>{`${t('guests.birthDate')}:`}</b>
                <DatePicker
                    dateFormat='P'
                    locale={i18n.language}
                    onChange={(date) => {
                      setGuestModal((guestModalCur) => {
                        guestModalCur.birthDate = dateToTimestamp(date);
                        return {...guestModalCur};
                      });
                    }}
                    popperPlacement='right'
                    selected={timestampToDate(guestModal.birthDate)}
                />
              </div>
              <div className='guest-form-row'>
                <label htmlFor='fiscalIdentifier'>
                  <Select
                      className='dropdown-select-fi'
                      classNamePrefix='select'
                      defaultValue={fiscalIdentifierTypes[0]}
                      getOptionLabel={option => `${t(option.label)}`}
                      isSearchable={true}
                      menuPlacement='top'
                      name='fiscalIdentifierName'
                      onChange={(option) => {
                        setGuestModal((guestModalCur) => {
                          guestModalCur.fiscalIdentifierTypeId = option.value.id;
                          // console.log('guestModalCur.fiscalIdentifierTypeId = ' + guestModalCur.fiscalIdentifierTypeId);
                          return {...guestModalCur};
                        });
                      }}
                      options={fiscalIdentifierTypes}
                      styles={{
                        singleValue: (styles, {data}) => {
                          return {...styles, color: '#797979'};
                        },
                      }}
                  />
                  <input
                      className='fiscal-identifier'
                      defaultValue={`${guestModal.fiscalIdentifierTypeId === 0 ? '' : guestModal.fiscalIdentifier ?? ''}`}
                      name='fiscalIdentifier'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.fiscalIdentifier = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      readOnly={
                          null_or_undefined(guestModal.fiscalIdentifierTypeId) || guestModal.fiscalIdentifierTypeId === 0
                      }
                      type='text'
                  />
                </label>
              </div>

              <div className='guest-form-row'>
                <label htmlFor='guestSummary'>
                  <b>{`${t('guests.summary')}:`}</b>
                  <input
                      defaultValue={`${guestModal.summary ?? ''}`}
                      name='guestSummary'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.summary = newValue;
                          return {...guestModalCur};
                        });
                      }}
                      type='text'
                  />
                </label>
              </div>
              <div className='guest-form-row'>
                <label htmlFor='guestNote'>
                  <b>{`${t('guests.note')}:`}</b>
                  <textarea
                      defaultValue={`${guestModal.note ?? ''}`}
                      name='guestNote'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setGuestModal((guestModalCur) => {
                          guestModalCur.note = newValue;
                          return {...guestModalCur};
                        });
                      }}
                  />
                </label>
              </div>
              <div className='guest-links-section'>
                {guestModal.links.map((link) => {
                  return (
                      <ResourceLink
                          key={link.url}
                          className='installation-desc-link'
                          linkState={link}
                      />
                  );
                })}
              </div>
              <div className='guest-details-modify-buttons'>
                <div></div>
                <div>
                  <button
                      className='cbutton cancelBt'
                      onClick={() => {
                        closeGuestModal();
                      }}
                      type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button
                      className='cbutton saveBt'
                      onClick={async () => {
                        try {
                          if (guestModal.name.length === 0 || guestModal.surname.length === 0) {
                            await handleAlert(t('guests.invalidName'));
                            return;
                          }

                          if ((guestModal.phoneNumber1Number == null || guestModal.phoneNumber1Number?.length === 0) &&
                              (guestModal.phoneNumber2Number == null || guestModal.phoneNumber2Number?.length === 0) &&
                              (guestModal.phoneNumber3Number == null || guestModal.phoneNumber3Number?.length === 0) &&
                              (guestModal.phoneNumber4Number == null || guestModal.phoneNumber4Number?.length === 0) &&
                              (guestModal.phoneNumber5Number == null || guestModal.phoneNumber5Number?.length === 0)) {
                            await handleConfirmation(t('guests.warningMissingPhoneNumber1'));
                          }

                          await requestGuestAddition(selectedInst, guestModal);
                          closeGuestModal();
                        } catch (error) {
                          // return to allow for error correction
                        }
                      }}
                      type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </Modal>
    );
  }

  function renderGuests() {
    return (
      <div className='info-guests'>
        {guests instanceof Map &&
          [...guests.values()].map((guest) => {
            let icon = faUser;
            let tileState = 'active-state';
            if (guest.sex === 'M') {
              icon = faMale;
              tileState = 'male-state';
            } else if (guest.sex === 'F') {
              icon = faFemale;
              tileState = 'female-state';
            }

            return (
              <ActiveTile
                key={guest.id}
                descType={''}
                details={
                  <>
                    {!isNaN(getAge(guest.birthDate)) &&
                        `${t('guests.age')} ${getAge(guest.birthDate)} ${t('guests.years')} `}
                    <wbr/>
                    {guest.phone1Info?.number && (
                        <span className='phone-link'>
                        <a className='phone-link' href={`${guest.phone1Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone1Info.number)}`}>
                          {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                          {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                          {guest.phone1Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                          {`${guest.phone1Info.number}`}</a> {guest.phone1Info.desc && (`(${guest.phone1Info.desc})`)}
                      </span>
                    )}
                    <wbr/>
                    {guest.phone2Info?.number && (
                        <span className='phone-link'>
                        <a className='phone-link' href={`${guest.phone2Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone2Info.number)}`}>
                          {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                          {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                          {guest.phone2Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                              <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                          {`${guest.phone2Info.number}`}</a> {guest.phone2Info.desc && (`(${guest.phone2Info.desc})`)}
                      </span>
                    )}
                    <wbr/>
                    {guest.phone3Info?.number && (
                        <span className='phone-link'>
                          <a className='phone-link' href={`${guest.phone3Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone3Info.number)}`}>
                            {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {guest.phone3Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${guest.phone3Info.number}`}</a> {guest.phone3Info.desc && (`(${guest.phone3Info.desc})`)}
                        </span>
                    )}
                    <wbr/>
                    {guest.phone4Info?.number && (
                        <span className='phone-link'>
                          <a className='phone-link' href={`${guest.phone4Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone4Info.number)}`}>
                            {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {guest.phone4Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${guest.phone4Info.number}`}</a> {guest.phone4Info.desc && (`(${guest.phone4Info.desc})`)}
                        </span>
                    )}
                    <wbr/>
                    {guest.phone5Info?.number && (
                        <span className='phone-link'>
                          <a className='phone-link' href={`${guest.phone5Info.number.substring(0, 4) === "sip:" ? "" : "tel:"}${cleanupPhoneNumber(guest.phone5Info.number)}`}>
                            {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[0].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhone}/>)}
                            {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[1].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faMobileAlt}/>)}
                            {guest.phone5Info.type === PHONE_NUMBER_TYPE_OPTIONS[2].value && (
                                <FontAwesomeIcon className={"phone-type-icon"} icon={faPhoneVolume}/>)}
                            {`${guest.phone5Info.number}`}</a> {guest.phone5Info.desc && (`(${guest.phone5Info.desc})`)}
                        </span>
                    )}
                  </>
                }
                iconName={icon}
                moreDetails={guest.summary}
                onClickHandler={() => guestOnClickHandler(guest)}
                tileId={`guest_${guest.id}`}
                tileState={tileState}
                title={`${guest.name} ${guest.surname}`}
                useFontAwesome={true}
                // uppercaseDetails={true}
              />
            );
          })}
      </div>
    );
  }

  // async function fiscalCodeTypesOptions() {
  //   const fcts = await requestFiscalCodeTypes();
  //   if (null_or_undefined(fct)) {
  //     return [];
  //   }
  //
  //   const options = fcts.map((fct) => {
  //     return { value: fct.id, label: <div>{fct.name}</div> };
  //   });
  //
  //   return options;
  // }

  return (
    <div className={standalone ? 'guests-div' : 'guests-div-embedded'}>
      <h1 className={standalone ? 'guests-header' : 'guests-header-embedded'} onClick={toggleShow}>
        {standalone && (
          <>
            <span className='collapsable-section-desc'>
              <FontAwesomeIcon icon={show ? faArrowCircleUp : faArrowCircleDown}/>
              {`${t('guests.title')}: ${guests !== null ? guests.size : '...'}`}
            </span>
          </>
        )}
        {!standalone && <span>{`${t('guests.title')}`}</span>}
        {allowModify && (
          <span className='add-guest-icon'>
            <FontAwesomeIcon icon={faPlusCircle} onClick={handleAddNewGuest}/>
          </span>
        )}
      </h1>
      <hr/>
      {renderModal()}
      {standalone && (
        <CSSTransition classNames='guests-trans' in={show} nodeRef={nodeRef} timeout={250} unmountOnExit>
          <div ref={nodeRef}>
            {renderGuests()}
          </div>
        </CSSTransition>
      )}
      {!standalone && renderGuests()}
    </div>
  );
}
