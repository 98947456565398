import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

export default function CategoryLabel({label, showLabel, defaultClosed, devices}) {
  const {t/*, i18n*/} = useTranslation();
  const [isOpen, setIsOpen] = useState(!defaultClosed);

  if(devices.length === 0)
    return null;

  return (
    <>
      {(showLabel && label !== '$uncategorized$') && (
      <h3 className="status-page-category-name" onClick={() => {setIsOpen( cur => !cur )}} >
        {label === '$safety$' ? `${t('statusPage.safetyCategoryName')}` : label}
      </h3>)}
      {(isOpen) && (
        devices
      )}
    </>
  )
}
