import { wiMHomeMQTTClient } from './WiMHomeMQTT';

// called when the client loses its connection
function onConnectionLost(responseObject) {
  // console.log(responseObject);
  // console.log(wiMHomeMQTTClient);
  // console.log(responseObject);
  // console.log('onConnectionLost');
  if (responseObject.errorCode !== 0) {
    // console.log('onConnectionLost:' + responseObject.errorMessage);
  }
  wiMHomeMQTTClient.connected = false;
  if (wiMHomeMQTTClient.connectionCb != null) {
    wiMHomeMQTTClient.connectionCb(false);
  }
}

// called when a message arrives
function onMessageArrived(message) {
  // console.log(`Topic: ${message.topic} onMessageArrived: ${message.payloadString}`, 'Start of topic: ', message.topic.substring(0, 16));

  const notificationPayload = JSON.parse(message.payloadString);
  if (message.topic.substring(0, 16) === 'interfaces/users') {
    // console.log(`user alerts updated: ${notificationPayload.instSerial}: ${notificationPayload.deviceId}`);
    setTimeout(function () {
      wiMHomeMQTTClient.execAlertsUpdate(notificationPayload.instSerial, notificationPayload.deviceId);
    }, 0);
  } else {
    const installationSerial = message.topic.substring(11, message.topic.indexOf('/', 11));
    const typeOfUpdate = message.topic.substring(
      11 + installationSerial.length + 9,
      message.topic.indexOf('/', 11 + installationSerial.length + 9)
    );
    const deviceUpdated = notificationPayload.deviceId;
    // console.log(`${typeOfUpdate} updated: ${installationSerial}: ${deviceUpdated}`);

    if (typeOfUpdate === 'controls') {
      if (wiMHomeMQTTClient.execDeviceUpdate) {
        setTimeout(function () {
          wiMHomeMQTTClient.execDeviceUpdate(installationSerial, deviceUpdated);
        }, 0);
      }
    } else if (typeOfUpdate === 'alerts') {
      if (wiMHomeMQTTClient.execAlertsUpdate) {
        setTimeout(function () {
          wiMHomeMQTTClient.execAlertsUpdate(installationSerial, deviceUpdated);
        }, 0);
      }
    }
  }

  // const messagesDiv = document.querySelector(".messages");
  // const newMsgP = document.createElement("P");
  // const newMsgText = document.createTextNode(message.payloadString);
  // newMsgP.appendChild(newMsgText);
  // messagesDiv.appendChild(newMsgP);
}

export function connectMqtt(host, port, useSSL, username, password, connectionCb, execDeviceUpdate, execAlertsUpdate) {
  try {
    wiMHomeMQTTClient.connectionCb = connectionCb;
    wiMHomeMQTTClient.connect(
      host,
      port,
      useSSL,
      null,
      null,
      username,
      password,
      null,
      null,
      onMessageArrived,
      onConnectionLost,
      execDeviceUpdate,
      execAlertsUpdate
    );
    return true;
  } catch ($e) {
    // console.log($e.message);
  }
  return false;
}

// export function mqttSetInstallationsSerials(installations) {
//     wiMHomeMQTTClient.installations = installations;
// }

export function mqttSetInstallationSerial(instSerial) {
  if (wiMHomeMQTTClient.instSerial === instSerial) return;

  if (wiMHomeMQTTClient.instSerial !== null) {
    wiMHomeMQTTClient.unsubscribeInstallation();
  }

  wiMHomeMQTTClient.instSerial = instSerial;
  if (instSerial !== null) {
    wiMHomeMQTTClient.subscribeInstallation();
  }
}
