import React, { useState, useEffect, useCallback, useRef } from 'react';
import JournalEntry from './JournalEntry';
import Select from 'react-select';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { InstallationsContext } from './App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCaretLeft, faCaretRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function JournalInterface({type, setType}) {
  const {t} = useTranslation(); 

  const { 
    selectedInst,
    loadInstJournal,
    loadInstJournalCount,
    createInstJournalEntry,
  } = React.useContext(InstallationsContext);

  class JournalEntryData {
    constructor() {
      this.id = null;
      this.type = 1;
      this.title = "";
      this.description = "";
    }
  }

  const [journalModal, setJournalModal] = useState(null);
  const [installationJournal, setInstallationJournal] = useState(new Map());
  const [latestFirst, setLatestFirst] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [startDateTime/*, setStartDateTime*/] = useState(null);
  const [endDateTime/*, setEndDateTime*/] = useState(null);
  const [cannotChangeParams, setCannotChangeParams] = useState(false);

  const journalLoaded = useRef(false);

  function handleAddNewJournalEntry() {
    setType('create');
    setJournalModal(new JournalEntryData());
  }

  const loadJournal = useCallback(() => 
  {
    return loadInstJournal(selectedInst, latestFirst, offset, limit, startDateTime, endDateTime)
    .then((retrievedJournal) => {
      setInstallationJournal(retrievedJournal);
      journalLoaded.current = true;
      setCannotChangeParams(false);
      if(offset === 0) {
        if(retrievedJournal?.size === limit) {
          return loadInstJournalCount(selectedInst, startDateTime, endDateTime);
        } else {
          return retrievedJournal?.size;
        }
      } else {
        return null;
      }
    })
    .then((number) => {
      if(number !== null) {
        setCount(number);
      }
    });    
  }, [endDateTime, latestFirst, limit, loadInstJournal, loadInstJournalCount, offset, selectedInst, startDateTime]);

  useEffect(() => {
    if(journalLoaded.current === false)
      loadJournal();
  }, [loadJournal, type]);

  function handleLimitChange(option) {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setLimit(option.value); 
    setOffset(0);
  }

  function handleToggleLatestFirst() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setLatestFirst(cur => !cur);
  }

  function handleFirstPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(0);
  }

  function handlePrevPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(cur => (cur - limit));
  }

  function handleNextPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(cur => (cur + limit));
  }

  //Journal Modal
  function closeJournalModal() {
    setJournalModal(null);
    setType('closed');
  }

  const requestJournalAddition = useCallback((entry) => {
    createInstJournalEntry(selectedInst, entry)
    .then(() => {
      return loadJournal();
    })
    .then(() => {
      setType('show');
    });
  }, [createInstJournalEntry, loadJournal, selectedInst, setType]);

  return (
    <Modal
      onClose={closeJournalModal}
      open={type !== 'closed'}
      styles={{modal: {minWidth: 'min(80%, 900px)', maxHeight: '90vh'}}}
    >
      {
        installationJournal !== null && type === 'show' && (
          <div className="journal-modal-container">
            <h1 className="journal-title">
              <span>{t('journal.journalTitle')}</span>
              <span className="add-journal-icon-label" onClick={ () => {handleAddNewJournalEntry();} }>
                {t('journal.add')} <FontAwesomeIcon className="add-journal-icon" icon={faPlusCircle}/>
              </span>
            </h1>
            <hr/>
            <div className="journal-navigation">
              <div>
                <label className="checkbox-label show-latest-cases-checkbox" htmlFor="show-latest-cases-checkbox">
                  <input 
                    defaultChecked={latestFirst} 
                    disabled={cannotChangeParams} 
                    id="show-latest-cases-checkbox" 
                    name="show-latest-cases-checkbox"
                    onChange={handleToggleLatestFirst}
                    type="checkbox"
                  />
                    {t('alerts.showLatestCasesFirst')}
                  <span className="checkmark"/>
                </label>
              </div>
              <div>
                {(offset >= limit) && (
                  <button 
                    className="cbutton prev-page-bt" 
                    disabled={cannotChangeParams}
                    onClick={handleFirstPage}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button> 
                )}
                {(offset >= limit) && (
                  <button 
                    className="cbutton prev-page-bt" 
                    disabled={cannotChangeParams}
                    onClick={handlePrevPage}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button> 
                )}
                <span 
                  className="cases-page-position"
                >
                  {`${(count === 0) ? '0' : (offset + 1)}-${((offset + limit) < count) ? (offset + limit) : count} ${t('of')} ${count}`}
                </span>
                {((offset + limit) < count) && (
                  <button 
                    className="cbutton next-page-bt" 
                    disabled={cannotChangeParams}
                    onClick={handleNextPage}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                  </button> 
                )}
              </div>
              <div>
                <label className="select-label show-cases-limit">
                  <div className="select-control show-cases-limit">
                    <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      isDisabled={cannotChangeParams}
                      isSearchable={false}
                      name="page-elements-limit"
                      onChange={handleLimitChange}
                      options={[
                        {value: 10, label: '10'}, 
                        {value: 20, label: '20'}, 
                        {value: 30, label: '30'}, 
                        {value: 50, label: '50'}
                      ]}
                      value={{value: limit, label: `${limit}`}}
                    />
                  </div><span className="page-size-suffix">{`${t('perPage')}`}</span>
                </label>
              </div>
            </div>
            {(installationJournal instanceof Map && installationJournal.size > 0) && (
              <div className="journal-entry-list">
                {
                  Array.from(installationJournal.values()).map((entry) => {
                    return <JournalEntry 
                      key={entry.id} 
                      authorColor={entry.userColor}
                      authorIcon={entry.userIconUrl}
                      authorName={entry.userFirstName}
                      authorOrg={entry.userOrganization}
                      authorSurname={entry.userLastName}
                      caseDesc={entry.description ? entry.description : null}
                      dateTime={entry.dateTimeUtc}
                      entry={entry.entryDescription}
                      title={entry.title}
                    />
                  })
                }
              </div>
            )}
            {(!(installationJournal instanceof Map) || installationJournal.size === 0) && (
                <p>{t('journal.noEntries')}</p>
            )}
          </div>
        )
      }
      {
        journalModal !== null && type === 'create' && (
          <>
            <h1>{t('journal.journalTitle')}</h1>
            <hr/>
            {
              (installationJournal instanceof Map && installationJournal.size > 0) && (
                <div className="journal-entry-list-edit">
                  {
                    Array.from(installationJournal.values()).map((entry) => {
                      return <JournalEntry 
                        key={entry.id} 
                        authorColor={entry.userColor}
                        authorIcon={entry.userIconUrl}
                        authorName={entry.userFirstName}
                        authorOrg={entry.userOrganization}
                        authorSurname={entry.userLastName}
                        caseDesc={entry.description ? entry.description : null}
                        dateTime={entry.dateTimeUtc}
                        entry={entry.description}
                        title={entry.title}
                      />
                    })
                  }
                </div>
              )
            }
            <form className="journal-add-entry-form">
              <div className="journal-form-row">
                <label htmlFor="journalEntryTitle">
                  <b>{`${t('journal.journalEntryTitle')}:`}</b>
                  <input 
                    defaultValue={`${journalModal.title}`} 
                    name="journalEntryTitle" 
                    onChange={ (e) => {
                      const newValue = e.target.value;
                      setJournalModal( journalModalCur => ({...journalModalCur, title: newValue}) );
                    }}
                    type="text"
                  />
                </label>
              </div>
              <div className="journal-form-row">
                <label htmlFor="journalEntryDescription">
                  <b>{`${t('journal.journalEntryDescription')}:`}</b>
                  <textarea 
                    defaultValue={`${journalModal.description}`} 
                    name="journalEntryDescription" 
                    onChange={ (e) => {
                      const newValue = e.target.value;
                      setJournalModal( journalModalCur => ({...journalModalCur, description: newValue}) );
                    }}
                  />
                </label>
              </div>
              <div className="journal-add-entry-form-buttons">
                <div></div>
                <div>
                  <button 
                    className="cbutton cancelBt" 
                    onClick={ () => {
                        // closeJournalModal();
                        setType('show');
                      }
                    } 
                    type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button 
                    className="cbutton saveBt" 
                    onClick={ () => {
                        requestJournalAddition(journalModal);
                      }
                    } 
                    type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )
      }
    </Modal>
  )
}
